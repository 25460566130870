import { Injectable } from '@angular/core';
import { ConstantsService } from 'src/app/shared/services/constants/constants.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { getRequestedChangeCurrentLine } from 'src/app/store/change-request/change-request.selectors';
import { SetInvoiceChangesLocationData, SetInvoicesCurrentLocation } from 'src/app/store/invoice/invoice.actions';
import { getLocation } from 'src/app/store/location/location.selectors';
import { Store } from '@ngrx/store';
import {
  CustomerApiService,
  CustomerRequest,
  GetCustomerPath,
  GetCustomerResp,
  GetInvoicingInstructionsResp,
  RequestCustomerLocationFunction,
  UpdateCustomerRqst,
} from '@xpo-ltl-2.0/sdk-customer';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { CustomerDetailCd, CustomerFunctionCd, CustomerIdTypeCd } from '@xpo-ltl/sdk-common';
import { PricingApiService } from '@xpo-ltl/sdk-pricing';
import { ListAccountAgreementsQuery, PricingWorkbenchApiService } from '@xpo-ltl/sdk-pricingworkbench';
import moment from 'moment';
import { EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, skipWhile } from 'rxjs/operators';
import { AppState } from '../../store';
import { SetLocation } from '../../store/location/location.action';
import { LocationState } from '../../store/location/location.reducer';
import { isEqual as _isEqual } from 'lodash';

@Injectable({ providedIn: 'root' })
export class LocationDetailService {
  constructor(
    private customerService: CustomerApiService,
    private store: Store<AppState>,
    private snackbar: XpoSnackBar,
    private pricingApi: PricingApiService,
    private pricingWorkbenchApi: PricingWorkbenchApiService,
    private utilsService: UtilsService
  ) { }

  private currentLine = null;

  getLocationDataAPI(id, idType = CustomerIdTypeCd.CUSTOMER_LOCATION_FUNCTION_ID): Observable<GetCustomerResp> {
    const customerParams: GetCustomerPath = { id };

    const source =
      this.customerService.getCustomer(customerParams, {
        customerIdTypeCd: idType,
        customerDetailCd: null,
        inheritContacts: true,
      }) || of(new GetCustomerResp());

    return source.pipe(
      map((resp) => resp),
      catchError((err) => throwError(err))
    );
  }

  getLocationData(
    locationId: string,
    idType?: CustomerIdTypeCd,
    refreshLocation?: boolean
  ): Observable<GetCustomerResp> {
    return new Observable((observer) => {
      let data: GetCustomerResp;
      this.store
        .select(getLocation)
        .pipe(take(1))
        .subscribe(
          (locationState: LocationState) => {
            if (!locationState || locationState.locationId !== locationId || refreshLocation) {
              this.getLocationDataAPI(locationId, idType).subscribe(
                (res) => {
                  data = res;
                  data.customerLocation.customerLocationFunction = data.customerLocation.customerLocationFunction
                    .filter(
                      (location) =>
                        location.legacyCisCustomerNbr.toString() === locationId ||
                        location.madCd === locationId ||
                        location.customerLocationFuncId === +locationId
                    );
                  this.store.dispatch(new SetLocation({ customerLocation: res, locationId: locationId }));
                  observer.next(data);
                  observer.complete();
                },
                (err) => observer.error(err)
              );
            } else {
              data = locationState.customerLocation;
              observer.next(data);
              observer.complete();
            }
          },
          (err) => observer.error(err)
        );
    });
  }

  updateCustomer(
    requestPayload: UpdateCustomerRqst,
    locationFunctionId,
    cleanUpFn?: Function,
    hasParentChange?,
    hasZipChange?,
    duplicateCheck = false,
    addressValidation = false,
    isAlias = false,
  ): any {
    requestPayload.ignoreDuplicateCheckInd = duplicateCheck;
    requestPayload.ignoreAddressValidation = addressValidation;
    return this.callUpdateCustomer(requestPayload, locationFunctionId, cleanUpFn, hasParentChange, hasZipChange, isAlias);
  }

  private callUpdateCustomer(
    requestPayload: UpdateCustomerRqst,
    locationFunctionId,
    cleanUpFn?: Function,
    hasParentChange?,
    hasZipChange?,
    isAlias?,
  ): any {
    this.utilsService.objValuesToUpperCase(requestPayload);

    const location = requestPayload.customerLocation?.customerLocationFunction[0];
    const ignoreRule = location?.functionCd === CustomerFunctionCd.BILL_TO && !!location?.serviceRecipient?.serviceRecipientNbr;

    requestPayload.ignoreAddressValidation = ignoreRule ? true : requestPayload.ignoreAddressValidation;

    this.customerService
      .updateCustomer(requestPayload, { updateOffspringCredit: false }, { toastOnError: false, loadingOverlayEnabled: true })
      .pipe(
        catchError((err) => this.displayErrorMessage(err))
      )
      .subscribe((res) => {
        switch (true) {
          case !requestPayload.ignoreAddressValidation && requestPayload.customerDetailCd === CustomerDetailCd.ACCOUNT:
            this.utilsService.objValuesToUpperCase(res.finalStandardAddress);
            this.utilsService
              .confirmAddress(
                res.finalStandardAddress,
                requestPayload.customerLocation.customerAddress,
                requestPayload.customerLocation.party1.partyName,
                res.customerAddressValidationCd
              )
              .subscribe(({ address, latitude, longitude }) => {
                if (address && address.hasOwnProperty('address')) {
                  requestPayload.ignoreAddressValidation = true;
                  requestPayload.customerLocation.customerAddress.address = address.address;
                  requestPayload.customerLocation.customerAddress.cityName = address.cityName;
                  requestPayload.customerLocation.customerAddress.countryCd = address.countryCd;
                  requestPayload.customerLocation.customerAddress.stateCd = address.stateCd;
                  requestPayload.customerLocation.customerAddress.zipCd = address.zipCd;
                  requestPayload.customerLocation.customerAddress.zip4Cd = address.zip4Cd || '';
                  requestPayload.customerLocation.customerAddress.latitudeNbr = latitude;
                  requestPayload.customerLocation.customerAddress.longitudeNbr = longitude;
                  requestPayload.pstlCertifiedInd = 'N';
                  this.callUpdateCustomer(requestPayload, locationFunctionId, cleanUpFn, hasParentChange, hasZipChange, isAlias);
                } else if (address && address.hasOwnProperty('addressLine1')) {
                  requestPayload.ignoreAddressValidation = true;
                  requestPayload.customerLocation.customerAddress.address = address.addressLine1;
                  requestPayload.customerLocation.customerAddress.cityName = address.cityName;
                  requestPayload.customerLocation.customerAddress.countryCd = address.countryCd;
                  requestPayload.customerLocation.customerAddress.stateCd = address.stateCd;
                  requestPayload.customerLocation.customerAddress.zipCd = address.postalCd;
                  requestPayload.customerLocation.customerAddress.zip4Cd = address.usZip4 || '';
                  requestPayload.pstlCertifiedInd = 'Y';
                  requestPayload.customerLocation.customerAddress.latitudeNbr = address.geoCoordinates.latitude;
                  requestPayload.customerLocation.customerAddress.longitudeNbr = address.geoCoordinates.longitude;
                  this.callUpdateCustomer(requestPayload, locationFunctionId, cleanUpFn, hasParentChange, hasZipChange, isAlias);
                }
              });
            break;
          case res.matchedCustomers?.length > 0:
            this.utilsService
              .duplicateMatch(
                res.matchedCustomers,
                isAlias,
                [requestPayload, locationFunctionId, cleanUpFn, hasParentChange, hasZipChange],
                this.callUpdateCustomer.bind(this)
              )
              .subscribe();
            break;
          default:
            this.getLocationData(locationFunctionId, CustomerIdTypeCd.CUSTOMER_LOCATION_FUNCTION_ID, true).subscribe(
              (locData) => {
                const madChanged =
                  locData.customerLocation?.customerLocationFunction[0]?.madCd !==
                  requestPayload.customerLocation?.customerLocationFunction[0]?.madCd;
                switch (true) {
                  case madChanged &&
                    requestPayload.customerLocation?.customerLocationFunction[0]?.functionCd ===
                    CustomerFunctionCd.PICKUP_OR_DELIVERY &&
                    hasZipChange:
                    this.snackbar.open({
                      message: 'Changes successfully saved.  MAD code changed due to postal code change.',
                      status: 'success',
                      matConfig: {
                        duration: ConstantsService.snackbarDuration,
                      },
                    });
                    break;
                  case madChanged &&
                    requestPayload.customerLocation?.customerLocationFunction[0]?.functionCd ===
                    CustomerFunctionCd.PICKUP_OR_DELIVERY &&
                    hasZipChange &&
                    hasParentChange:
                    this.snackbar.open({
                      message: 'Changes successfully saved.  MAD code changed due to parent and postal code change.',
                      status: 'success',
                      matConfig: {
                        duration: ConstantsService.snackbarDuration,
                      },
                    });
                    break;
                  case madChanged && hasParentChange:
                    this.snackbar.open({
                      message: 'Changes successfully saved.  MAD code changed due to parent change.',
                      status: 'success',
                      matConfig: {
                        duration: ConstantsService.snackbarDuration,
                      },
                    });
                    break;
                  default:
                    if (requestPayload.massAliasInd) {
                      this.snackbar.open({
                        message:
                          'Mass operation in progress. You will receive a confirmation email when the operation finishes',
                        status: 'success',
                        matConfig: {
                          duration: ConstantsService.snackbarDuration,
                        },
                      });
                    } else {
                      this.snackbar.open({
                        message: 'Customer successfully updated.',
                        status: 'success',
                        matConfig: {
                          duration: ConstantsService.snackbarDuration,
                        },
                      });
                    }
                    break;
                }
                if (cleanUpFn) {
                  cleanUpFn();
                }
              }
            );
            break;
        }
      });
    return EMPTY;
  }

  /**
   * Get the first location detail information from the passed Change Request
   * @param changeRequest <ListCisRequestDetailsResp>
   * @return <LocationDetail>
   */
  getFirstLocationDetailFromChangeRequest(changeRequest: CustomerRequest): RequestCustomerLocationFunction {
    return changeRequest.requestCustomerLocationFunction.find((l) => l.actionCd !== 'ADD');
  }

  /**
   * Check if the passed Location Id is a valid code
   * @param locationId <number | string>
   * @return <boolean>
   */
  isLocationIdValid(locationId: number | string): boolean {
    // Valid when: doesn't contain alphabet letters
    return !!locationId;
  }

  /**
   * Check if the passed Location Id is a new one
   * @param locationId <number | string>
   * @return <boolean>
   */
  isNewLocation(locationId: number | string): boolean {
    return !!locationId;
  }

  getInvoiceInstructions(locationId: number): any {
    return this.customerService.getInvoicingInstructions({ custLocFunctionId: locationId }).pipe(
      switchMap((response) => {
        if (response.invInstFullData?.length) {
          response = this.sortInvoices(response);
        }
        this.store.dispatch(new SetInvoicesCurrentLocation({ invoices: response }));
        if (window.location.pathname.includes('change-request')) {
          this.store.select(getRequestedChangeCurrentLine).pipe(skipWhile((line) => _isEqual(line, this.currentLine))).subscribe((resp) => {
            this.currentLine = resp;
            if (resp?.requestCustomerLocationFunction[0]?.requestInvoicePreference) {
              forkJoin([
                ...resp.requestCustomerLocationFunction[0]?.requestInvoicePreference
                  .reduce((acc, invoice) => {
                    if (invoice.sendToCustomerLocationFuncId) {
                      const customerParams: GetCustomerPath = {
                        id: invoice.sendToCustomerLocationFuncId.toString(),
                      };
                      if (!acc.has(invoice)) {
                        acc.set(
                          invoice.sendToCustomerLocationFuncId,
                          this.customerService
                            .getCustomer(customerParams, {
                              customerIdTypeCd: CustomerIdTypeCd.CUSTOMER_LOCATION_FUNCTION_ID,
                              customerDetailCd: [CustomerDetailCd.ACCOUNT],
                              inheritContacts: true,
                            })
                            .pipe(
                              catchError((e) => {
                                return throwError(e);
                              })
                            )
                        );
                      }
                    }
                    return acc;
                  }, new Map())
                  .values(),
              ]).subscribe((locationData) => {
                this.store.dispatch(new SetInvoiceChangesLocationData({ locations: locationData }));
              });
            }
          });
        }
        return of(response.invInstFullData);
      }),
      catchError((err) => throwError(err))
    );
  }

  hasActivePricing(legacyNbr, showOverlay = true): Observable<any> {
    const queryParams = new ListAccountAgreementsQuery();
    queryParams.accountInstIds = [];
    queryParams.accountInstIds[0] = legacyNbr;
    return new Observable((observer) => {
      this.pricingWorkbenchApi
        .listAccountAgreements(queryParams, { toastOnError: false, loadingOverlayEnabled: showOverlay })
        .subscribe(
          (res) => {
            const agreementData = res.accountAgreements[0];
            const firstAgreement = agreementData?.agreements ? agreementData?.agreements[0] : null;

            let lastExpiryDate = firstAgreement?.expiryDate;
            if (agreementData?.agreements) {
              for (const ag of agreementData.agreements) {
                lastExpiryDate = moment(ag.expiryDate).isAfter(lastExpiryDate) ? ag.expiryDate : lastExpiryDate;
              }
              const isActive = moment(lastExpiryDate).isAfter(moment());

              observer.next({ isActive: isActive, expiryDate: lastExpiryDate, agreementData: agreementData });
            } else {
              observer.next({ isActive: false, expiryDate: lastExpiryDate, agreementData: agreementData });
            }
          },
          (err) => {
            if (err?.code === '404' || err?.code === '400') {
              observer.next({ isActive: false });
            } else {
              this.snackbar.open({
                message: 'Service Exception occurred while checking for Pricing Agreement. Retry or contact support.',
                status: 'error',
                matConfig: {
                  duration: ConstantsService.snackbarDuration,
                },
              });
              observer.error(err);
            }
          }
        );
    });
  }

  private sortInvoices(invoices: GetInvoicingInstructionsResp): GetInvoicingInstructionsResp {
    const sortedInvoices = { invInstFullData: [] };
    const prepaidInvoices = invoices.invInstFullData.filter((i) => i.invoiceInstruction.billCd === 'Prepaid');
    const collectInvoices = invoices.invInstFullData.filter((i) => i.invoiceInstruction.billCd === 'Collect');
    const sortedPrepaidInvoices = [];
    const sortedCollectInvoices = [];
    this.fillEmptyInvoices(prepaidInvoices, 'Prepaid');
    this.fillEmptyInvoices(collectInvoices, 'Collect');
    this.sortInvoicesByBillCd(prepaidInvoices, 'Prepaid', sortedPrepaidInvoices);
    this.sortInvoicesByBillCd(collectInvoices, 'Collect', sortedCollectInvoices);
    sortedInvoices.invInstFullData = [...sortedPrepaidInvoices, ...sortedCollectInvoices];

    return sortedInvoices;
  }

  private sortInvoicesByBillCd(invoices, billCd, sortedInvoices): void {
    invoices.forEach((invoice) => {
      const invoiceCd = invoice.invoiceInstruction.invoiceCd;
      let index = 0;
      switch (invoiceCd) {
        case 'Original':
          index = 0;
          break;
        case 'Corrected':
          index = 1;
          break;
        case 'BalDue':
          index = 2;
          break;
        case 'TrueDebtor':
          index = 3;
          break;
      }
      sortedInvoices[index] = invoice;
    });
  }

  private fillEmptyInvoices(invoices, billCd): void {
    ['Original', 'Corrected', 'BalDue', 'TrueDebtor'].forEach((invoiceCd) => {
      const invoice = invoices.find((i) => i.invoiceInstruction.invoiceCd === invoiceCd);

      if (!invoice) {
        invoices.push({
          invoiceInstruction: {
            invoiceCd: invoiceCd,
            billCd: billCd,
            useAsEnteredInd: true,
          },
        });
      }
    });
  }

  private displayErrorMessage(err): Observable<never> {
    if (err?.error?.message?.toLowerCase()?.includes('validation errors')) {
      this.snackbar.open({
        message: err.error.moreInfo[0].message,
        status: 'error',
        matConfig: {
          duration: ConstantsService.snackbarDuration,
        },
      });
    } else {
      this.snackbar.open({
        message: err?.error?.message,
        status: 'error',
        matConfig: {
          duration: ConstantsService.snackbarDuration,
        },
      });
    }
    return throwError(err);
  }
}
