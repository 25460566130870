<div class="ncis-CustomerContacts">
  <div class="ncis-LocationDetails-card">
    <div class="ncis-contacts-requester-notes" *ngIf="hasChanges">
      <p class="ncis-contacts-requester-notes-title">Requester Notes</p>
      <div class="ncis-contacts-requester-notes-content">
        <span>NOTES</span>
        <span>{{ notes }}</span>
      </div>
    </div>
    <div class="ncis-contacts-xpo-board-container" [ngClass]="{ 'no-rows': noData }">
      <xpo-board
        [dataSource]="dataSource"
        [viewDataStore]="viewDataStore"
        [viewTemplates]="viewTemplates"
        [boardOptions]="boardOptions"
      >
        <xpo-board-actions class="ncis-contacts-xpo-board-container__actions" filterActions>
          <button
            mat-icon-button
            [disabled]="userRole$ | userAccess: ['Credit Analyst', 'noUpdateAuthorization'] | async"
            *ngIf="!isChangeRequest"
            id="contact-add-btn"
            (click)="addContact()"
            style="font-size: 14px;"
          >
            <mat-icon style="font-size: 16px; margin-right: 8px;" inline="true">add_circle</mat-icon> Add
          </button>
          <xpo-button-group>
            <ng-container [ngSwitch]="true">
              <ng-container *ngSwitchCase="allSelected">
                <button (click)="toggleAllOrLocal('all')" mat-flat-button>All</button>
                <button (click)="toggleAllOrLocal('local')" mat-stroked-button>Local</button>
              </ng-container>
              <ng-container *ngSwitchCase="localSelected">
                <button (click)="toggleAllOrLocal('all')" mat-stroked-button>All</button>
                <button (click)="toggleAllOrLocal('local')" mat-flat-button>Local</button>
              </ng-container>
            </ng-container>
          </xpo-button-group>
        </xpo-board-actions>
        <xpo-board-no-results-display>
          <xpo-empty-state>
            <xpo-empty-state-icon>
              <mat-icon>insert_drive_file</mat-icon>
            </xpo-empty-state-icon>
            <xpo-empty-state-description>There are no contacts for this location. </xpo-empty-state-description>
          </xpo-empty-state>
        </xpo-board-no-results-display>
        <xpo-ag-grid-board
          rowModelType="client-side"
          rowSelection="false"
          [enableSorting]="true"
          [customGridOptions]="customGridOptions"
          (gridBoardReady)="gridBoardReady($event)"
        >
        </xpo-ag-grid-board>
      </xpo-board>
      <app-custom-pagination [dataSource]="dataSource" [gridApi]="gridApi"></app-custom-pagination>
    </div>
  </div>
</div>
