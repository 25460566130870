import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {XpoAgGridBoardData} from '@xpo-ltl/ngx-board/ag-grid';
import {XpoBoardData, XpoBoardDataSource, XpoBoardState} from '@xpo-ltl/ngx-board/core';
import { AppRoutes } from 'src/app/shared/enums/app-routes.enum';
import { AppState } from 'src/app/store';
import { getContacts } from 'src/app/store/details/details.selectors';
import { getAccountContact } from 'src/app/store/location/location.selectors';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomerContactsService extends XpoBoardDataSource {
  constructor(private store: Store<AppState>, private router: Router) {
    super();
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    const subscription$: Observable<any> = this.isChangeRequest()
      ? this.store.pipe(select(getContacts))
      : this.store.pipe(select(getAccountContact));

    this.pageSize = 10;

    return subscription$.pipe(
      take(1),
      map((resp) => {
        return resp.length === 0
          ? new XpoAgGridBoardData(XpoBoardData.empty<XpoAgGridBoardData>(<XpoBoardState>state))
          : new XpoBoardData(state, resp, resp.length, this.pageSize);
      })
    );
  }

  isChangeRequest(): boolean {
    const isParent = window.location.pathname.includes('parent');
    let parentSequenceNbr;

    if (isParent) {
      const urlPath = window.location.pathname.split('/');
      parentSequenceNbr = +urlPath[urlPath.findIndex((s) => s === 'parent') + 1];
    }
    const urlParts = this.router.routerState.snapshot.url.split('/') || [];
    return isParent ? parentSequenceNbr > 0 : urlParts.findIndex((item) => item === AppRoutes.CHANGE_REQUEST_PAGE) !== -1;
  }
}
