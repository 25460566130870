import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  CustomerApiService,
  GetServiceRecipientHistoryPath,
  GetServiceRecipientHistoryQuery,
  ServiceRecipientHist,
} from '@xpo-ltl-2.0/sdk-customer';
import { ListInfo } from '@xpo-ltl/sdk-common';
import { ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import moment from 'moment';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { EmployeesService } from 'src/app/change-request-page/services/employees.service';
import { AppState } from 'src/app/store';
import { SetIsProfile } from 'src/app/store/service-recipient/service-recipient.actions';
import { ActionCodeDetails, HistoryActionCode } from '../../enums/action-code.enum';

@Component({
  selector: 'service-recipient-history-component',
  templateUrl: './service-recipient-history.component.html',
  styleUrls: ['./service-recipient-history.component.scss'],
})
export class ServiceRecipientHistoryComponent implements OnInit, OnDestroy {
  private gridApi;
  private destroy$ = new Subject();
  serviceRecipientNbr;

  constructor(
    private customerApi: CustomerApiService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private employeeService: EmployeesService
  ) {
    this.noRowsTemplate = `<span">No History Found.</span>`;
  }

  gridInstructionOptions: GridOptions = {
    defaultColDef: {
      sortable: false,
      filter: false,
      floatingFilterComponentParams: { suppressFilterButton: true },
      suppressMenu: true,
      resizable: true,
      flex: 1,
    },
    pagination: true,
    paginationPageSize: 25,
  };

  columnInstructionDefs: ColDef[] = [
    { headerName: 'Action', field: 'histActionCd' },
    { headerName: 'Number', field: 'serviceRecipientNbr' },
    { headerName: 'Name', field: 'serviceRecipientName' },
    { headerName: 'Type', field: 'typeOfServiceInd' },
    { headerName: 'First Name', field: 'contactFirstName' },
    { headerName: 'Last Name', field: 'contactLastName' },
    { headerName: 'Email', field: 'email' },
    { headerName: 'Last Updated by', field: 'sourceLastUpdateBy' },
    { headerName: 'Updated on', field: 'sourceLastUpdateDateTime', valueFormatter: this.dateFormat },
    { headerName: 'Remarks', field: 'remarks' },
  ];

  noRowsTemplate: string;

  rowData: Array<ServiceRecipientHist> = [];

  ngOnInit(): void {
    this.store.dispatch(new SetIsProfile({ isProfile: true }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(({ serviceRecipientNbr }) => {
      if (+serviceRecipientNbr > 0) {
        this.serviceRecipientNbr = serviceRecipientNbr;

        const queryParams = new GetServiceRecipientHistoryQuery();
        queryParams.listInfo = new ListInfo();
        queryParams.listInfo.startAt = 0;
        const pathParams = new GetServiceRecipientHistoryPath();
        pathParams.serviceRecipientNbr = this.serviceRecipientNbr;
        this.customerApi
          .getServiceRecipientHistory(pathParams, queryParams)
          .pipe(
            map((resp) => {
              return resp.serviceRecipientHistory.map((history) => {
                history['histActionCd'] = HistoryActionCode[history.histActionCd];
                return history;
              });
            })
          )
          .subscribe((data) => {
            if (data) {
              // const historyData = data.map((row) => {
              //   this.employeeService.getEmployee(row.sourceCreatedBy).subscribe((name) => {
              //     row['sourceCreatedBy'] = name;
              //   });
              //   return row;
              // });
              this.rowData = data;
            }
          });
      }
    });
  }

  onFirstDataRendered(params): void {
    params.api.sizeColumnsToFit();
  }

  dateFormat(params): string {
    if (params && params.value) {
      return moment(params.value).format('MM/DD/YYYY HH:mm');
    } else {
      return '';
    }
  }
}
