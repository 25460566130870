import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CustomerApiService } from '@xpo-ltl-2.0/sdk-customer';
import { CustomerRequestStatusCd } from '@xpo-ltl/sdk-common';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { ChangeRequestService } from 'src/app/change-request-page/services/change-request.service';
import { AppRoutes } from 'src/app/shared/enums/app-routes.enum';
import { ValidatorHelper } from 'src/app/shared/validators';
import { AppState } from 'src/app/store';
import { getChangeRequestData } from 'src/app/store/change-request/change-request.selectors';
import { DialogComponent, DialogConfig } from '../dialog';

export const NEXT = 'next';
export const DASHBOARD = 'dashboard';
@Component({
  selector: 'app-complete-request-dialog',
  templateUrl: './complete-request-dialog.component.html',
  styleUrls: ['./complete-request-dialog.component.scss'],
})
export class CompleteRequestDialogComponent implements OnInit {
  private ccEmailsRequest: string;
  private requestQueueName;
  private cstRequestId;
  isCancelRequest = false;
  closeRequest = false;
  form: FormGroup;
  title = 'You have processed all the line items for this request';

  constructor(
    private customerApi: CustomerApiService,
    private store: Store<AppState>,
    private router: Router,
    private dialogRef: MatDialogRef<CompleteRequestDialogComponent>,
    private dialog: MatDialog,
    private changeRequestService: ChangeRequestService,
  ) { }

  ngOnInit(): void {
    this.title = this.isCancelRequest ? 'No Pending Changes Found. Any selected option will cancel the request.' : this.title;
    this.store.pipe(select(getChangeRequestData), take(1)).subscribe((state) => {
      this.requestQueueName = state.requestQueueName;
      this.cstRequestId = state.cstRequestId;
      this.ccEmailsRequest = state.requestEmailAddress?.map((ccEmail) => ccEmail?.emailAddress)?.join(', ');
      this.createForm();
    });
  }

  nextRequest(): void {
    this.isCancelRequest || this.closeRequest ? this.cancelAndNext(NEXT) : this.completeAndNext();
  }

  completeAndNext(): void {
    this.completeRequest()
      .pipe(take(1))
      .subscribe((_) => {
        this.getNextRequest();
      });
  }

  cancelAndNext(action: string): void {
    this.cancelOrCompleteRequest(this.isCancelRequest ? CustomerRequestStatusCd.CANCEL : CustomerRequestStatusCd.COMPLETED)
      .pipe(take(1),
        switchMap(() => {
          return this.completeRequest();
        })
      ).subscribe((_) => {
        if (action === NEXT) {
          this.getNextRequest();
        } else {
          this.closeAndRedirectToDashboard();
        }
    });
  }

  closeAndRedirectToDashboard(): void {
    this.dialogRef.close();
    this.router.navigate([AppRoutes.DASHBOARD_CODER_PAGE]);
  }

  getNextRequest(): void {
    this.customerApi
      .getRequestGrabAndLock({ rqstQueueName: this.requestQueueName })
      .pipe(take(1))
      .subscribe((res) => {
        if (res.requestId) {
          // Reset Change Request Store
          this.changeRequestService.resetStore();
          this.router.navigate([AppRoutes.CHANGE_REQUEST_PAGE, res.requestId]);
        } else {
          const dialog: DialogConfig = {
            title: 'There are no more requests in the queue.',
            icon: 'report_problem',
            actions: [
              {
                label: 'GO TO DASHBOARD',
                type: 'primary',
                resultType: 'CLOSE',
                resultAction: false,
                position: 'right',
              },
            ],
          };
          this.dialog
            .open(DialogComponent, { maxWidth: '600px', data: dialog })
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
              this.router.navigate([AppRoutes.DASHBOARD_CODER_PAGE]);
            });
        }
        this.dialogRef.close();
      });
  }

  goToDashboard(): void {
    if (this.isCancelRequest || this.completeRequest) {
      this.cancelAndNext(DASHBOARD);
    } else {
      this.completeRequest()
        .pipe(take(1))
        .subscribe((_) => {
           this.closeAndRedirectToDashboard();
        });
    }
  }

  stayOnRequest(): void {
    this.completeRequest()
      .pipe(take(1))
      .subscribe((_) => {
        this.dialogRef.close({ stayOnRequest: true });
      });
  }

  getCcEmails(emails): Array<string> {
    const emailList = [];
    emails = emails.split(',');
    emails.forEach((email) => {
      email = email.trim();
      emailList.push(email);
    });
    return emailList;
  }

  private createForm(): void {
    this.form = new FormGroup({
      emailCopyList: new FormControl(this.ccEmailsRequest || '', [
        ValidatorHelper.ccEmailFormatValidator(),
        ValidatorHelper.emailsFormatValidator(),
        ValidatorHelper.emailsMaxLengthValidator(),
      ]),
      comments: this.isCancelRequest ? new FormControl('No changes to process in the request') :  new FormControl(''),
    });
  }

  private completeRequest(): Observable<any> {
    const errorHandler = (err) => {
      this.dialogRef.close();
      return throwError(err);
    };

    const emailList = this.form.value.emailCopyList ? this.getCcEmails(this.form.value.emailCopyList) : [];

    if (emailList.length) {
      return this.customerApi
        .sendEmailForCompletedRequest({
          commentTxt: this.form.controls['comments'].value,
          forwardEmailAddresses: emailList.map((e) => e.trim()),
          requestId: this.cstRequestId,
        })
        .pipe(catchError(errorHandler), take(1));
    }
    return of(true);
  }

  private cancelOrCompleteRequest(status: CustomerRequestStatusCd): Observable<any> {
    const errorHandler = (err) => {
      this.dialogRef.close();
      return throwError(err);
    };
    return this.customerApi.updateRequestStatus({
        requestStatusCd: status
      },
      {requestId: this.cstRequestId
      })
      .pipe(catchError(errorHandler), take(1));
  }
}
