import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { XpoBoardState } from '@xpo-ltl/ngx-board/core';
import { GridApi, IServerSideDatasource } from 'ag-grid-community';
import { ServiceRecipientSearchService } from 'src/app/service-recipient-page/services/service-recipient-search.service';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss'],
})
export class CustomPaginationComponent implements OnInit, OnChanges {
  @Input('gridApi') api: GridApi;
  @Input() dataSource: IServerSideDatasource;

  currentPage = 1;
  totalPages = 1;
  numberOfRows = 1;
  defaultPageSize = 25;
  pageSizesOptions = [25, 50, 100];
  pageSizeInfo = '';
  currentPageSize = 25;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.api) {
      this.api.paginationSetPageSize(this.defaultPageSize);
      this.api.addEventListener('paginationChanged', () => {
        const page = this.api.paginationGetCurrentPage();
        this.currentPage = page + 1;
        this.totalPages = this.api.paginationGetTotalPages();
        this.numberOfRows = this.api.paginationGetRowCount();
        this.pageSizeInfo = `${this.currentPage === 1 ? 1 : page * this.currentPageSize + 1} to ${
          this.totalPages === 1 || this.currentPage === this.totalPages
            ? this.numberOfRows
            : this.api.paginationGetPageSize() * this.currentPage
        } of ${this.numberOfRows}`;
      });
    }
  }

  goToFirst(): void {
    this.api.paginationGoToFirstPage();
  }

  goToLast(): void {
    this.api.paginationGoToLastPage();
  }

  goToNextPage(): void {
    this.api.paginationGoToNextPage();
  }

  goToPreviousPage(): void {
    this.api.paginationGoToPreviousPage();
  }

  setPaginationSize(event: Event): void {
    this.currentPageSize = Number((<HTMLSelectElement>event.target).value);
    (<any>this.api).gridOptionsWrapper.setProperty('cacheBlockSize', this.currentPageSize);
    this.api.paginationSetPageSize(this.currentPageSize);
    if (this.dataSource) {
      this.api.setServerSideDatasource(this.dataSource);
    }
  }

  goToPage(page: number): void {
    this.api.paginationGoToPage(page - 1);
  }
}
