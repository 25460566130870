import { Injectable } from '@angular/core';
import { HumanResourceApiService, InterfaceEmployee } from '@xpo-ltl-2.0/sdk-humanresource';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  employeesList: Map<string, InterfaceEmployee> = new Map();

  constructor(private hrApiService: HumanResourceApiService) {}

  addEmployee(employee: InterfaceEmployee): void {
    this.employeesList.set(employee.employeeId, employee);
  }

  getEmployee(id: string, isWebTeam = false, jobDesc = false): Observable<string> {
    if (isWebTeam) { return of('WEB_TEAM'); }
    if (this.employeesList.has(id)) {
      return of(this.getName(this.employeesList.get(id), jobDesc));
    } else {
      return this.hrApiService.getInterfaceEmployee({ employeeId: id }, { loadingOverlayEnabled: false }).pipe(
        map((response) => {
          const employee = (response?.data ?? <any>response).interfaceEmployee;
          if (employee) {
            this.addEmployee(employee);
            return this.getName(employee, jobDesc);
          }
          return 'Inactive employee';
        })
      );
    }
  }

  getName(employee: InterfaceEmployee, jobDesc: boolean): string {
    const firstLast = `${employee.firstName} ${employee.lastName}`;
    return jobDesc ? `${firstLast} (${employee.jobDescription})` : firstLast;
  }
}
