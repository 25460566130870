import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { XpoAgGridColumns } from '@xpo-ltl/ngx-ag-grid';
import { XpoAgGridBoardApi, XpoAgGridBoardReadyEvent, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-board/ag-grid';
import {
  XpoBoardOptions,
  XpoBoardState,
  XpoBoardViewConfig,
  XpoLocalStorageBoardViewDataStore,
} from '@xpo-ltl/ngx-board/core';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { ReplaySubject } from 'rxjs';
import { LinkCellRendererComponent } from 'src/app/location-details-page/company-hierarchy/components/link-cell-renderer/link-cell-renderer.component';
import { AppRoutes } from '../../enums/app-routes.enum';
import { GridApiContextService } from '../../services/grid-api-context/grid-api-context.service';
import { ServiceRecipientResultDialogService } from './services/service-recipient-result-dialog.service';

@Component({
  selector: 'app-service-recipient-result-dialog',
  templateUrl: './service-recipient-result-dialog.component.html',
  styleUrls: ['./service-recipient-result-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ServiceRecipientResultDialogComponent implements OnInit {
  customGridOptions: GridOptions;
  boardOptions: XpoBoardOptions;
  gridApi: GridApi;
  colApi: ColumnApi;
  gridBoardApi: XpoAgGridBoardApi;
  rowSelected = '';
  stateChange$ = new ReplaySubject<XpoBoardState>(1);
  search = '';
  selectedRow = false;

  readonly viewDataStore: XpoLocalStorageBoardViewDataStore;
  readonly viewTemplates: XpoAgGridBoardViewTemplate[];

  constructor(
    private dialog: MatDialogRef<ServiceRecipientResultDialogComponent>,
    public dataSource: ServiceRecipientResultDialogService,
    private gridApiContextService: GridApiContextService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.viewTemplates = this.getBoardViewTemplates();
    this.viewDataStore = new XpoLocalStorageBoardViewDataStore('client-side-recipient-dialog', this.getBoardViews());
    this.search = this.data.search;
    this.dataSource.setSearch(this.search);
  }

  ngOnInit(): void {
    this.boardOptions = {
      addNewViewButtonLabel: '',
      suppressViewSwitcher: true,
      enableFilterReset: false,
      enableQueryParamStatePersistance: false,
      persistFiltersBetweenViews: false,
      preloadViewData: false,
      suppressGridDensity: true,
      suppressGridSettingsPopover: true,
      suppressRecordCounts: false,
      suppressLastUpdateTime: true,
    };

    this.customGridOptions = {
      suppressRowClickSelection: true,
      localeText: { noRowsToShow: 'No Service Recipients were found for your search criteria.' },
      frameworkComponents: {
        linkRenderer: LinkCellRendererComponent,
      },
      animateRows: true,
      multiSortKey: 'ctrl',
      pagination: true,
      defaultColDef: {
        suppressMenu: true,
        resizable: true,
      },
      domLayout: 'normal',
      suppressPaginationPanel: true,
      onRowSelected: (params) => {
        this.selectedRow = params.api.getSelectedRows().length === 1;
        if (this.selectedRow) {
          this.rowSelected = params.api.getSelectedRows()[0];
        }
      },
    };
  }

  refreshGrid(): void {
    this.dataSource.refresh();
  }

  saveRecipient(): void {
    this.dialog.close(this.rowSelected);
  }

  close(): void {
    this.dialog.close();
  }

  gridBoardReady(gridEvent: XpoAgGridBoardReadyEvent): void {
    this.gridApi = gridEvent.agGridApi;
    this.gridApiContextService.saveGridApi(this.gridApi);
  }

  private getBoardViews(): XpoBoardViewConfig[] {
    return [
      {
        templateId: 'template1',
        name: 'All - SD',
        closeable: false,
      },
    ];
  }

  private getBoardViewTemplates(): XpoAgGridBoardViewTemplate[] {
    const indexCol = {
      ...XpoAgGridColumns.RowIndex,
    };
    indexCol.suppressSizeToFit = true;
    return [
      new XpoAgGridBoardViewTemplate({
        id: 'template1',
        name: 'Template 1',
        allowAdditional: true,
        availableColumns: [
          indexCol,
          {
            checkboxSelection: true,
            sortable: false,
            floatingFilter: false,
          },
          {
            headerName: 'Number',
            field: 'serviceRecipientNbr',
            colId: 'serviceRecipientNbr',
            minWidth: 150,
            sortable: false,
            cellRenderer: 'linkRenderer',
            cellRendererParams: ({ data }) => {
              return {
                url: data && `${AppRoutes.SERVICE_RECIPIENT_PAGE}/profile/` + data.serviceRecipientNbr,
                text: data && data.serviceRecipientNbr,
              };
            },
          },
          {
            headerName: 'Name',
            field: 'serviceRecipientName',
            colId: 'serviceRecipientName',
            minWidth: 290,
            resizable: true,
          },
          {
            headerName: 'Type',
            field: 'typeOfServiceInd',
            colId: 'typeOfServiceInd',
            minWidth: 100,
          },
          {
            headerName: 'Address',
            field: 'address',
            colId: 'address',
            minWidth: 250,
          },
          {
            headerName: 'City',
            field: 'city',
            colId: 'city',
            minWidth: 200,
          },
          {
            headerName: 'State',
            field: 'state',
            colId: 'state',
            minWidth: 80,
            headerClass: 'state-header',
          },
          {
            headerName: 'Postal Code',
            field: 'zipCd',
            colId: 'zipCd',
            valueGetter: ({ data }) => {
              return data ? (data.zip4RestUs ? `${data.zip6}-${data.zip4RestUs}` : data.zip6) : '';
            },
            minWidth: 100,
          },
          {
            headerName: 'First Name',
            field: 'contactFirstName',
            colId: 'contactFirstName',
            minWidth: 200,
          },
          {
            headerName: 'Last Name',
            field: 'contactLastName',
            colId: 'contactLastName',
            minWidth: 200,
          },
          {
            headerName: 'Email',
            field: 'email',
            colId: 'email',
            minWidth: 250,
          },
          {
            headerName: 'Area Code',
            field: 'contactAreaCd',
            colId: 'contactAreaCd',
            minWidth: 80,
          },
          {
            headerName: 'Phone Number',
            field: 'contactPhone',
            colId: 'contactPhone',
            minWidth: 100,
          },
        ],
        keyField: 'id',
      }),
    ];
  }
}
