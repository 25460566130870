import { RequestCustomerLocationFunction } from '@xpo-ltl-2.0/sdk-customer';
import { CustomerAccountTypeCd, CustomerCreditStatusCd, CustomerFunctionCd } from '@xpo-ltl/sdk-common';
import { YesNo, YesNoValue } from 'src/app/change-request-page/models/enums/yes-no';
import { AccountDetails } from 'src/app/location-details-page/company-information/models/account-details';
import { AccountType } from 'src/app/shared/enums/account-form/account-type.enum';
import { Country, CountryValue } from 'src/app/shared/enums/account-form/country.enum';
import { CreditStatus, CreditStatusValue } from 'src/app/shared/enums/account-form/credit-status.enum';
import { PaymentTerms, PaymentTermsValue } from 'src/app/shared/enums/account-form/payment-terms.enum';
import { CustomerFunctionCdLabel } from 'src/app/shared/enums/function-cdl.enum';
import { UserRole } from 'src/app/shared/enums/user-role/user-role.enum';
import { ValidatorHelper } from 'src/app/shared/validators';

export function getNewLocationSchema(
  changeRequestData: RequestCustomerLocationFunction,
  loggedInUserRole = UserRole.Coder
): AccountDetails[] {
  let accountDetails = [];

  switch (changeRequestData?.functionCd) {
    case CustomerFunctionCd.CORPORATE:
      accountDetails = [
        {
          name: 'Account',
          isHeader: true,
        },
        {
          name: 'LOCATION TYPE',
          editable: false,
          type: 'text',
          requestedValue: CustomerFunctionCdLabel.Corporate,
          key: 'functionCd',
        },
        {
          name: 'STATUS',
          key: 'customerStatusCd',
          required: true,
          type: 'text',
          validators: [ValidatorHelper.required('Enter value for required field.')],
          editable: false,
          disable: loggedInUserRole === UserRole.CreditAnalyst,
          requestedValue: 'Pending Approval',
        },
        {
          name: 'ACCOUNT TYPE',
          value: changeRequestData?.acctTypeCd || CustomerAccountTypeCd.LOCAL_ACCOUNT,
          key: 'acctTypeCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: AccountType.LocalAccount, value: CustomerAccountTypeCd.LOCAL_ACCOUNT },
            { label: AccountType.NationalAccount, value: CustomerAccountTypeCd.NATIONAL_ACCOUNT },
          ],
          editable: true,
          validators: [ValidatorHelper.required('Enter value for required field.')],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
          requestedValue: changeRequestData?.acctTypeCd || CustomerAccountTypeCd.LOCAL_ACCOUNT,
        },
        {
          name: 'GOVERNMENT',
          key: 'govtCustomerInd',
          required: true,
          type: 'select',
          options: [
            { label: YesNo.TRUE, value: YesNo.TRUE },
            { label: YesNo.FALSE, value: YesNo.FALSE },
          ],
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.notBothValidator('affiliateInd', 'Cannot have Government and Affiliate enabled.'),
          ],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'AFFILIATE',
          key: 'affiliateInd',
          required: true,
          type: 'select',
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.notBothValidator('govtCustomerInd', 'Cannot have Government and Affiliate enabled.'),
          ],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'PARENT',
          customerNbr: changeRequestData?.parentCorpCustomerLocationFuncId || '',
          key: 'parentCorpCustomerLocationFuncId',
          required: false,
          type: 'parent',
          editable: true,
          value: changeRequestData?.parentCorpCustomerLocationFuncId,
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'MAD CODE',
          key: 'madCd',
          editable: false,
          type: 'text',
          currentValue: '',
        },
        {
          name: 'SALES OWNER',
          value: changeRequestData?.salesTerritoryCd,
          key: 'salesTerritoryCd',
          type: 'text',
          editable: true,
          required: false,
          validators: [ValidatorHelper.maxLength(5, 'Must contain less than 6 characters.')],
        },
        { name: 'Location', isHeader: true },
        {
          name: 'NAME',
          key: 'name1',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.minLength(2, 'Must contain at least 2 letters.'),
            ValidatorHelper.pattern('^[\\w %#&,@\\.\\-\\\']*$', 'Name has invalid characters.'),
            ValidatorHelper.beginWithThe(),
            ValidatorHelper.spacesFormatValidator(),
          ],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'NAME2',
          key: 'name2',
          required: false,
          type: 'text',
          validators: [
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.pattern('^[\\w %#&,@:\\.\\-\\\']*$', 'Name2 has invalid characters.'),
          ],
          editable: true,
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'ADDRESS',
          key: 'address',
          required: true,
          type: 'text',
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.pattern('^[\\w áéíóúÁÉÍÓÚ%#&,@:/\\.\\-\\\']*$', 'Address has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
          ],
          editable: true,
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'CITY',
          key: 'cityName',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(20, 'Must contain less than 20 characters.'),
            ValidatorHelper.pattern('^[a-zA-Z&\\-/ ]*$', 'City has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
          ],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'STATE',
          country: changeRequestData?.countryCd,
          key: 'stateCd',
          required: true,
          type: 'state',
          editable: true,
          validators: [ValidatorHelper.required('State is required.')],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'POSTAL CODE',
          key: 'zipCd',
          country: changeRequestData?.countryCd,
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.pattern('^[a-zA-Z0-9\\-/ ]*$', 'Postal Code has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
            ValidatorHelper.zipCdValidator,
          ],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'ZIP 4',
          key: 'zip4Cd',
          type: 'text',
          editable: true,
          disable: loggedInUserRole === UserRole.CreditAnalyst,
          validators: [ValidatorHelper.zip4CdValidator],
        },
        {
          name: 'COUNTRY',
          key: 'countryCd',
          required: true,
          type: 'select',
          options: [
            { label: Country.unitedStates, value: CountryValue.unitedStates },
            { label: Country.canada, value: CountryValue.canada },
            { label: Country.mexico, value: CountryValue.mexico },
          ],
          editable: true,
          validators: [ValidatorHelper.required('Country is required.')],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'Financial',
          isHeader: true,
        },
        {
          name: 'INHERIT CREDIT FROM PARENT',
          key: 'inheritCd',
          required: true,
          type: 'checkbox',
          editable: true,
          disable: false,
          financial: true,
          currentValue: '',
        },
        {
          name: 'BANKRUPT',
          key: 'bankruptInd',
          required: true,
          type: 'checkbox',
          financial: true,
          editable: true,
          requestedValue: changeRequestData?.bankruptInd,
        },
        {
          name: 'CREDIT STATUS',
          value: changeRequestData?.creditStatusCd,
          key: 'creditStatusCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: CreditStatus.Credit, value: CreditStatusValue.Credit },
            { label: CreditStatus.NoCredit, value: CreditStatusValue.NoCredit },
            { label: CreditStatus.Voluntary, value: CreditStatusValue.Voluntary },
            { label: CreditStatus.Cash, value: CreditStatusValue.Cash },
          ],
          editable: true,
          financial: true,
        },
        {
          name: 'CREDIT LIMIT',
          key: 'authorizationLimitAmount',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(11, 'Must contain less than 11 characters.'),
            ValidatorHelper.pattern('^[0-9]*$', 'Credit Limit has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
          ],
          financial: true,
        },
        {
          name: 'HRC ID',
          key: 'authorizationNbr',
          required: CreditStatus[changeRequestData?.creditStatusCd] === CreditStatusValue.Credit,
          type: 'text',
          editable: true,
          validators: [ValidatorHelper.maxLength(11, 'Must contain less than 11 characters.')],
          financial: true,
        },
        {
          name: 'PAYMENT TERMS',
          value:
            changeRequestData?.paymentTermCd || changeRequestData.creditStatusCd === CustomerCreditStatusCd.CREDIT
              ? PaymentTermsValue.Term30Days
              : PaymentTermsValue.Immediate,
          key: 'paymentTermCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: PaymentTerms.Delinquent, value: PaymentTermsValue.Delinquent },
            { label: PaymentTerms.Immediate, value: PaymentTermsValue.Immediate },
            { label: PaymentTerms.Term15Days, value: PaymentTermsValue.Term15Days },
            { label: PaymentTerms.Term20Days, value: PaymentTermsValue.Term20Days },
            { label: PaymentTerms.Term30Days, value: PaymentTermsValue.Term30Days },
            { label: PaymentTerms.Term35Days, value: PaymentTermsValue.Term35Days },
            { label: PaymentTerms.Term40Days, value: PaymentTermsValue.Term40Days },
            { label: PaymentTerms.Term45Days, value: PaymentTermsValue.Term45Days },
            { label: PaymentTerms.Term50Days, value: PaymentTermsValue.Term50Days },
            { label: PaymentTerms.Term55Days, value: PaymentTermsValue.Term55Days },
            { label: PaymentTerms.Term60Days, value: PaymentTermsValue.Term60Days },
            { label: PaymentTerms.Term75Days, value: PaymentTermsValue.Term75Days },
            { label: PaymentTerms.Term90Days, value: PaymentTermsValue.Term90Days },
            { label: PaymentTerms.Term120Days, value: PaymentTermsValue.Term120Days },
            { label: PaymentTerms.Term180Days, value: PaymentTermsValue.Term180Days },
          ],
          editable: true,
          financial: true,
          validators: [ValidatorHelper.required('Enter value for required field.')],
          requestedValue: changeRequestData.paymentTermCd || PaymentTermsValue.Term30Days,
        },
        {
          name: 'INHERIT D&B FROM PARENT',
          key: 'inheritCdDb',
          required: false,
          type: 'checkbox',
          editable: true,
          disable: !changeRequestData.parentCorpCustomerLocationFuncId,
          financial: true,
        },
        {
          name: 'DUNS',
          key: 'dbCustomerNbr',
          required: false,
          type: 'text',
          editable: true,
          validators: [ValidatorHelper.pattern('^[0-9]{9}$', 'Duns must be 9 digits.')],
          financial: true,
        },
        {
          name: 'NAICS CODE',
          required: false,
          type: 'text',
          key: 'standardIndlCd',
          editable: true,
          validators: [ValidatorHelper.pattern('^[0-9]{1,6}$', 'NAICS Code must be up to 6 digits.')],
          financial: true,
        },
        {
          name: 'TAX ID',
          required: false,
          type: 'text',
          key: 'taxId',
          editable: true,
          financial: true,
          validators: [ValidatorHelper.taxIdValidator()],
        },
      ];
      break;
    case CustomerFunctionCd.BILL_TO:
      accountDetails = [
        {
          name: 'Account',
          isHeader: true,
        },
        {
          name: 'LOCATION TYPE',
          editable: false,
          type: 'text',
          requestedValue: CustomerFunctionCdLabel['Bill-To'],
          key: 'functionCd',
        },
        {
          name: 'STATUS',
          key: 'customerStatusCd',
          required: true,
          type: 'text',
          validators: [ValidatorHelper.required('Enter value for required field.')],
          editable: false,
          disable: loggedInUserRole === UserRole.CreditAnalyst,
          requestedValue: 'Pending Approval',
        },
        {
          name: 'ACCOUNT TYPE',
          value:
            changeRequestData?.acctTypeCd || changeRequestData?.parentCorpMadCd
              ? CustomerAccountTypeCd.INHERIT_FROM_PARENT
              : CustomerAccountTypeCd.LOCAL_ACCOUNT,
          key: 'acctTypeCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: AccountType.InheritFromParent, value: CustomerAccountTypeCd.INHERIT_FROM_PARENT },
            { label: AccountType.LocalAccount, value: CustomerAccountTypeCd.LOCAL_ACCOUNT },
            { label: AccountType.NationalAccount, value: CustomerAccountTypeCd.NATIONAL_ACCOUNT },
          ],
          editable: true,
          validators: [ValidatorHelper.required('Enter value for required field.')],
          disable: true,
          requestedValue:
            changeRequestData.acctTypeCd || changeRequestData?.parentCorpMadCd
              ? CustomerAccountTypeCd.INHERIT_FROM_PARENT
              : CustomerAccountTypeCd.LOCAL_ACCOUNT,
        },
        {
          name: 'RESTRICTED',
          key: 'restrictChargeInd',
          required: true,
          type: 'select',
          options: [
            { label: YesNo.TRUE, value: YesNoValue.true },
            { label: YesNo.FALSE, value: YesNoValue.false },
          ],
          editable: false,
          validators: [ValidatorHelper.required('Enter value for required field.')],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
          requestedValue: YesNo.FALSE,
        },
        {
          name: 'PARENT',
          customerNbr: changeRequestData?.parentCorpCustomerLocationFuncId || '',
          key: 'parentCorpCustomerLocationFuncId',
          required: false,
          type: 'parent',
          editable: true,
          value: changeRequestData?.parentCorpCustomerLocationFuncId,
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'MAD CODE',
          key: 'madCd',
          editable: false,
          type: 'text',
          currentValue: '',
        },
        { name: 'Location', isHeader: true },
        {
          name: 'NAME',
          key: 'name1',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.minLength(2, 'Must contain at least 2 letters.'),
            ValidatorHelper.pattern('^[\\w %#&,@\\.\\-\\\']*$', 'Name has invalid characters.'),
            ValidatorHelper.beginWithThe(),
            ValidatorHelper.spacesFormatValidator(),
          ],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'NAME2',
          key: 'name2',
          required: false,
          type: 'text',
          validators: [
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.pattern('^[\\w %#&,@:\\.\\-\\\']*$', 'Name2 has invalid characters.'),
          ],
          editable: true,
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'ADDRESS',
          key: 'address',
          required: true,
          type: 'text',
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.pattern('^[\\w áéíóúÁÉÍÓÚ%#&,@:/\\.\\-\\\']*$', 'Address has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
          ],
          editable: true,
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'CITY',
          key: 'cityName',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(20, 'Must contain less than 20 characters.'),
            ValidatorHelper.pattern('^[a-zA-Z&\\-/ ]*$', 'City has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
          ],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'STATE',
          country: changeRequestData?.countryCd,
          key: 'stateCd',
          required: true,
          type: 'state',
          editable: true,
          validators: [ValidatorHelper.required('State is required.')],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'POSTAL CODE',
          key: 'zipCd',
          country: changeRequestData?.countryCd,
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.pattern('^[a-zA-Z0-9\\-/ ]*$', 'Postal Code has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
            ValidatorHelper.zipCdValidator,
          ],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'ZIP 4',
          key: 'zip4Cd',
          type: 'text',
          editable: true,
          disable: loggedInUserRole === UserRole.CreditAnalyst,
          validators: [ValidatorHelper.zip4CdValidator],
        },
        {
          name: 'COUNTRY',
          key: 'countryCd',
          required: true,
          type: 'select',
          options: [
            { label: Country.unitedStates, value: CountryValue.unitedStates },
            { label: Country.canada, value: CountryValue.canada },
            { label: Country.mexico, value: CountryValue.mexico },
          ],
          editable: true,
          validators: [ValidatorHelper.required('Country is required.')],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'Financial',
          isHeader: true,
        },
        {
          name: 'INHERIT CREDIT FROM PARENT',
          key: 'inheritCd',
          required: true,
          type: 'checkbox',
          editable: true,
          disable: !!changeRequestData?.parentCorpCustomerLocationFuncId,
          requestedValue: !!changeRequestData?.parentCorpCustomerLocationFuncId,
          financial: true,
        },
        {
          name: 'BANKRUPT',
          key: 'bankruptInd',
          required: true,
          type: 'checkbox',
          financial: true,
          editable: true,
          disable: !!changeRequestData?.parentCorpCustomerLocationFuncId,
          requestedValue: changeRequestData?.bankruptInd,
        },
        {
          name: 'CREDIT STATUS',
          value:
            changeRequestData?.creditStatusCd ||
            (changeRequestData?.parentCorpCustomerLocationFuncId ? undefined : CreditStatusValue.NoCredit),
          key: 'creditStatusCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: CreditStatus.Credit, value: CreditStatusValue.Credit },
            { label: CreditStatus.NoCredit, value: CreditStatusValue.NoCredit },
            { label: CreditStatus.Voluntary, value: CreditStatusValue.Voluntary },
            { label: CreditStatus.Cash, value: CreditStatusValue.Cash },
          ],
          editable: true,
          financial: true,
          disable: !!changeRequestData?.parentCorpCustomerLocationFuncId,
        },
        {
          name: 'CREDIT LIMIT',
          key: 'authorizationLimitAmount',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(11, 'Must contain less than 11 characters.'),
            ValidatorHelper.pattern('^[0-9]*$', 'Credit Limit has invalid characters.'),
            ValidatorHelper.creditLimitValidator(changeRequestData?.creditStatusCd),
            ValidatorHelper.spacesFormatValidator(),
          ],
          financial: true,
          disable: !!changeRequestData?.parentCorpCustomerLocationFuncId,
        },
        {
          name: 'HRC ID',
          key: 'authorizationNbr',
          required: false,
          type: 'text',
          editable: true,
          validators: [ValidatorHelper.maxLength(11, 'Must contain less than 11 characters.')],
          financial: true,
          requestedValue:
            changeRequestData?.creditStatusCd || (changeRequestData?.parentCorpCustomerLocationFuncId ? undefined : ''),
        },
        {
          name: 'PAYMENT TERMS',
          value:
            changeRequestData?.paymentTermCd ||
            (changeRequestData?.parentCorpCustomerLocationFuncId
              ? undefined
              : changeRequestData.creditStatusCd === CustomerCreditStatusCd.CREDIT
                ? PaymentTermsValue.Term30Days
                : PaymentTermsValue.Immediate),
          key: 'paymentTermCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: PaymentTerms.Delinquent, value: PaymentTermsValue.Delinquent },
            { label: PaymentTerms.Immediate, value: PaymentTermsValue.Immediate },
            { label: PaymentTerms.Term15Days, value: PaymentTermsValue.Term15Days },
            { label: PaymentTerms.Term20Days, value: PaymentTermsValue.Term20Days },
            { label: PaymentTerms.Term30Days, value: PaymentTermsValue.Term30Days },
            { label: PaymentTerms.Term35Days, value: PaymentTermsValue.Term35Days },
            { label: PaymentTerms.Term40Days, value: PaymentTermsValue.Term40Days },
            { label: PaymentTerms.Term45Days, value: PaymentTermsValue.Term45Days },
            { label: PaymentTerms.Term50Days, value: PaymentTermsValue.Term50Days },
            { label: PaymentTerms.Term55Days, value: PaymentTermsValue.Term55Days },
            { label: PaymentTerms.Term60Days, value: PaymentTermsValue.Term60Days },
            { label: PaymentTerms.Term75Days, value: PaymentTermsValue.Term75Days },
            { label: PaymentTerms.Term90Days, value: PaymentTermsValue.Term90Days },
            { label: PaymentTerms.Term120Days, value: PaymentTermsValue.Term120Days },
            { label: PaymentTerms.Term180Days, value: PaymentTermsValue.Term180Days },
          ],
          editable: true,
          financial: true,
          validators: [ValidatorHelper.required('Enter value for required field.')],
          requestedValue: changeRequestData.paymentTermCd,
          disable: !!changeRequestData?.parentCorpCustomerLocationFuncId,
        },
        {
          name: 'INHERIT D&B FROM PARENT',
          key: 'inheritCdDb',
          required: false,
          type: 'checkbox',
          editable: true,
          disable: !changeRequestData.parentCorpCustomerLocationFuncId,
          financial: true,
        },
        {
          name: 'DUNS',
          key: 'dbCustomerNbr',
          required: false,
          type: 'text',
          editable: true,
          financial: true,
          validators: [ValidatorHelper.pattern('^[0-9]{9}$', 'Duns must be 9 digits.')],
        },
        {
          name: 'NAICS CODE',
          required: false,
          type: 'text',
          editable: true,
          financial: true,
          validators: [ValidatorHelper.pattern('^[0-9]{1,6}$', 'NAICS Code must be up to 6 digits.')],
          key: 'standardIndlCd',
        },
        {
          name: 'TAX ID',
          required: false,
          type: 'text',
          key: 'taxId',
          editable: true,
          financial: true,
          validators: [ValidatorHelper.taxIdValidator()],
        },
        { name: 'Service Recipient', isHeader: true },
        {
          name: 'SERVICE RECIPIENT NBR',
          key: 'serviceRecipientNbr',
          type: 'service-recipient',
          required: false,
          editable: true,
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'TYPE',
          key: 'typeOfSvcInd',
        },
        {
          name: 'NAME',
          key: 'svcRcpntNm',
        },
      ];
      break;
    case CustomerFunctionCd.PICKUP_OR_DELIVERY:
      accountDetails = [
        {
          name: 'Account',
          isHeader: true,
        },
        {
          name: 'LOCATION TYPE',
          editable: false,
          type: 'text',
          requestedValue: CustomerFunctionCdLabel.PickupOrDelivery,
          key: 'functionCd',
        },
        {
          name: 'STATUS',
          key: 'customerStatusCd',
          required: true,
          type: 'text',
          validators: [ValidatorHelper.required('Enter value for required field.')],
          editable: false,
          disable: loggedInUserRole === UserRole.CreditAnalyst,
          requestedValue: 'Pending Approval',
        },
        {
          name: 'ACCOUNT TYPE',
          value:
            changeRequestData?.acctTypeCd || changeRequestData?.parentCorpMadCd
              ? CustomerAccountTypeCd.INHERIT_FROM_PARENT
              : CustomerAccountTypeCd.LOCAL_ACCOUNT,
          key: 'acctTypeCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: AccountType.InheritFromParent, value: CustomerAccountTypeCd.INHERIT_FROM_PARENT },
            { label: AccountType.LocalAccount, value: CustomerAccountTypeCd.LOCAL_ACCOUNT },
            { label: AccountType.NationalAccount, value: CustomerAccountTypeCd.NATIONAL_ACCOUNT },
          ],
          editable: true,
          validators: [ValidatorHelper.required('Enter value for required field.')],
          disable: true,
          requestedValue:
            changeRequestData?.acctTypeCd || changeRequestData?.parentCorpMadCd
              ? CustomerAccountTypeCd.INHERIT_FROM_PARENT
              : CustomerAccountTypeCd.LOCAL_ACCOUNT,
        },
        {
          name: 'PARENT',
          customerNbr: changeRequestData?.parentCorpCustomerLocationFuncId || '',
          key: 'parentCorpCustomerLocationFuncId',
          required: false,
          type: 'parent',
          editable: true,
          value: changeRequestData?.parentCorpCustomerLocationFuncId,
        },
        {
          name: 'MAD CODE',
          key: 'madCd',
          editable: false,
          type: 'text',
          currentValue: '',
        },
        {
          name: 'SALES OWNER',
          value: changeRequestData?.salesTerritoryCd,
          key: 'salesTerritoryCd',
          type: 'text',
          editable: true,
          required: false,
          validators: [ValidatorHelper.maxLength(5, 'Must contain less than 6 characters.')],
        },
        { name: 'Location', isHeader: true },
        {
          name: 'NAME',
          key: 'name1',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.minLength(2, 'Must contain at least 2 letters.'),
            ValidatorHelper.pattern('^[\\w %#&,@\\.\\-\\\']*$', 'Name has invalid characters.'),
            ValidatorHelper.beginWithThe(),
            ValidatorHelper.spacesFormatValidator(),
          ],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'NAME2',
          key: 'name2',
          required: false,
          type: 'text',
          validators: [
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.pattern('^[\\w %#&,@:\\.\\-\\\']*$', 'Name2 has invalid characters.'),
          ],
          editable: true,
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'ADDRESS',
          key: 'address',
          required: true,
          type: 'text',
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(30, 'Must contain less than 30 characters.'),
            ValidatorHelper.pattern('^[\\w áéíóúÁÉÍÓÚ%#&,@:/\\.\\-\\\']*$', 'Address has invalid characters.'),
            ValidatorHelper.isPoBox(),
            ValidatorHelper.spacesFormatValidator(),
          ],
          editable: true,
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'CITY',
          key: 'cityName',
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(20, 'Must contain less than 20 characters.'),
            ValidatorHelper.pattern('^[a-zA-Z&\\-/ ]*$', 'City has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
          ],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'STATE',
          country: changeRequestData?.countryCd,
          key: 'stateCd',
          required: true,
          type: 'state',
          editable: true,
          validators: [ValidatorHelper.required('State is required.')],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'POSTAL CODE',
          key: 'zipCd',
          country: changeRequestData?.countryCd,
          required: true,
          type: 'text',
          editable: true,
          validators: [
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.pattern('^[a-zA-Z0-9\\-/ ]*$', 'Postal Code has invalid characters.'),
            ValidatorHelper.spacesFormatValidator(),
            ValidatorHelper.zipCdValidator,
          ],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'ZIP 4',
          key: 'zip4Cd',
          type: 'text',
          editable: true,
          disable: loggedInUserRole === UserRole.CreditAnalyst,
          validators: [ValidatorHelper.zip4CdValidator],
        },
        {
          name: 'COUNTRY',
          key: 'countryCd',
          required: true,
          type: 'select',
          options: [
            { label: Country.unitedStates, value: CountryValue.unitedStates },
            { label: Country.canada, value: CountryValue.canada },
            { label: Country.mexico, value: CountryValue.mexico },
          ],
          editable: true,
          validators: [ValidatorHelper.required('Country is required.')],
          disable: loggedInUserRole === UserRole.CreditAnalyst,
        },
        {
          name: 'Financial',
          isHeader: true,
        },
        {
          name: 'INHERIT CREDIT FROM PARENT',
          key: 'inheritCd',
          required: true,
          type: 'checkbox',
          editable: true,
          disable: !!changeRequestData?.parentCorpCustomerLocationFuncId,
          requestedValue: !!changeRequestData?.parentCorpCustomerLocationFuncId,
          financial: true,
        },
        {
          name: 'BANKRUPT',
          key: 'bankruptInd',
          required: true,
          type: 'checkbox',
          financial: true,
          editable: true,
          disable: !!changeRequestData?.parentCorpCustomerLocationFuncId,
          requestedValue: changeRequestData?.bankruptInd,
        },
        {
          name: 'CREDIT STATUS',
          value:
            changeRequestData?.creditStatusCd ||
            (changeRequestData?.parentCorpCustomerLocationFuncId ? undefined : CreditStatusValue.NoCredit),
          key: 'creditStatusCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: CreditStatus.Credit, value: CreditStatusValue.Credit },
            { label: CreditStatus.NoCredit, value: CreditStatusValue.NoCredit },
            { label: CreditStatus.Voluntary, value: CreditStatusValue.Voluntary },
            { label: CreditStatus.Cash, value: CreditStatusValue.Cash },
          ],
          editable: true,
          financial: true,
          disable: !!changeRequestData?.parentCorpCustomerLocationFuncId,
        },
        {
          name: 'CREDIT LIMIT',
          key: 'authorizationLimitAmount',
          required: true,
          type: 'text',
          editable: true,
          financial: true,
          validators: [
            ValidatorHelper.spacesFormatValidator(),
            ValidatorHelper.required('Enter value for required field.'),
            ValidatorHelper.maxLength(11, 'Must contain less than 11 characters.'),
            ValidatorHelper.pattern('^[0-9]*$', 'Credit Limit has invalid characters.'),
            ValidatorHelper.creditLimitValidator(changeRequestData?.creditStatusCd),
          ],
          disable: !!changeRequestData?.parentCorpCustomerLocationFuncId,
          requestedValue:
            changeRequestData?.authorizationLimitAmount ||
            (changeRequestData?.parentCorpCustomerLocationFuncId ? undefined : 0),
        },
        {
          name: 'HRC ID',
          key: 'authorizationNbr',
          required: CreditStatus[changeRequestData?.creditStatusCd] === CreditStatusValue.Credit,
          type: 'text',
          editable: true,
          financial: true,
          validators: [ValidatorHelper.maxLength(11, 'Must contain less than 11 characters.')],
        },
        {
          name: 'PAYMENT TERMS',
          value:
            changeRequestData?.paymentTermCd ||
            (changeRequestData?.parentCorpCustomerLocationFuncId
              ? ''
              : changeRequestData.creditStatusCd === CustomerCreditStatusCd.CREDIT
                ? PaymentTermsValue.Term30Days
                : PaymentTermsValue.Immediate),
          key: 'paymentTermCd',
          required: true,
          type: 'financial-select',
          options: [
            { label: PaymentTerms.Delinquent, value: PaymentTermsValue.Delinquent },
            { label: PaymentTerms.Immediate, value: PaymentTermsValue.Immediate },
            { label: PaymentTerms.Term15Days, value: PaymentTermsValue.Term15Days },
            { label: PaymentTerms.Term20Days, value: PaymentTermsValue.Term20Days },
            { label: PaymentTerms.Term30Days, value: PaymentTermsValue.Term30Days },
            { label: PaymentTerms.Term35Days, value: PaymentTermsValue.Term35Days },
            { label: PaymentTerms.Term40Days, value: PaymentTermsValue.Term40Days },
            { label: PaymentTerms.Term45Days, value: PaymentTermsValue.Term45Days },
            { label: PaymentTerms.Term50Days, value: PaymentTermsValue.Term50Days },
            { label: PaymentTerms.Term55Days, value: PaymentTermsValue.Term55Days },
            { label: PaymentTerms.Term60Days, value: PaymentTermsValue.Term60Days },
            { label: PaymentTerms.Term75Days, value: PaymentTermsValue.Term75Days },
            { label: PaymentTerms.Term90Days, value: PaymentTermsValue.Term90Days },
            { label: PaymentTerms.Term120Days, value: PaymentTermsValue.Term120Days },
            { label: PaymentTerms.Term180Days, value: PaymentTermsValue.Term180Days },
          ],
          editable: true,
          financial: true,
          validators: [ValidatorHelper.required('Enter value for required field.')],
          requestedValue: changeRequestData.paymentTermCd,
          disable: !!changeRequestData?.parentCorpCustomerLocationFuncId,
        },
        {
          name: 'INHERIT D&B FROM PARENT',
          key: 'inheritCdDb',
          required: false,
          type: 'checkbox',
          editable: true,
          disable: !changeRequestData.parentCorpCustomerLocationFuncId,
          financial: true,
        },
        {
          name: 'DUNS',
          key: 'dbCustomerNbr',
          required: false,
          type: 'text',
          editable: true,
          financial: true,
          validators: [ValidatorHelper.pattern('^[0-9]{9}$', 'Duns must be 9 digits.')],
        },
        {
          name: 'NAICS CODE',
          required: false,
          type: 'text',
          editable: true,
          financial: true,
          validators: [ValidatorHelper.pattern('^[0-9]{1,6}$', 'NAICS Code must be up to 6 digits.')],
          key: 'standardIndlCd',
        },
        {
          name: 'TAX ID',
          required: false,
          type: 'text',
          key: 'taxId',
          editable: true,
          financial: true,
          validators: [ValidatorHelper.taxIdValidator()],
        },
      ];
      break;
  }
  return accountDetails;
}
