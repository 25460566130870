import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl, Validators } from '@angular/forms';
import { MatLegacyFormField as MatFormField, MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { ValidatorHelper } from 'src/app/shared/validators';
import { CustomerApiService, GetCustomerResp } from '@xpo-ltl-2.0/sdk-customer';
import { CustomerDetailCd, CustomerFunctionCd, CustomerIdTypeCd } from '@xpo-ltl/sdk-common';
import { of, Subject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-madcode-search',
  templateUrl: './madcode-search.component.html',
  styleUrls: ['./madcode-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: MadcodeSearchComponent,
    },
  ],
})
export class MadcodeSearchComponent implements OnInit, MatFormFieldControl<number>, ControlValueAccessor {
  @Input() element: any;
  @Input() isNewLocation: any;
  @Input() isOutside: boolean;
  @Input() isParentMadCd: boolean;
  @Input() madCode: string;
  @Input()
  get prepaidCopyMadCd(): string {
    return this._prepaidCopyMadCd;
  }
  set prepaidCopyMadCd(val) {
    this._prepaidCopyMadCd = val;
    if (val !== '' && this.element.key.includes('Prepaid')) {
      this.searchValueControl?.setValue(val);
    }
    this.stateChanges.next();
  }
  @Input()
  get collectCopyMadCd(): string {
    return this._collectCopyMadCd;
  }
  set collectCopyMadCd(val) {
    this._collectCopyMadCd = val;
    if (val !== '' && this.element.key.includes('Collect')) {
      this.searchValueControl?.setValue(val);
    }
    this.stateChanges.next();
  }
  @Output() madCdSelected = new EventEmitter<any>();
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req) {
    this._required = !!req;
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = !!value;
    this.stateChanges.next();
  }

  get empty(): boolean {
    return !!this._value;
  }

  get errorState(): boolean {
    return this.ngControl?.control?.invalid;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  results: Array<GetCustomerResp> = [];
  searchingForCustomer = false;
  loading = false;
  noResults = false;
  set value(number) {
    this._value = number;
    this.stateChanges.next();
  }

  get value(): number {
    return this._value;
  }

  stateChanges = new Subject<void>();
  id: string;
  placeholder: string;
  focused: boolean;
  controlType?: string = 'mat-input';
  autofilled?: boolean;
  userAriaDescribedBy?: string;
  searchValueControl: UntypedFormControl;
  isWindows: boolean = false;

  static nextId = 0;

  private onChangeFn;
  private onTouchedFn;
  private _value;
  private customerData;
  private originalValue;
  private _required;
  private _disabled;
  private _prepaidCopyMadCd;
  private _collectCopyMadCd;

  constructor(
    private customerApi: CustomerApiService,
    private fm: FocusMonitor,
    private elRef: ElementRef,
    @Optional() public parentFormField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    if (navigator?.userAgent?.indexOf("Win")!== -1) {
      this.isWindows = true;
    }
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this.elRef.nativeElement.querySelector('.quick-search-form');
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.searchValueControl?.disable();
      this.searchValueControl?.setValue('');
    } else {
      this.searchValueControl?.enable();
      this.searchValueControl?.setValue(this.originalValue);
    }
  }

  ngOnInit(): void {
    const defaultValue =
      this.element?.request?.madCd ||
      this.element?.prevBillTo?.madCd ||
      this.ngControl?.value?.madCd ||
      this.element?.prevBillTo?.name1 ||
      this.element?.current?.madCd ||
      '';

    if (this.element?.request?.sendToCustomerLocationFuncId && defaultValue === '') {
      this.getMadCode(this.element?.request?.sendToCustomerLocationFuncId);
    } else {
      this.originalValue = this.originalValue;
    }

    if (this.isParentMadCd) {
      this.initializeForm(defaultValue);
    } else {
      this.initializeForm(defaultValue === this.madCode && !this.isNewLocation ||
        (!this.isOutside && !this.element?.request?.sendToCustomerLocationFuncId && !this.element?.prevBillTo) ? '' : defaultValue);
    }

    this.fm.monitor(this.elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  initializeForm(defaultValue): void {
    this.searchValueControl = new UntypedFormControl(
      {
        value: defaultValue,
        disabled: this.disabled,
      },
      this.element?.changes || this.isOutside
        ? [
          Validators.required,
          ValidatorHelper.pattern('[A-Za-z0-9 ]*', 'No special characters allowed.'),
          this.madCdValidator,
        ]
        : [ValidatorHelper.pattern('[A-Za-z0-9 ]*', 'No special characters allowed.')]
    );
    this.searchValueControl.markAsDirty();
    this.searchValueControl.markAsTouched();
    this.searchValueControl.valueChanges.subscribe((value) => {
      value = value?.trim();
      this.searchingForCustomer = value && value.length > 2 && /^[A-Za-z ]{2,}/g.test(value);

      if (this.searchValueControl.valid) {
        switch (true) {
          case this.searchingForCustomer && value.length === 11:
            this.loading = true;
            this.noResults = false;
            this.customerApi
              .getCustomer(
                { id: value.toUpperCase() },
                {
                  customerIdTypeCd: CustomerIdTypeCd.MAD_CODE,
                  customerDetailCd: [CustomerDetailCd.ACCOUNT],
                  inheritContacts: false,
                },
                { loadingOverlayEnabled: false }
              )
              .subscribe(
                (res) => {
                  this.results = [res];
                  this.customerData = res;
                  this.customerData.customerLocation.customerLocationFunction = this.customerData.customerLocation.customerLocationFunction.filter(
                    (loc) => {
                      return loc.madCd === value.toUpperCase();
                    }
                  );
                },
                (_) => {
                  this.loading = false;
                  this.noResults = true;
                },
                () => {
                  this.loading = false;
                }
              );
            break;
          default:
            this.noResults = true;
            if (this.loading) {
              this.loading = false;
              this.results = [];
            }
            break;
        }
      }
    });
  }

  getMadCode(customerLocFunctionId: string): void {
    const customerParams = { id: customerLocFunctionId };
    this.customerApi
      .getCustomer(customerParams, {
        customerIdTypeCd: CustomerIdTypeCd.CUSTOMER_LOCATION_FUNCTION_ID,
        customerDetailCd: [CustomerDetailCd.ACCOUNT],
        inheritContacts: false,
      })
      .pipe(take(1))
      .subscribe((customer) => {
        const billTo = customer?.customerLocation?.customerLocationFunction.find((customerLocation) => {
          return customerLocation.functionCd === CustomerFunctionCd.BILL_TO;
        });
        this.originalValue = billTo?.madCd ?? '';
        this.initializeForm(this.originalValue);
      });
  }

  madCdValidator(control): any {
    return control?.value?.length === 11 ? null : { invalidMadCd: 'MAD Code must be 11 characters.' };
  }

  selectMadCd(): void {
    this.madCdSelected.emit({ customerRequestData: this.customerData, element: this.element });
  }

  checkBlankValue(event): void {
    if (!event.target.value) {
      this.madCdSelected.emit({ customerRequestData: null, element: this.element });
    }
  }

  displayMadCode = (): string => {
    return this.searchValueControl?.value;
  };
}
