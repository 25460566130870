import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BehaviorSubject } from 'rxjs';
import { EmployeesService } from 'src/app/change-request-page/services/employees.service';

@Component({
  selector: 'app-requester-name-cell-renderer',
  templateUrl: './requester-name-cell-renderer.component.html',
  styleUrls: ['./requester-name-cell-renderer.component.scss'],
})
export class RequesterNameRendererComponent implements ICellRendererAngularComp {
  params: any;
  employeeName: BehaviorSubject<string> = new BehaviorSubject('Loading...');

  constructor(private employeeService: EmployeesService) {}

  refresh(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params.data;
  }

  getEmployeeName(event): void {
    const isWebTeam = this.params?.submittedBy === 'WEB_TEAM';
    this.employeeService.getEmployee(this.params.submittedBy || this.params, isWebTeam).subscribe((name) => {
      this.employeeName.next(name);
    });
    event.stopPropagation();
  }
}
