<button mat-icon-button [xpoLtlpopoverTriggerFor]="commentsPopover">
  <xpo-icon xpoIconLarge iconName="comment"></xpo-icon>
</button>
<xpo-ltl-popover #commentsPopover [position]="position">
  <xpo-ltl-popover-content>
    <ng-container *ngIf="requestNotes$ | async as requestNotes">
      <mat-card appearance="outlined" *ngFor="let requestNote of requestNotes">
        <mat-card-header>
          <div mat-card-avatar><xpo-icon iconName="account"></xpo-icon></div>
          <mat-card-title>{{ requestNote.author }}</mat-card-title>
          <mat-card-subtitle>{{ requestNote.auditInfo.createdTimestamp | date: 'MMM d, y, HH:mm' }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <p>
            {{ requestNote.note }}
          </p>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </xpo-ltl-popover-content>
</xpo-ltl-popover>
