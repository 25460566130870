<div class="customer-search-page-content-container">
  <div class="search-results-container">
    <xpo-card class="ncis-Customer-card ncis-Card-marginTop" *ngIf="dataSourceValue">
      <xpo-card-header class="cdk-cursor" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <xpo-card-title>{{ titleText }}</xpo-card-title>
      </xpo-card-header>
      <xpo-card-content class="ncis-customer-match-board-container">
        <xpo-board
          [dataSource]="dataSource"
          [viewDataStore]="viewDataStore"
          [viewTemplates]="viewTemplates"
          [boardOptions]="boardOptions"
        >
          <xpo-ag-grid-board
            rowModelType="client-side"
            rowSelection="false"
            [enableSorting]="true"
            sizeColumnsToFit="true"
            [customGridOptions]="customGridOptions"
            (gridBoardReady)="gridBoardReady($event)"
          >
          </xpo-ag-grid-board>
<!--          <xpo-board-pagination #myPagination></xpo-board-pagination>-->
        </xpo-board>
      </xpo-card-content>
    </xpo-card>
  </div>
  <div class="ncis-customer-match-form-actions">
    <button id="duplicate-modal-continue-btn" mat-flat-button (click)="onContinue()" type="submit">CONTINUE</button>
    <button id="duplicate-modal-cancel-btn" mat-stroked-button (click)="onCancel()" type="button">CANCEL</button>
  </div>
</div>
