<div style="padding: 0 16px;" mat-dialog-content>
  <div class="complete-request-header cdk-cursor" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <p><mat-icon>report_problem</mat-icon>
      {{ title }}</p>
  </div>
  <form class="complete-request-form" [formGroup]="form">
    <mat-form-field floatLabel="always">
      <mat-label>CC:</mat-label>
      <input formControlName="emailCopyList" matInput />
      <mat-error *ngIf="form?.controls?.emailCopyList?.errors?.pattern">
        <mat-icon>report_problem</mat-icon>Must be an XPO email address.
      </mat-error>
      <mat-error *ngIf="form?.controls?.emailCopyList?.errors?.invalidEmail">
        <mat-icon>report_problem</mat-icon> Some email addresses have invalid characters.
      </mat-error>
      <mat-error *ngIf="form?.controls?.emailCopyList?.errors?.maxLength">
        <mat-icon>report_problem</mat-icon> Some email addresses exceed maximum length of 70 characters.
      </mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <mat-label>Comments</mat-label>
      <textarea formControlName="comments" matInput></textarea>
    </mat-form-field>
  </form>
</div>
<div class="complete-request-actions" mat-dialog-actions>
  <button *ngIf="!isCancelRequest && !closeRequest" id="complete-request-stay" mat-flat-button [disabled]="form.invalid" type="button"
          (debounceClick)="stayOnRequest()">
    STAY ON THIS REQUEST
  </button>
  <button id="complete-request-next-request" mat-stroked-button [disabled]="form.invalid"
          (debounceClick)="nextRequest()" type="button">
    NEXT REQUEST
  </button>
  <button id="complete-request-go-to-dashboard" [disabled]="form.invalid" mat-stroked-button (click)="goToDashboard()"
          type="button">
    GO TO DASHBOARD
  </button>
</div>
