<div
  (debounceClick)="getChangeRequest(queueData.queueName)"
  class="host-Dashboard-button"
  [ngClass]="{
    'host-Dashboard-button--break': isTotalTile,
    'host-Dashboard-button--border': !isTotalTile && total !== 0,
    'host-Dashboard-button--total': isTotalTile,
    'host-Dashboard-button--disabled': total === 0
  }"
>
  <div class="host-Dashboard-button-content">
    <div class="host-Dashboard-button-content-inner">
      <div class="host-Dashboard-button-content-inner-block total-container">
        <span class="host-Dashboard-button-number">{{ total }}</span>
        <button *ngIf="!isTotalTile" [disabled]="total === 0" class="host-Dashboard-button-content-inner-block-btn">
          <mat-icon class="host-Dashboard-button-content-inner-block-btn-navigate">
            east
          </mat-icon>
        </button>
      </div>

      <div class="host-Dashboard-button-content-inner-block">
        <mat-icon class="host-Dashboard-button-content-inner-block-circleIcon">
          circle
        </mat-icon>
        <span
          *ngIf="queueData.queueName"
          class="host-Dashboard-button-label"
          [style.fontWeight]="isBold ? 'bold' : 'normal'"
        >
          {{ queueData.queueName }}
        </span>
      </div>
      <div *ngIf="!isTotalTile" class="host-Dashboard-button-content-inner-block">
        <div class="details-container">
          <div>
            <div>
              <span class="count within-number">{{ queueData.withinSLARequests }}</span>
            </div>
            <div>
              <span>Within SLA</span>
            </div>
          </div>
          <div class="item-separator"></div>
          <div>
            <div>
              <span class="count outside-number">{{ queueData.outsideSLARequests }}</span>
            </div>
            <div>
              <span>Outside SLA</span>
            </div>
          </div>
          <div class="item-separator"></div>
          <div>
            <div>
              <span class="count pending">{{ queueData.totalPendingRequests }}</span>
            </div>
            <div>
              <span>Pending</span>
            </div>
          </div>
        </div>
      </div>
      <span
        *ngIf="queueData.queueName == null"
        class="host-Dashboard-button-label exDev-Utils-skeleton-animated"
        style="width: 50px"
      ></span>
    </div>
  </div>
</div>
