import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ag-grid';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-board/ag-grid';
import {
  XpoBoardModule,
  XpoBoardViewsModule,
  XpoDashbarModule,
  XpoDataDrawerModule,
  XpoFiltersModule,
} from '@xpo-ltl/ngx-board/core';
import { XpoHighchartsBoardModule } from '@xpo-ltl/ngx-board/highcharts';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoEmptyStateModule } from '@xpo-ltl/ngx-ltl-core/empty-state';
import { XpoStatusIndicatorModule } from '@xpo-ltl/ngx-ltl-core/status-indicator';
import { LabelChartMetricsComponent } from './components/label-chart-metrics/label-chart-metrics.component';

/* Components */
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';
import { EditIconComponent } from './components/edit-icon/edit-icon.component';
import { ViewDetailsComponent } from './components/view-details-icon/view-details-icon.component';

/* Modules */
import { DialogModule } from '../shared/components/dialog/dialog.module';

/* Guards */
import { CheckUnsavedChangesGuard } from '../shared/guards/check-unsaved-changes/check-unsaved-changes.guard';

/* Directives */
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoLtlPopoverModule } from '@xpo-ltl/ngx-ltl-core/xpo-ltl-popover';
import { XpoHighchartsChartModule } from '@xpo/ngx-core-highcharts';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { EmployeeNamePipe } from 'src/app/change-request-page/pipes/employee-name.pipe';
import { LinkCellRendererComponent } from 'src/app/location-details-page/company-hierarchy/components/link-cell-renderer/link-cell-renderer.component';
import { CompleteRequestDialogComponent } from 'src/app/shared/components/complete-request-dialog/complete-request-dialog.component';
import { EmployeeNameJobDescriptionPipe } from '../change-request-page/pipes/employee-name-job-description.pipe';
import { AutocompleteOffDirective, NcisXpoFocusOnSubmitDirective, XpoChipMediumDirective } from '../shared/directives';
import { EditCellRendererComponent } from './components/cell-renderers/edit-cell-renderer/edit-cell-renderer.component';
import { PhoneNumberEditorComponent } from './components/cell-renderers/phone-number-editor/phone-number-editor.component';
import { SelectCellRendererComponent } from './components/cell-renderers/select-cell-renderer/select-cell-renderer.component';
import { ConfirmAddressDialogComponent } from './components/confirm-address-dialog/confirm-address-dialog.component';
import { ConfirmBulkDeleteDialogComponent } from './components/confirm-bulk-delete-dialog/confirm-bulk-delete-dialog.component';
import { CustomPaginationComponent } from './components/custom-pagination/custom-pagination.component';
import { AliasDialogComponent } from './components/customer-business-name/alias-dialog/alias-dialog.component';
import { DetailBusinessNameComponent } from './components/customer-business-name/cell-renderers/detail-business-name/detail-business-name.component';
import { CustomerBusinessNameComponent } from './components/customer-business-name/customer-business-name.component';
import { CustomerContactsComponent } from './components/customer-contacts/customer-contacts.component';
import { DetailContactComponent } from './components/customer-contacts/detail-contact/detail-contact.component';
import { NewContactDialogComponent } from './components/customer-contacts/new-contact-dialog/new-contact-dialog.component';
import { DetailCustomerHistoryComponent } from './components/customer-history/detail-customer-history/detail-customer-history.component';
import { CustomerInvoiceInstructionsComponent } from './components/customer-invoice-instructions/customer-invoice-instructions.component';
import { CustomerRequestCommentsComponent } from './components/customer-request-comments/customer-request-comments.component';
import { CustomerTabsComponent } from './components/customer-tabs/customer-tabs.component';
import { DashboardQueueTileComponent } from './components/dashboard-queue-tile/dashboard-queue-tile.component';
import { DateRangeFilterComponent } from './components/date-range-filter/date-range-filter.component';
import { ExportDialogComponent } from './components/export-dialog/export-dialog.component';
import { InstructionHistoryComponent } from './components/instruction-history/instruction-history.component';
import { LineStatusCellRendererComponent } from './components/line-status-cell-renderer/line-status-cell-renderer.component';
import { MadcodeSearchComponent } from './components/madcode-search/madcode-search.component';
import { MatchCustomerTableComponent } from './components/match-customer-table/match-customer-table.component';
import { NcisBadgeComponent } from './components/ncis-badge/ncis-badge.component';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
import { PricingDialogComponent } from './components/pricing-dialog/pricing-dialog.component';
import { QuickSearchComponent } from './components/quick-search/quick-search.component';
import { RelatedLocationsComponent } from './components/related-locations/related-locations.component';
import { RequesterNameRendererComponent } from './components/requester-name-cell-renderer/requester-name-cell-renderer.component';
import { SelectFilterComponent } from './components/select-filter/select-filter.component';
import { ServiceRecipientHistoryComponent } from './components/service-recipient-history/service-recipient-history.component';
import { ServiceRecipientInputComponent } from './components/service-recipient-input/service-recipient-input.component';
import { ServiceRecipientResultDialogComponent } from './components/service-recipient-result-dialog/service-recipient-result-dialog.component';
import { TextFilterComponent } from './components/text-filter/text-filter.component';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { InputTrimDirective } from './directives/input-trim.directive';
import { NotPipe } from './pipes/not.pipe';
import { RequestLineStatusColorPipe } from './pipes/request-line-status-color.pipe';
import { RequestLineStatusPipe } from './pipes/request-line-status.pipe';
import { StateSortPipe } from './pipes/state-sort.pipe';
import { UserAccessPipe } from './pipes/user-acces.pipe';

@NgModule({
  declarations: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    CustomPaginationComponent,
    EditIconComponent,
    ViewDetailsComponent,
    LabelChartMetricsComponent,
    DashboardQueueTileComponent,
    // Pipes/Directives
    XpoChipMediumDirective,
    NcisXpoFocusOnSubmitDirective,
    AutocompleteOffDirective,
    QuickSearchComponent,
    MadcodeSearchComponent,
    LineStatusCellRendererComponent,
    RequesterNameRendererComponent,
    LinkCellRendererComponent,
    RelatedLocationsComponent,
    CompleteRequestDialogComponent,
    EmployeeNamePipe,
    EmployeeNameJobDescriptionPipe,
    SelectFilterComponent,
    DateRangeFilterComponent,
    TextFilterComponent,
    CustomerTabsComponent,
    CustomerInvoiceInstructionsComponent,
    CustomerBusinessNameComponent,
    CustomerContactsComponent,
    DetailBusinessNameComponent,
    DetailContactComponent,
    NewContactDialogComponent,
    DetailCustomerHistoryComponent,
    EditCellRendererComponent,
    SelectCellRendererComponent,
    PhoneNumberInputComponent,
    PhoneNumberEditorComponent,
    ServiceRecipientInputComponent,
    AliasDialogComponent,
    PricingDialogComponent,
    NcisBadgeComponent,
    MatchCustomerTableComponent,
    UserAccessPipe,
    NotPipe,
    RequestLineStatusPipe,
    RequestLineStatusColorPipe,
    StateSortPipe,
    ConfirmAddressDialogComponent,
    ExportDialogComponent,
    InstructionHistoryComponent,
    ServiceRecipientHistoryComponent,
    InputTrimDirective,
    DebounceClickDirective,
    ServiceRecipientResultDialogComponent,
    ConfirmBulkDeleteDialogComponent,
    CustomerRequestCommentsComponent,
  ],
  imports: [
    CommonModule,
    XpoCardModule,
    DialogModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatSelectModule,
    XpoStatusIndicatorModule,
    MatCheckboxModule,
    MatBadgeModule,
    XpoAgGridBoardModule,
    XpoAgGridModule,
    XpoEmptyStateModule,
    AgGridModule.withComponents([DetailBusinessNameComponent]),
    XpoBoardViewsModule,
    XpoFiltersModule,
    XpoButtonModule,
    XpoBoardModule,
    XpoBoardViewsModule,
    XpoDataDrawerModule,
    XpoDashbarModule,
    MatTableModule,
    MatDatepickerModule,
    DragDropModule,
    XpoHighchartsBoardModule,
    XpoHighchartsChartModule,
    MatExpansionModule,
    XpoLtlPopoverModule,
    XpoIconModule,
    MatLegacyCardModule,
  ],
  exports: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    CustomPaginationComponent,
    EditIconComponent,
    ViewDetailsComponent,
    LabelChartMetricsComponent,
    DashboardQueueTileComponent,
    // Pipes/Directives
    XpoChipMediumDirective,
    NcisXpoFocusOnSubmitDirective,
    AutocompleteOffDirective,
    DebounceClickDirective,
    QuickSearchComponent,
    MadcodeSearchComponent,
    RelatedLocationsComponent,
    EmployeeNamePipe,
    EmployeeNameJobDescriptionPipe,
    LinkCellRendererComponent,
    RequesterNameRendererComponent,
    CustomerTabsComponent,
    CustomerInvoiceInstructionsComponent,
    CustomerBusinessNameComponent,
    CustomerContactsComponent,
    NewContactDialogComponent,
    PhoneNumberInputComponent,
    ServiceRecipientInputComponent,
    NcisBadgeComponent,
    UserAccessPipe,
    NotPipe,
    RequestLineStatusPipe,
    RequestLineStatusColorPipe,
    InstructionHistoryComponent,
    ServiceRecipientHistoryComponent,
    StateSortPipe,
  ],
  providers: [CheckUnsavedChangesGuard],
})
export class SharedModule {}
