import { DetailsActionsTypes } from './details.actions';

export interface DetailsState {
  currentCountrySelected: string;
  isEditMode: boolean;
  isProcessingBulkDelete: boolean;
  changesRequestCount: number;
  massIndIcon: boolean;
}

export const initialState: DetailsState = {
  currentCountrySelected: '',
  isEditMode: false,
  isProcessingBulkDelete: false,
  changesRequestCount: 0,
  massIndIcon: false,
};

export const detailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DetailsActionsTypes.SetCurrentCountry:
      return Object.assign({}, state, action.payload);
    case DetailsActionsTypes.GetCurrentCountry:
      return { ...state };
    case DetailsActionsTypes.SetProcessingBulkDelete:
      return Object.assign({}, state, action.payload);
    case DetailsActionsTypes.GetProcessingBulkDelete:
      return { ...state };
    case DetailsActionsTypes.SetEditMode:
      return Object.assign({}, state, action.payload);
    case DetailsActionsTypes.GetEditMode:
      return { ...state };
    case DetailsActionsTypes.SetMassIndIcon:
      return Object.assign({}, state, action.payload);
    case DetailsActionsTypes.GetMassIndIcon:
      return { ...state };
    case DetailsActionsTypes.SetChangesRequestCount:
      return Object.assign({}, state, action.payload);
    case DetailsActionsTypes.UpdateChangesRequestCount:
      return Object.assign({}, state, action.payload);
    case DetailsActionsTypes.GetChangesRequestCount:
      return { ...state };
    default:
      return state;
  }
};
