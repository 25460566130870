import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { User } from '@xpo-ltl/sdk-common';

import { MatDialog } from '@angular/material/dialog';
import { CustomerApiService } from '@xpo-ltl-2.0/sdk-customer';
import { NotAuthorizedComponent } from '../../../dialogs/not-authorized/not-authorized.component';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { UserRoleService } from '../../services/user-role/user-role.service';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
  constructor(
    private config: ConfigManagerService,
    private dialog: MatDialog,
    private userRoleService: UserRoleService,
    private customerApiService: CustomerApiService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.customerApiService.loggedInUser(this.config.getSetting(ConfigManagerProperties.loggedInUserRoot)).pipe(
      map((user: User) => {
        if (this.userRoleService.isAuthorizedUser(user)) {
          this.userRoleService.setRole();
          return true;
        }
        this.userRoleService.user = undefined;
        this.showNotAuthorizedDialog();
        return false;
      }),
      catchError((err) => {
        this.userRoleService.user = undefined;
        return throwError(err);
      })
    );
  }

  private showNotAuthorizedDialog(): void {
    this.dialog.open(NotAuthorizedComponent, { disableClose: true });
  }
}
