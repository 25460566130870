import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerAddress, FinalStandardAddress } from '@xpo-ltl-2.0/sdk-customer';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { CustomerAddressValidationCd } from '@xpo-ltl/sdk-common';
import { ConstantsService } from 'src/app/shared/services/constants/constants.service';

@Component({
  selector: 'app-confirm-address-dialog',
  templateUrl: './confirm-address-dialog.component.html',
  styleUrls: ['./confirm-address-dialog.component.scss'],
})
export class ConfirmAddressDialogComponent implements OnInit {
  asEnteredAddress: CustomerAddress;
  validatedAddress: FinalStandardAddress;
  partyName: string;
  result: CustomerAddressValidationCd;
  validatedDisabled: boolean = false;
  latitudeNbr: number;
  longitudeNbr: number;
  showEnteredAddress: boolean = true;

  constructor(
    private dialogRef: MatDialogRef<ConfirmAddressDialogComponent>,
    private snackbar: XpoSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.asEnteredAddress = this.data.asEnteredAddress;
    this.result = this.data.result;
    if (this.result === CustomerAddressValidationCd.INVALID) {
      this.validatedDisabled = true;

      this.snackbar.open({
        message: 'Address validation failed. Please continue with as entered or correct the address and try again.',
        status: 'error',
        matConfig: {
          duration: ConstantsService.snackbarDuration,
        },
      });
    } else {
      this.latitudeNbr = this.data.validatedAddress.geoCoordinates.latitude;
      this.longitudeNbr = this.data.validatedAddress.geoCoordinates.longitude;
      this.validatedAddress = this.data.validatedAddress;
      this.validatedAddress.postalCd = this.validatedAddress?.postalCd?.replace(/\s+/g, '');
      this.partyName = this.data.partyName;

      if (this.validatedAddress.addressLine1.length > 30 || this.validatedAddress.cityName.length > 20) {
        this.validatedAddress.addressLine1 = this.asEnteredAddress.address;
        this.validatedAddress.cityName = this.asEnteredAddress.cityName;
        this.validatedAddress.usZip4 = this.asEnteredAddress.zip4Cd;
        this.result = CustomerAddressValidationCd.INVALID;
        this.validatedDisabled = true;
      } else {
        this.validatedDisabled = false;
        this.checkExactMatch();
      }
    }

    if (this.result === CustomerAddressValidationCd.UNAVAILABLE) {
      this.snackbar.open({
        message: 'Address Validation services are not available at this time. Please contact IT Support.',
        status: 'error',
        matConfig: {
          duration: ConstantsService.snackbarDuration,
        },
      });
    }
  }

  checkExactMatch(): void {
    if (
      this.asEnteredAddress.address === this.validatedAddress.addressLine1 &&
      this.asEnteredAddress.cityName === this.validatedAddress.cityName &&
      this.asEnteredAddress.stateCd === this.validatedAddress.stateCd &&
      this.asEnteredAddress.countryCd === this.validatedAddress.countryCd &&
      this.asEnteredAddress.zipCd === this.validatedAddress.postalCd &&
      this.asEnteredAddress?.zip4Cd === this.validatedAddress?.usZip4
    ) {
      this.showEnteredAddress = false;
    }
  }

  useAsEnteredClick(): void {
    this.dialogRef.close({ address: this.asEnteredAddress, latitude: this.latitudeNbr, longitude: this.longitudeNbr });
  }

  useValidatedClick(): void {
    this.dialogRef.close({ address: this.validatedAddress });
  }

  cancelClick(): void {
    this.dialogRef.close({ address: null });
  }
}
