import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomerRequest, RequestCustomerLocationFunction } from '@xpo-ltl-2.0/sdk-customer';
import { CustomerLineStatusCd, CustomerRequestStatusCd } from '@xpo-ltl/sdk-common';
import { AppState } from '..';
import { getLocationId } from '../location/location.selectors';
import { ChangeRequestState } from './change-request.reducer';

export const getChangeRequest = createFeatureSelector<AppState, ChangeRequestState>('changeRequest');

export const getChangeRequestData = createSelector(getChangeRequest, (changeRequestState: ChangeRequestState) => {
  return changeRequestState.changeRequest;
});

export const getChangeRequestChangesOnlyData = createSelector(
  getChangeRequest,
  (changeRequestState: ChangeRequestState) => {
    return changeRequestState.changeRequestChangesOnly;
  }
);

export const locationHasWarnings = createSelector(getChangeRequest, (state: ChangeRequestState, madCd) => {
  if (state?.warnings?.length && madCd) {
    const uniqueLocations = state?.changeRequest?.requestCustomerLocationFunction?.reduce((acc, current) => {
      if (!acc.find((item) => item.madCd === current.madCd)) {
        acc.push(current);
      }
      return acc;
    }, []);

    const locationWithWarnings = uniqueLocations?.filter(
      (location) =>
        location.statusCd === CustomerLineStatusCd.NOT_IN_PROCESS &&
        state?.warnings?.some((warning) => {
          if (warning.fieldName === 'RequestContactCustomerRelationship') {
            return warning.fieldValue.split('-')[0] === location?.requestFuncSequenceNbr?.toString();
          } else {
            return warning.fieldValue === location?.requestFuncSequenceNbr?.toString();
          }
        })
    );

    const currentLocation: RequestCustomerLocationFunction = locationWithWarnings?.filter(
      (loc) => loc.madCd === madCd
    )[0];

    const filteredWarning = state.warnings.filter((warning) => {
      if (warning.fieldName === 'RequestContactCustomerRelationship') {
        return warning.fieldValue?.split('-')[0] === currentLocation?.requestFuncSequenceNbr?.toString();
      } else {
        return warning.fieldValue === currentLocation?.requestFuncSequenceNbr?.toString();
      }
    })[0];

    const isContactError = filteredWarning?.fieldName === 'RequestContactCustomerRelationship';
    const contactSequenceNbr = isContactError ? Number(filteredWarning.fieldValue?.split('-')[1]) : 0;

    if (currentLocation) {
      return {
        disabled: true,
        isContact: isContactError,
        contactPersonSequenceNbr: contactSequenceNbr,
        error: filteredWarning,
      };
    }

    return { disabled: false, error: null };
  } else {
    return { disabled: false, error: null };
  }
});

export const getEmployees = createSelector(getChangeRequest, (state) => {
  return state.employees;
});

export const getLoggedInUser = createSelector(getChangeRequest, (state) => {
  return state.loggedInUser;
});

export const loggedInUserIsOwner = createSelector(getChangeRequest, (state) => {
  return state.loggedInUser && state.changeRequest
    ? state.loggedInUser.employeeId === state.changeRequest.coderEmployeeId
    : false;
});

export const getCoderName = createSelector(getChangeRequest, (state) => {
  const employee = state.employees.find(
    (emp) => emp.interfaceEmployee.employeeId === state.changeRequest.coderEmployeeId
  );
  return employee ? employee.interfaceEmployee.firstName + ' ' + employee.interfaceEmployee.lastName : '';
});

export const getChangeRequestId = createSelector(getChangeRequest, (changeRequestState: ChangeRequestState) => {
  return changeRequestState.changeRequest && changeRequestState.changeRequest.cstRequestId;
});

export const getRequestedChangeCurrentLocation = createSelector(
  getChangeRequest,
  (changeRequestState: ChangeRequestState) => {
    return changeRequestState.requestedChangesCurrentLocation;
  }
);

export const getRequestedChangeCurrentLine = createSelector(
  getChangeRequest,
  (changeRequestState: ChangeRequestState) => {
    return changeRequestState.requestChangesCurrentLine;
  }
);

export const isProcessableChangeRequest = createSelector(getChangeRequest, getLocationId, (changeRequestState) => {
  return changeRequestState.changeRequest
    ? changeRequestState.changeRequest.statusCd === CustomerRequestStatusCd.PROCESS
    : false;
});

export const getEditFormStatus = createSelector(getChangeRequest, (changeRequestState: ChangeRequestState) => {
  return changeRequestState ? changeRequestState.editFormStatus : true;
});

export const isAbleToEditOutSideRequest = createSelector(getChangeRequest, loggedInUserIsOwner, (crData, isOwner) => {
  return isOwner && crData.changeRequest?.statusCd === CustomerRequestStatusCd.PROCESS;
});

export const atLeastOneLineIsHold = createSelector(
  getChangeRequestChangesOnlyData,
  (changeRequest: CustomerRequest, { locationId, seqNbr }) => {
    if (changeRequest) {
      const customerLocationLines = changeRequest?.requestCustomerLocationFunction?.filter((loc) => {
        return loc.customerLocationFuncId
          ? loc.customerLocationFuncId === locationId
          : loc.requestFuncSequenceNbr === seqNbr;
      });

      const contactLines = changeRequest?.requestContactPerson?.filter((contact) => {
        return customerLocationLines.some((line) => {
          return line?.requestContactCustomerRelationship?.some((rel) => {
            return +rel.requestContactPersonSequenceNbr === contact.requestContactPersonSequenceNbr;
          });
        });
      });

      const aliasLines = customerLocationLines?.reduce(
        (acc, loc) => [...acc, ...(loc.requestCustomerFunctionAlias || [])],
        []
      );

      const invoices = customerLocationLines?.reduce(
        (acc, loc) => [...acc, ...(loc.requestInvoicePreference || [])],
        []
      );

      return [...customerLocationLines, ...(contactLines || []), ...aliasLines, ...invoices].some((line) => {
        return line.statusCd === CustomerLineStatusCd.HELD;
      });
    } else {
      return false;
    }
  }
);
