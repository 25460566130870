import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { HumanResourceApiService } from '@xpo-ltl-2.0/sdk-humanresource';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { RequestNote } from './customer-request-comments.interface';

@Component({
  selector: 'host-customer-request-comments',
  templateUrl: './customer-request-comments.component.html',
  styleUrls: ['./customer-request-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'host-CustomerRequestComments',
  },
})
export class CustomerRequestCommentsComponent implements AfterViewInit, OnDestroy {
  @Input() remarks: RequestNote[];
  position: TooltipPosition = 'below';
  private destroy = new Subject<void>();
  set requestNotes(notes: RequestNote[] | undefined) {
    this._requestNotes$.next(notes);
  }
  private _requestNotes$: BehaviorSubject<RequestNote[] | undefined> = new BehaviorSubject<RequestNote[] | undefined>(
    []
  );
  requestNotes$ = this._requestNotes$.pipe(shareReplay(1));

  constructor(private hrApiService: HumanResourceApiService) {}

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  ngAfterViewInit(): void {
    const notes$ = this.remarks.map((note) => {
      return this.hrApiService.getInterfaceEmployee({ employeeId: note.auditInfo.createdById }).pipe(
        take(1),
        map((response) => {
          const employee = (response?.data ?? <any>response).interfaceEmployee;
          return {
            ...note,
            author: employee?.firstName + ' ' + employee?.lastName,
          } as RequestNote;
        })
      );
    });

    forkJoin(notes$)
      .pipe(takeUntil(this.destroy))
      .subscribe((requestNotes) => {
        this.requestNotes = requestNotes.sort(
          (a, b) => Number(b.auditInfo.createdTimestamp) - Number(a.auditInfo.createdTimestamp)
        );
      });
  }
}
