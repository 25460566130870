<mat-accordion>
  <mat-expansion-panel hideToggle="true" expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>Service Recipient History</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="ncis-ServiceRecipientHistory">
      <xpo-ag-grid>
        <ag-grid-angular
          class="instruction-history-grid"
          (gridReady)="onGridReady($event)"
          [overlayNoRowsTemplate]="noRowsTemplate"
          [columnDefs]="columnInstructionDefs"
          [gridOptions]="gridInstructionOptions"
          (firstDataRendered)="onFirstDataRendered($event)"
          [rowData]="rowData"
        >
        </ag-grid-angular>
      </xpo-ag-grid>
    </div>
  </mat-expansion-panel>
</mat-accordion>
