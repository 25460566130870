export enum ActionCode {
  A = 'Add',
  U = 'Update',
  D = 'Delete',
  I = 'Inactive',
  M = 'Merge',
  E = 'Expire',
}

export enum ActionCodeMap {
  A = 'I',
  I = 'I',
  U = 'U',
  D = 'D',
  E = 'U',
  M = 'U',
}

export enum ActionCodeDetails {
  A = 'Added',
  U = 'Update',
  D = 'Deleted',
  I = 'Added',
  M = 'Merged',
  E = 'Expired',
}

export enum HistoryActionCode {
  U = 'Update',
  D = 'Delete',
  I = 'Add',
}
