<div class="ncis-BNARowForm">
  <div class="ncis-BNARowForm-ribbon"></div>
  <div class="ncis-BNARowForm-action" [ngClass]="{ 'outside-request': !isChangeRequest }"></div>
  <div class="ncis-BNARowForm-wrapper">
    <form class="ncis-BNARowForm-form" [formGroup]="bnaForm" *ngIf="bnaForm" autocomplete="none" ncisXpoFocusOnSubmit>
      <ng-container
        *ngIf="
          !isChangeRequest &&
          isCorpLocation &&
          (isMassAliasUpdate || actionType === 'ADD' || (actionType === 'DELETE' && hasMassAlias))
        "
      >
        <mat-checkbox
          [checked]="checkedMassAlias"
          [disabled]="isMassAliasUpdate"
          (change)="changeValueMassAlias($event)"
          >Mass Alias
        </mat-checkbox>
      </ng-container>

      <span class="ncis-BNARowForm-label" *ngIf="isChangeRequest">Remarks</span>
      <div class="ncis-BNARowForm-remarks">
        <ng-container *ngIf="isChangeRequest; else isOutside">
          <mat-form-field class="ncis-CommentsInput" floatLabel="always">
            <textarea
              [readonly]="!loggedInUserIsOwner"
              matInput
              formControlName="comments"
              matTextareaAutosize
              matAutosizeMinRows="6"
              matAutosizeMaxRows="6"
              maxLength="800"
              [readonly]="statusIsApproveOrRejected || !loggedInUserIsOwner"
            >
            </textarea>
            <mat-error *ngIf="bnaForm?.controls?.comments?.errors && bnaForm?.controls?.comments?.errors.noWhiteSpace">
              <mat-icon>report_problem</mat-icon>You must enter a reason in the Comments field
            </mat-error>
            <mat-error *ngIf="bnaForm?.controls?.comments?.errors && bnaForm?.controls?.comments?.errors.required">
              <mat-icon>report_problem</mat-icon>You must enter a reason in the Comments field
            </mat-error>
            <mat-hint align="end">{{ getRemainingCharacters() }} characters</mat-hint>
          </mat-form-field>
        </ng-container>
        <ng-template #isOutside>
          <mat-form-field style="max-width: 400px;">
            <input type="text" formControlName="comments" matInput maxLength="50" />
          </mat-form-field>
        </ng-template>
      </div>
      <div
        *ngIf="isChangeRequest ? loggedInUserIsOwner : true"
        class="ncis-BNARowForm-actions"
        [class.ncis-BNARowForm-actions--spaceBetween]="isChangeRequest"
      >
        <ng-container *ngIf="isChangeRequest; else editActions">
          <div class="ncis-BNARowFormActions-group">
            <button
              id="approve-alias"
              [disabled]="hasWarnings || isCompleted || isRequesterCanceled || statusIsApproved"
              mat-flat-button
              type="submit"
              (debounceClick)="approveRequest(gridActions)"
            >
              APPROVE
            </button>
            <button
              id="reject-alias"
              [disabled]="statusIsApproveOrRejected || isCompleted || isRequesterCanceled"
              mat-stroked-button
              (debounceClick)="rejectRequest()"
            >
              REJECT
            </button>
            <button
              id="hold-alias"
              [disabled]="statusIsApproveOrRejected || statusIsHeld || isCompleted || isRequesterCanceled"
              mat-stroked-button
              (debounceClick)="holdRequest()"
            >
              HOLD
            </button>
          </div>
          <button id="cancel-alias" mat-stroked-button (click)="cancelForm()">CANCEL</button>
        </ng-container>
        <ng-template #editActions>
          <button
            [disabled]="!isAbleToProcess || bnaForm?.invalid || !isAllDataFilled()"
            mat-flat-button
            type="submit"
            (debounceClick)="updateCustomer()"
          >
            {{ actionText }}
          </button>
          <button id="cancel-alias" mat-stroked-button (click)="cancelForm()">CANCEL</button>
        </ng-template>
      </div>
    </form>
  </div>
</div>
