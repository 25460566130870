import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CustomerAddress, CustomerMatch, FinalStandardAddress, ServiceRecipient } from '@xpo-ltl-2.0/sdk-customer';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { ConfirmAddressDialogComponent } from 'src/app/shared/components/confirm-address-dialog/confirm-address-dialog.component';
import { ExportDialogComponent } from 'src/app/shared/components/export-dialog/export-dialog.component';
import { MatchCustomerTableComponent } from 'src/app/shared/components/match-customer-table/match-customer-table.component';
import { ExpireCustomerErrorCode } from 'src/app/shared/enums/ensemble-error-code.enum';

/* XPO */
import { CustomerAddressValidationCd } from '@xpo-ltl/sdk-common';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ConstantsService } from '../constants/constants.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private snackbar: XpoSnackBar, private dialog: MatDialog) {}

  private enumKeys = [
    'functionCd',
    'creditStatusCd',
    'acctTypeCd',
    'paymentTermCd',
    'statusCd',
    'priorityCd',
    'requestQueueName',
    'customerDetailCd',
    'actionCd',
    'listActionCd',
    'typeOfServiceInd',
    'bankruptInd',
    'contactRoleCd',
    'billCd',
    'invoiceCd',
    'expiryReasonCd',
    'approvalStatusCd',
    'noteForCd',
    'noteTypeCd',
  ];

  static handleServiceRecipientChange(
    form: UntypedFormGroup,
    tableDataSrc: any,
    serviceRecipient: ServiceRecipient
  ): void {
    const name2Control = form.get('name2');
    const addressControl = form.get('address');
    const cityControl = form.get('cityName');
    const stateControl = form.get('stateCd');
    const zipControl = form.get('zipCd');
    const zip4Control = form.get('zip4Cd');
    const countryControl = form.get('countryCd');
    const restrict = tableDataSrc.data.find((d) => d.key === 'restrictChargeInd');
    const svcType = tableDataSrc.data.find((d) => d.key === 'typeOfSvcInd');
    const svcRcpntName = tableDataSrc.data.find((d) => d.key === 'svcRcpntNm');
    if (serviceRecipient) {
      name2Control.setValue('% ' + serviceRecipient.serviceRecipientName);
      name2Control.disable();
      addressControl.setValue(serviceRecipient.address);
      addressControl.disable();
      countryControl.setValue(serviceRecipient.country);
      countryControl.disable();
      cityControl.setValue(serviceRecipient.city);
      cityControl.disable();
      stateControl.setValue(serviceRecipient.state);
      stateControl.disable();
      if (zip4Control) {
        zipControl.setValue(serviceRecipient.zip6);
        if (serviceRecipient.zip4RestUs) {
          zip4Control.setValue(serviceRecipient.zip4RestUs);
          zip4Control.disable();
        }
      } else if (serviceRecipient.country === 'US') {
        zipControl.setValue(
          serviceRecipient.zip4RestUs
            ? `${serviceRecipient.zip6}-${serviceRecipient.zip4RestUs}`
            : `${serviceRecipient.zip6}`
        );
      } else {
        zipControl.setValue(serviceRecipient.zip6);
      }
      zipControl.disable();
      svcType.requestedValue = serviceRecipient.typeOfServiceInd;
      restrict.requestedValue = serviceRecipient.typeOfServiceInd === 'Restricted' ? 'Yes' : 'No';
      svcRcpntName.requestedValue = serviceRecipient.serviceRecipientName;
    } else {
      name2Control.enable();
      addressControl.enable();
      countryControl.enable();
      cityControl.enable();
      stateControl.enable();
      zip4Control?.enable();
      zipControl?.enable();
      svcType.requestedValue = ' ';
      svcRcpntName.requestedValue = ' ';
      restrict.requestedValue = 'No';
    }
  }

  // THIS function exists because a bug on backend response
  static removeDuplicates({ array, typeKey, seqNbrKey, key = null }): any[] {
    return [...new Map(array.map((item) => [key ? item[key] : item[typeKey][seqNbrKey], item])).values()];
  }

  static parsePhoneNumber(number: string): any {
    const numbers = (number.match(/\d/g) || []).join(''); // remove all non numerics
    let countryCode;
    let main = numbers;

    if (numbers.length === 11) {
      // then country code is included
      countryCode = numbers[0];
      main = numbers.slice(1);
    }

    return {
      countryCode: countryCode,
      phoneNbr: numbers ? main.slice(0, 3) + '-' + main.slice(3) : undefined,
    };
  }

  static isValidPhone(phoneNbr: string): boolean {
    const splittedPhone = phoneNbr.split('-');
    return splittedPhone[0] && splittedPhone[0].length > 0 && splittedPhone[1] && splittedPhone[1].length > 0;
  }

  static cleanObject(obj): any {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  // TODO: Use this function in all the zip inputs
  getZipFormatted(zip?: string, zip4?: string): string {
    if (zip) {
      return zip4 && zip4 !== undefined && zip4.length ? `${zip}-${zip4}` : zip;
    } else {
      return '';
    }
  }

  showServiceErrMessage(err): void {
    this.snackbar.open({
      message: this.getServiceErrorMessage(err),
      status: 'error',
      matConfig: {
        duration: ConstantsService.snackbarDuration,
      },
    });
  }

  private getServiceErrorMessage(err): string {
    return err && err.error && err.error.moreInfo[0] ? err.error.moreInfo[0].message : '';
  }

  shouldRunDuplicateMatch(currentValue, formValue, newLoc = false): boolean {
    const nameAndAddressFields = ['address', 'name1', 'name2', 'cityName', 'countryCd', 'stateCd', 'zipCd', 'zip4Cd'];

    if (newLoc) {
      return true;
    }

    return nameAndAddressFields.some((key) => {
      return (currentValue[key] || undefined) !== (formValue[key] || undefined);
    });
  }

  addressChanged(current, requested): boolean {
    return (
      current.address !== requested.address ||
      current.cityName !== requested.cityName ||
      current.countryCd !== requested.countryCd ||
      current.stateCd !== requested.stateCd ||
      current.zipCd !== requested.zipCd ||
      (current.zip4Cd || undefined) !== (requested.zip4Cd || undefined)
    );
  }

  confirmAddress(
    standardizedAddress: FinalStandardAddress,
    requestedAddress: CustomerAddress,
    partyName: string,
    result: CustomerAddressValidationCd
  ): Observable<any> {
    return this.dialog
      .open(ConfirmAddressDialogComponent, {
        data: {
          asEnteredAddress: requestedAddress,
          validatedAddress: standardizedAddress,
          partyName: partyName,
          result: result,
        },
        disableClose: true,
        minWidth: 600,
      })
      .afterClosed();
  }

  duplicateMatch(matchedCustomers: CustomerMatch[], isAlias, args: any[] = [], callback): Observable<any> {
    if (matchedCustomers[0].accuracyPercentage > 99.4 && !isAlias) {
      this.snackbar.open({
        message:
          'Duplicate found ' + this.replaceSpaceWithNBSP(matchedCustomers[0].madCd) + '. Cannot process change.  ',
        status: 'error',
        matConfig: {
          duration: 0,
        },
      });
      return EMPTY;
    } else {
      return this.dialog
        .open(MatchCustomerTableComponent, { minWidth: 1200, disableClose: true, data: matchedCustomers })
        .afterClosed()
        .pipe(
          switchMap((res) => {
            if (res.ignoreDuplicateCheck) {
              args[0].ignoreDuplicateCheckInd = true;
              return callback(...args);
            } else {
              return EMPTY;
            }
          }),
          catchError((err) => throwError(err))
        );
    }
  }

  private replaceSpaceWithNBSP(input: string): string {
    if (input) {
      return input.split(' ').join(' ');
    }
    return '';
  }

  exportDialog(columns, rows, fileName): void {
    this.dialog.open(ExportDialogComponent, {
      minWidth: '90%',
      data: {
        rows: rows,
        columns: columns,
        fileName: fileName,
      },
    });
  }

  getEnsembleErrorMessage(code): string {
    return ExpireCustomerErrorCode[code];
  }

  isWritable<T extends Object>(obj: T, key: keyof T): boolean {
    const desc =
      Object.getOwnPropertyDescriptor(obj, key) ||
      Object.getOwnPropertyDescriptor(Object.getPrototypeOf(obj), key) ||
      {};
    return Boolean(desc.writable);
  }

  objValuesToUpperCase(obj): any {
    for (const key in obj) {
      if (obj[key] !== null && typeof obj[key] === 'object') {
        this.objValuesToUpperCase(obj[key]);
      } else if (
        obj[key] !== null &&
        typeof obj[key] === 'string' &&
        this.isWritable(obj, key) &&
        !this.enumKeys.includes(key)
      ) {
        obj[key] = obj[key].toUpperCase();
      }
    }
  }
}
