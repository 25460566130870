import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { RegionInfo } from '@xpo-ltl/ngx-auth/lib/authentication/model/region-info.model';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core/shell';
import { User } from '@xpo-ltl/sdk-common';
import { get as _get, invoke as _invoke, isEmpty as _isEmpty } from 'lodash';
import { interval, Observable } from 'rxjs';
import { map, skipWhile, take, tap } from 'rxjs/operators';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { UserRole } from './shared/enums/user-role/user-role.enum';
import { UserRoleService } from './shared/services/user-role/user-role.service';
import { AppState } from './store';
import { getLoggedInUser, getLoggedInUserRole } from './store/user/user.selectors';
declare var dtrum: any; // This is a dynatrace global Object

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ncis-Root',
  },
})
export class AppComponent {
  title = 'cBase';
  build: string;
  routes: Observable<XpoShellRoute[]>;

  constructor(
    private dialog: MatDialog,
    private configService: ConfigManagerService,
    private authService: XpoLtlAuthenticationService,
    private store: Store<AppState>,
    private config: ConfigManagerService,
    private router: Router,
    private titleService: Title
  ) {
    const region = this.configService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {});
    this.store.pipe(select(getLoggedInUser)).subscribe((user) => {
      const { role, ...xpoUser } = user;
      this.setDynatraceUserIdentity(xpoUser);
    });

    this.routes = this.store.pipe(
      select(getLoggedInUserRole),
      // skipWhile((role) => !role),
      map((role) => {
        let routes: XpoShellRoute[];

        switch (role) {
          case UserRole.CreditAnalyst:
          case UserRole.noUpdateAuthorization:
            routes = [
              {
                label: 'Dashboard',
                path: `/${AppRoutes.DASHBOARD_CODER_PAGE}`,
              },
              {
                label: ' Customer Request Search',
                path: `/${AppRoutes.CIS_SEARCH_PAGE}`,
              },
              {
                label: 'Service Recipient Search',
                path: `/${AppRoutes.SERVICE_RECIPIENT_PAGE}`,
              },
              {
                label: 'Group Credit Management',
                path: `/${AppRoutes.CREDIT_MANAGEMENT_PAGE}`,
              },
              {
                label: 'Customer Search',
                path: `/${AppRoutes.CUSTOMER_SEARCH_PAGE}`,
              },
            ];
            break;
          default:
            routes = [
              {
                label: 'Dashboard',
                path: `/${AppRoutes.DASHBOARD_CODER_PAGE}`,
              },
              {
                label: ' Customer Request Search',
                path: `/${AppRoutes.CIS_SEARCH_PAGE}`,
              },
              {
                label: 'Service Recipient Search',
                path: `/${AppRoutes.SERVICE_RECIPIENT_PAGE}`,
              },
              {
                label: 'Group Credit Management',
                path: `/${AppRoutes.CREDIT_MANAGEMENT_PAGE}`,
              },
              {
                label: 'Customer Search',
                path: `/${AppRoutes.CUSTOMER_SEARCH_PAGE}`,
              },
              {
                label: 'P&D Merge',
                path: `/${AppRoutes.PND_MERGE_PAGE}`,
              },
              {
                label: 'Create Customer',
                path: `/${AppRoutes.CREATE_CUSTOMER_PAGE}`,
              },
            ];
            break;
        }

        return routes;
      })
    );

    this.build = this.configService.getSetting('buildVersion');

    this.router.events.subscribe((res) => {
      const isChangeRequestorOutsideView =
        window.location.pathname.includes('change-request') || window.location.pathname.includes('location/');

      if (!isChangeRequestorOutsideView) {
        this.titleService.setTitle('XPO Customer Base');
      }
    });
  }

  private setDynatraceUserIdentity(user: User): void {
    const setUser = (): void =>
      _invoke(window['dtrum'], 'identifyUser', !_isEmpty(user.emailAddress) ? user.emailAddress : user.userId);

    if ((window['dtrum'] || {}).identifyUser) {
      setUser();
    } else {
      let retryCount: number = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !(window['dtrum'] || {}).identifyUser && retryCount <= 60),
          take(1)
        )
        .subscribe(() => setUser());
    }
  }
}
