import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CustomerAddress, CustomerLocationFunction, ServiceRecipient } from '@xpo-ltl-2.0/sdk-customer';
import { CustomerAccountTypeCd, CustomerCreditStatusCd, CustomerFunctionCd } from '@xpo-ltl/sdk-common';
import { AccountStatus, AccountStatusValue } from '../enums/account-form/account-status.enum';
import { CreditStatus, CreditStatusValue } from '../enums/account-form/credit-status.enum';
import { PaymentTermsValue } from '../enums/account-form/payment-terms.enum';

@Injectable()
export class ValidatorHelper {
  static noWhiteSpace(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const v = control.value ? control.value : '';

      return !v.length || (v.length && v.trim().length) ? null : { noWhiteSpace: true };
    };
  }

  static required(message: string): ValidatorFn {
    return (control: AbstractControl) => {
      return Validators.required(control) ? { required: message } : null;
    };
  }

  static maxLength(length: number, message: string): ValidatorFn {
    return (control: AbstractControl) => {
      return Validators.maxLength(length)(control) ? { maxLength: message } : null;
    };
  }

  static minLength(length: number, message: string): ValidatorFn {
    return (control: AbstractControl) => {
      return Validators.minLength(length)(control) ? { minLength: message } : null;
    };
  }

  static pattern(pattern: string, message: string): ValidatorFn {
    return (control: AbstractControl) => {
      return Validators.pattern(pattern)(control) ? { pattern: message } : null;
    };
  }

  static beginWithThe(): ValidatorFn {
    return (control: AbstractControl) => {
      const theRegex = /^the /i;
      const value = control ? control.value : '';
      return theRegex.test(value) ? { beginWithThe: 'Must not begin with "The".' } : null;
    };
  }

  static isPoBox(): ValidatorFn {
    return (control: AbstractControl) => {
      const regex = /\bP(ost)?[\.\s]*(O|0)(ff(ice)?)?[\.\s]+B(o|0)x/gi;
      const value = control ? control.value : '';
      return regex.test(value) ? { isPoBox: 'Must not be PO Box.' } : null;
    };
  }

  static zip4CdValidator(control): any {
    const country = control?.parent?.get('countryCd')?.value;

    switch (country) {
      case 'US':
        switch (true) {
          case control.value && control.value.length === 4 && control.value.replace(/[0-9]/g, '').length === 0:
            return null;
          case !control.value:
            return null;
          default:
            return { invalidZip4: 'Invalid zip code format.' };
        }
      case 'CA':
      case 'MX':
        return control.value ? { invalidZip4: 'Invalid zip code format.' } : null;
    }
  }

  static zipCdValidator(control): any {
    let result = null;
    const countryCd = control.parent?.get('countryCd')?.value;
    const value = control.value || '';
    switch (countryCd) {
      case 'US':
      case 'MX':
        result =
          value.length === 5 && value.replace(/\d/g, '').length === 0
            ? null
            : { invalidZip: 'Invalid zip code format.' };
        break;
      case 'CA':
        const zip = value.split(' ').join('');
        result =
          zip.length === 6
            ? /[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d/.test(zip)
              ? null
              : { invalidZip: 'Invalid zip code format.' }
            : { invalidZip: 'Invalid zip code format.' };
        break;
    }

    return result;
  }

  static creditStatusValidator(parent: CustomerLocationFunction): ValidatorFn {
    return (control: AbstractControl) => {
      const isCTSMadCode = parent?.madCd?.substring(5, 8)?.toUpperCase() === 'CTS';
      if (!!parent && !isCTSMadCode) {
        if (parent.creditStatusCd === CustomerCreditStatusCd.CREDIT) {
          return null;
        } else {
          return control.value !== CreditStatus.Credit
            ? null
            : { parentValueNotCredit: 'Cannot choose a credit status higher than parent.' };
        }
      } else {
        return null;
      }
    };
  }

  static creditStatusValidatorOnParentChange(control: AbstractControl, parentData: CustomerLocationFunction): void {
    this.removeControlError(control, 'parentValueNotCredit');
    const isCTSMadCode = parentData?.madCd?.substring(5, 8)?.toUpperCase() === 'CTS';

    if (!!parentData && !isCTSMadCode) {
      if (parentData.creditStatusCd !== CustomerCreditStatusCd.CREDIT && control.value === CreditStatus.Credit) {
        this.addControlError(control, { parentValueNotCredit: 'Cannot choose a credit status higher than parent.' });
      }
    }
  }

  static notBothValidator(otherKey, message): ValidatorFn {
    return (control) => {
      const otherControl = control?.parent?.get(otherKey);
      const controlTruthy = control?.value === 'Yes' || control?.value === true;
      const otherTruthy = otherControl?.value === 'Yes' || otherControl?.value === true;
      return controlTruthy && otherTruthy ? { notBoth: message } : null;
    };
  }

  static taxIdValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (control?.value) {
        const hasInvalidCharacters = control.value && control.value.replace(/[\d\-]/g, '').length > 0;

        if (hasInvalidCharacters) {
          return { hasInvalidCharacters: 'Invalid characters. Must be numbers and hyphens' };
        } else {
          const match1 = control.value.match(/[\d]{2}-[\d]{7}/) && control.value.match(/[\d]{2}-[\d]{7}/)[0];
          const match2 =
            control.value.match(/[\d]{3}-[\d]{2}-[\d]{4}/) && control.value.match(/[\d]{3}-[\d]{2}-[\d]{4}/)[0];
          return control.value === match1 || control.value === match2
            ? null
            : { invalidTaxId: 'Invalid format. Must be NNN-NN-NNNN or NN-NNNNNNN format.' };
        }
      } else {
        return null;
      }
    };
  }

  static valueChangedValidator(canChange, currentValue, message): ValidatorFn {
    return (control: AbstractControl) => {
      return canChange || currentValue?.toString() === control?.value?.toString() ? null : { valueChanged: message };
    };
  }

  static customerSearchValidator = () => {
    return (control: AbstractControl) => {
      const filters = control?.get('filters')?.value;
      const madCd = control?.get('madCode')?.value;
      const name = control?.get('addresses')?.get('name1')?.value;
      const address = control?.get('addresses')?.get('address')?.value;
      if (!filters || filters.length === 0) {
        return { atLeastOne: 'At least one Customer Type is required.' };
      }
      if (!name && !address && !madCd) {
        return { atLeastOne: 'At least one MAD or Name or Address is required.' };
      }
      return null;
    };
  };

  static validateSalesTerritory(currentLoc): ValidatorFn {
    return (control: AbstractControl) => {
      let error = {};
      const functionCd = currentLoc?.functionCd || control?.get('functionCd')?.value;
      const salesTerritory = control?.get('salesTerritoryCd')?.value;
      const accountTypeCd = control?.get('acctTypeCd')?.value || currentLoc?.acctTypeCd;

      this.removeControlError(control?.get('salesTerritoryCd'), 'invalidSalesTerr');

      if (functionCd !== 'Bill-To' && control?.get('salesTerritoryCd') && control?.get('acctTypeCd')) {
        switch (true) {
          case functionCd === 'Corporate' && accountTypeCd === 'NationalAccount' && salesTerritory?.length !== 3:
            error['invalidSalesTerr'] = 'Sales Territory must contain 3 characters';
            break;
          case functionCd === 'Corporate' && accountTypeCd === 'LocalAccount' && salesTerritory?.length !== 5:
            error['invalidSalesTerr'] = 'Sales Territory must contain 5 characters';
            break;
        }
      }
      error = Object.keys(error)?.length === 0 ? null : error;
      this.addControlError(control?.get('salesTerritoryCd'), error);
      return error;
    };
  }

  static validateParent(currentLoc): ValidatorFn {
    return (control: AbstractControl) => {
      let error = {};
      const madCd = currentLoc?.madCd;
      const parent = control?.get('parentCorpMadCd')?.value?.madCd;
      this.removeControlError(control?.get('parentCorpMadCd'), 'invalidParent');

      if (parent === madCd) {
        error['invalidParent'] = 'Parent must not be itself';
      }
      error = Object.keys(error)?.length === 0 ? null : error;
      this.addControlError(control?.get('parentCorpMadCd'), error);
      return error;
    };
  }

  static invalidCreditCombination(currentLoc): ValidatorFn {
    return (control: AbstractControl) => {
      let error = {};
      const status = control?.value?.customerStatusCd;
      const bankruptInd = control?.get('bankruptInd')?.value;
      const creditStatusCd = control?.get('creditStatusCd')?.value || currentLoc?.creditStatusCd;
      const creditLimit = +control?.get('authorizationLimitAmount')?.value;
      const hasValue = !!(creditStatusCd && creditStatusCd !== undefined);
      const creditLimitHasValue = creditLimit !== undefined;
      const paymentTerm = control?.get('paymentTermCd')?.value;
      const hasAuthrzNbr = !!(currentLoc?.authrzNbr || control?.get('authorizationNbr')?.value);
      this.removeControlError(control?.get('creditStatusCd'), 'creditLimitOutOfRange');
      this.removeControlError(control?.get('creditStatusCd'), 'invalidCreditCombination');
      if (status === AccountStatusValue.expired) {
        this.removeControlError(control?.get('authorizationLimitAmount'), 'creditLimitOutOfRange');
        return null;
      } else {
        if (control?.get('creditStatusCd') || control?.get('authorizationLimitAmount')) {
          if (
            hasValue &&
            (creditLimitHasValue || currentLoc?.authrzLimitAmount) &&
            !!(currentLoc?.paymentTermCd || control?.get('paymentTermCd')?.value)
          ) {
            const paymentTermValue = paymentTerm !== undefined ? paymentTerm : currentLoc?.paymentTermCd;
            const creditLimitValue = creditLimit !== undefined ? creditLimit : +currentLoc?.authrzLimitAmount;
            let isValidCreditLimit, isValidPaymentTerm;
            switch (true) {
              case creditStatusCd === CreditStatusValue.Credit:
                isValidCreditLimit = !hasValue || (creditLimitValue >= 1 && creditLimitValue <= 999999999);
                isValidPaymentTerm =
                  paymentTermValue &&
                  ![PaymentTermsValue.Immediate, PaymentTermsValue.Delinquent].includes(paymentTermValue);
                if (!isValidCreditLimit) {
                  error['creditLimitOutOfRange'] = 'Credit Limit must be between 1$ and 999,999,999$.';
                }
                if (!isValidPaymentTerm) {
                  error['invalidCreditCombination'] = 'Invalid credit combination with Status, Limit, and Terms.';
                }
                break;
              case creditStatusCd === CreditStatusValue.Cash && bankruptInd:
              case creditStatusCd === CreditStatusValue.Voluntary && bankruptInd:
                error['invalidCreditCombination'] =
                  'Invalid credit combination with Bankrupt, Status, Limit, and Terms.';
                break;
              case creditStatusCd === CreditStatusValue.Cash:
              case creditStatusCd === CreditStatusValue.NoCredit:
              case creditStatusCd === CreditStatusValue.Voluntary:
                isValidCreditLimit = creditLimitValue === 0;
                isValidPaymentTerm =
                  paymentTermValue &&
                  [PaymentTermsValue.Immediate, PaymentTermsValue.Delinquent].includes(paymentTermValue);
                if (!isValidCreditLimit) {
                  error['creditLimitOutOfRange'] = 'Credit Limit must be 0$.';
                }
                if (!isValidPaymentTerm) {
                  error['invalidCreditCombination'] = 'Invalid credit combination with Status, Limit, and Terms.';
                }
                break;
            }
          } else {
            error = { invalidCreditCombination: 'Invalid credit combination with Status, Limit, and Terms.' };
          }
        }
        error = Object.keys(error).length === 0 ? null : error;
        this.addControlError(control?.get('creditStatusCd'), error);
        return error;
      }
    };
  }

  static checkDoNotMatch(): ValidatorFn {
    return (control: AbstractControl) => {
      const theRegex = /donotuse|dontuse|donotmatch|dontmatch/;
      const value = control ? control.value?.toLowerCase() : '';
      return theRegex.test(value?.replace(/[^A-Z0-9]+/gi, ''))
        ? { doNotMatch: 'Change status to INACTIVE instead of changing Name/address' }
        : null;
    };
  }

  static checkDoNotMatchForm(): ValidatorFn {
    return (control: AbstractControl) => {
      let error = {};
      if (control?.get('customerStatusCd')?.value === AccountStatusValue.active) {
        const theRegex = /donotuse|dontuse|donotmatch|dontmatch/;
        const name1 = control?.get('name1')?.value?.toLowerCase();
        const name2 = control?.get('name2')?.value?.toLowerCase();
        const address = control?.get('address')?.value?.toLowerCase();

        if (theRegex.test(name1?.replace(/[^A-Z0-9]+/gi, ''))) {
          this.addControlError(control?.get('name1'), {
            doNotMatch: 'Change status to INACTIVE instead of changing Name/address',
          });
        } else {
          this.removeControlError(control?.get('name1'), 'doNotMatch');
        }

        if (theRegex.test(name2?.replace(/[^A-Z0-9]+/gi, ''))) {
          this.addControlError(control?.get('name2'), {
            doNotMatch: 'Change status to INACTIVE instead of changing Name/address',
          });
        } else {
          this.removeControlError(control?.get('name2'), 'doNotMatch');
        }

        if (theRegex.test(address?.replace(/[^A-Z0-9]+/gi, ''))) {
          this.addControlError(control?.get('address'), {
            doNotMatch: 'Change status to INACTIVE instead of changing Name/address',
          });
        } else {
          this.removeControlError(control?.get('address'), 'doNotMatch');
        }
      } else {
        this.removeControlError(control?.get('name1'), 'doNotMatch');
        this.removeControlError(control?.get('name2'), 'doNotMatch');
        this.removeControlError(control?.get('address'), 'doNotMatch');
      }

      return error;
    };
  }

  static invalidGovernmentAffiliateCombination(): ValidatorFn {
    return (control: AbstractControl) => {
      const affiliateInd = control?.get('affiliateInd')?.value === 'Yes';
      const govtCustomerInd = control?.get('govtCustomerInd')?.value === 'Yes';
      this.removeControlError(control?.get('affiliateInd'), 'invalidGovernmentAffiliateCombination');
      this.removeControlError(control?.get('govtCustomerInd'), 'invalidGovernmentAffiliateCombination');

      const error =
        affiliateInd && govtCustomerInd
          ? { invalidGovernmentAffiliateCombination: 'Invalid Government and Affiliate combination.' }
          : null;
      this.addControlError(control?.get('affiliateInd'), error);
      this.addControlError(control?.get('govtCustomerInd'), error);
      return error;
    };
  }

  static removeControlError(control, errorKey): void {
    const formErrors = control?.errors ? { ...control.errors } : {};
    delete formErrors[errorKey];
    control?.setErrors(Object.keys(formErrors).length ? formErrors : null);
  }

  static addControlError(control, error = {}): void {
    const formErrors = control?.errors ? { ...control.errors } : {};
    Object.assign(formErrors, error);
    control?.setErrors(Object.keys(formErrors).length ? formErrors : null);
  }

  static duplicateCheckNameValidator(duplicateCheckControl: boolean): ValidatorFn {
    return (control: AbstractControl) => {
      const duplicateCheckValue = control?.parent?.get('duplicateCheck')?.value || duplicateCheckControl;
      return !duplicateCheckValue ? { mustRunDuplicateCheck: 'You must run Duplicate check.' } : null;
    };
  }

  static duplicateCheckFormValidator(form: UntypedFormGroup, duplicateCheckControl: boolean): void {
    if (!duplicateCheckControl) {
      this.addControlError(form.controls['name1'], {
        mustRunDuplicateCheck: 'You must run Duplicate check.',
      });
    } else {
      this.removeControlError(form.controls['name1'], 'mustRunDuplicateCheck');
    }
  }

  static validateCreditOnParentRemoval(form: UntypedFormGroup, parent: CustomerLocationFunction): void {
    if (!parent) {
      this.removeControlError(form.controls['creditStatusCd'], 'parentValueNotCredit');
    }
  }

  static creditLimitValidator(currentLocCreditStatusCd): ValidatorFn {
    return (control: AbstractControl) => {
      const creditStatusCd = control?.parent?.get('creditStatusCd')?.value || currentLocCreditStatusCd;
      const creditLimit = +control?.value;
      const hasValue = !!(control && control.value !== undefined);

      switch (true) {
        case creditStatusCd === CreditStatus.Credit:
          return !hasValue || (creditLimit >= 1 && creditLimit <= 999999999)
            ? null
            : { creditLimitOutOfRange: 'Credit Limit must be between 1$ and 999,999,999$.' };
        default:
          return creditLimit === 0 ? null : { creditLimitOutOfRange: 'Credit Limit must be 0$.' };
      }
    };
  }

  static paymentTermsValidator(currentLocCreditStatusCd): ValidatorFn {
    return (control: AbstractControl) => {
      const creditStatusCd = control?.parent?.get('creditStatusCd')?.value || currentLocCreditStatusCd;
      const paymentTerm = control?.value;
      const hasValue = !!(control && control.value !== undefined);

      switch (true) {
        case creditStatusCd === CreditStatus.Credit:
          return hasValue && ![PaymentTermsValue.Immediate, PaymentTermsValue.Delinquent].includes(paymentTerm)
            ? null
            : { invalidCreditCombination: 'Invalid credit combination with Status, Limit, and Terms.' };
        default:
          return hasValue && [PaymentTermsValue.Immediate, PaymentTermsValue.Delinquent].includes(paymentTerm)
            ? null
            : { invalidCreditCombination: 'Invalid credit combination with Status, Limit, and Terms.' };
      }
    };
  }

  static onlyNumbers(message: string): ValidatorFn {
    return (control: AbstractControl) => {
      return control.value
        ? control.value.toString().replace(/\d/g, '').length === 0
          ? null
          : { invalidCharacters: message }
        : null;
    };
  }

  static phoneNbrValidator(message): ValidatorFn {
    return (control: AbstractControl) => {
      if (control?.value) {
        const { areaCd, number } = control.value;

        if (areaCd || number) {
          return areaCd.length === 3 && number.length === 7 ? null : { invalidPhone: message };
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  static requiredPhoneNbr(message): ValidatorFn {
    return (control: AbstractControl) => {
      return control?.value && control.value.areaCd && control.value.number ? null : { required: message };
    };
  }

  static validateFields(
    form: UntypedFormGroup,
    locationType: string,
    collectorGroup: string,
    madCode: string,
    pricing: string,
    openAr: string,
    partyName: string,
    partyId
  ): void {
    if (form.get('creditStatusCd')) {
      const creditStatus = form.get('creditStatusCd').value;
      const condition = creditStatus === CreditStatus.Cash || creditStatus === CreditStatus.NoCredit;

      // if MAD code is not in Collector Group and Payment Terms is Delinquent, then credit status must be cash, no credit.
      if (
        form.get('paymentTermCd') &&
        form.get('paymentTermCd').value === PaymentTermsValue.Delinquent &&
        !collectorGroup &&
        !condition
      ) {
        form.controls['creditStatusCd'].setErrors({ condition: 'Credit status must be cash, no credit' });
      }
      // if MAD code is not in Collector Group and Payment Terms is NOT Delinquent and Credit Status is Credit,
      //  then Payment Terms must be 15 Days.
      if (
        form.get('paymentTermCd') &&
        form.get('paymentTermCd').value !== PaymentTermsValue.Delinquent &&
        !collectorGroup &&
        creditStatus === CreditStatus.Credit
      ) {
        form.controls['creditStatusCd'].setErrors({ error: 'Payment Terms must be 15 Days' });
      }
      // If MAD code is not in Collector Group and Payment Terms is NOT Delinquent and Credit Status is NOT Credit,
      // then Payment Terms must be Immediate.
      if (
        form.get('paymentTermCd') &&
        form.get('paymentTermCd').value !== PaymentTermsValue.Delinquent &&
        !collectorGroup &&
        creditStatus === CreditStatus.NoCredit
      ) {
        form.controls['creditStatusCd'].setErrors({ error: 'Payment Terms must be Immediate' });
      }
    }
    // Parent must not be itself
    if (form.get('parent') && madCode === form.get('parent').value) {
      form.controls['parent'].setErrors({ itself: 'Parent must not be itself' });
    }
    // You cannot change the parent, if there is active pricing.
    if (form.get('parent') && pricing === 'Yes') {
      form.controls['parent'].setErrors({ activePricing: 'Parent cannot be changed if there is an active pricing' });
    }
    // Change to a valid status
    // •	Must not have open AR
    // •	Must not have active pricing
    // •	Must not be an OBI customer
    if (locationType === CustomerFunctionCd.CORPORATE) {
      if (form.get('status') && (pricing === 'Yes' || openAr === 'Yes')) {
        form.controls['status'].setErrors({ activePricingOpenAr: 'Must not have open AR or active pricing' });
      }
      // Active status must go to Expired
      if (
        form.get('status') &&
        form.get('status').value === AccountStatus.inactive &&
        status === AccountStatus.active
      ) {
        form.controls['status'].setErrors({ invalidState: 'Active status must go to Expired' });
      }
    }
    // Expired MAD code status cannot be changed
    if (form.get('status') && form.get('status').value !== AccountStatus.expired && status === AccountStatus.expired) {
      form.controls['status'].setErrors({ expired: 'Expired MAD code status cannot be changed' });
    }
    if (locationType === CustomerFunctionCd.BILL_TO || locationType === CustomerFunctionCd.PICKUP_OR_DELIVERY) {
      // Active status must go to Inactive prior to Expired
      if (form.get('status') && form.get('status').value === AccountStatus.expired && status === AccountStatus.active) {
        form.controls['status'].setErrors({ invalidState: 'Active status must go to Inactive prior to Expired' });
      }
      // Cannot expire locations with pricing
      if (
        form.get('status') &&
        form.get('status').value === AccountStatus.expired &&
        status === AccountStatus.inactive &&
        (pricing === 'Yes' || openAr === 'Yes')
      ) {
        form.controls['status'].setErrors({ invalidState: 'Cannot expire locations with pricing' });
      }
    }
    if (locationType === CustomerFunctionCd.BILL_TO) {
      // Name2 can only be changed if you unrelate Service Recipient
      if (form.get('partyName2') && partyId) {
        form.controls['partyName2'].setErrors({
          serviceRecipient: 'Name2 can only be changed if you unrelate Service Recipient',
        });
      }
      // Name2 must match the associated Service Recipient
      if (form.get('partyName2') && form.get('partyName2').value !== partyName) {
        form.controls['partyName2'].setErrors({
          serviceRecipient: 'Name2 must match the associated Service Recipient',
        });
      }
    }
  }

  static validateParentCustomer(
    control: AbstractControl,
    parentData: CustomerLocationFunction,
    accTypeControl?: AbstractControl,
    parentAccType?: string
  ): void {
    const parentMadCd = parentData?.madCd;
    const parentId = parentData?.customerLocationFuncId;
    ['parentNotCorporate', 'parentIsCtsCorporate', 'parentNotActive'].forEach((errorKey) => {
      this.removeControlError(control, errorKey);
    });

    if (parentData) {
      switch (true) {
        case parentData.functionCd !== CustomerFunctionCd.CORPORATE:
          this.addControlError(control, { parentNotCorporate: 'Parent MAD code cannot be a P&D or Bill-To customer.' });
          break;
        case parentMadCd.slice(5, 8) === 'CTS':
          this.addControlError(control, { parentIsCtsCorporate: 'Parent MAD code cannot be a CTS customer.' });
          break;
        case parentData.statusCd !== 'ACTIVE':
          this.addControlError(control, { parentNotActive: 'Parent MAD code status must be Active.' });
          break;
        default:
          control.setValue({ id: parentId, madCd: parentMadCd });

          if (accTypeControl && parentAccType) {
            accTypeControl.setValue(parentAccType);
          }

          break;
      }
    } else {
      control.setValue(null);

      if (accTypeControl) {
        accTypeControl.setValue(CustomerAccountTypeCd.LOCAL_ACCOUNT);
      }
    }
  }

  static spacesFormatValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const { value = '' } = control;
      return value?.toString().trim() ? null : { invalidValue: 'Enter a valid value' };
    };
  }

  static emailFormatValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const { value = '' } = control;
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return value ? (regex.test(value) ? null : { invalidEmail: 'Invalid email address format' }) : null;
    };
  }

  static emailsFormatValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let value = control ? String(control.value) : '';
      let hasError = false;

      if (value) {
        hasError = value
          .split(',')
          .map((val) => val.trim())
          .some((val) => !regex.test(val));
        return hasError ? { invalidEmail: 'Invalid email address format' } : null;
      } else {
        return null;
      }
    };
  }

  static ccEmailFormatValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const regex = /([-A-Za-z0-9._%+-]+@(con-way.com|CON-WAY.COM|xpo.com))/gi;
      let value = control ? control.value : '';
      let hasError = false;
      if (value) {
        value = value.split(',');
        value.forEach((email) => {
          email = email.trim();
          hasError = regex.test(value) ? hasError : true;
        });
        return hasError ? { pattern: 'Must be an XPO email address.' } : null;
      } else {
        return null;
      }
    };
  }

  static emailsMaxLengthValidator(): ValidatorFn {
    const max = 70;
    return (control: AbstractControl) => {
      let value = control ? control.value : '';
      let hasError = false;

      if (value) {
        hasError = value
          .split(',')
          .map((val) => val.trim())
          .some((val) => val.length > max);
        return hasError ? { maxLength: `Some email addresses exceed maximum length of ${max} characters.` } : null;
      }
      {
        return null;
      }
    };
  }

  static amountValidator(limit): ValidatorFn {
    return (control: AbstractControl) => {
      return +control.value?.toString().replace(',', '') <= limit
        ? null
        : { maxAmount: 'Max value must be less than ' + limit };
    };
  }

  static compareStringsIgnoreCase(str1, str2): boolean {
    return str1?.toLowerCase() === str2?.toLowerCase();
  }
}
