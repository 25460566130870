<mat-form-field style="min-width: 250px; font-size: 14px;" class="quick-search-form" floatLabel="never">
  <input
    placeholder="Enter Customer or Request ID"
    [formControl]="searchValueControl"
    matInput
    type="text"
    [matAutocomplete]="auto"
    #searchInput
  />
  <mat-icon style="opacity: 0.5;" matSuffix>search</mat-icon>
  <mat-autocomplete #auto="matAutocomplete">
    <div *ngIf="!loading && !noResults">
      <mat-option style="height: auto;" *ngFor="let result of results" [value]="">
        <a
          [routerLink]="'/location/' + result.customerLocationFuncId"
          target="_blank"
          *ngIf="searchingForCustomer || searchingForLegacyCustomer"
        >
          <pre style="display: inline;">{{ searchValueControl.value?.toUpperCase() }}</pre>
          <span *ngIf="madCdChanged" class="new-madCd"> ({{ newMadCd }})</span>
          <span> ({{ customerStatusEnum[(result.customerLocation?.customerLocationFunction)[0]?.statusCd] }}) </span>
          <p class="quick-search-link">{{ result.customerLocation?.party1?.partyName }}</p>
        </a>
        <a [routerLink]="'/change-request/' + result.requestId" target="_blank" *ngIf="searchingForRequest">
          <span> {{ searchValueControl.value?.toUpperCase() }}</span
          ><span class="quick-search-link">{{ result.text }}</span>
        </a>
      </mat-option>
    </div>
    <mat-option *ngIf="loading">
      <mat-spinner diameter="25"></mat-spinner>
    </mat-option>
    <mat-option *ngIf="noResults">
      <div>No results found.</div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
