import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  CustomerLocationFuncAlias,
  CustomerRequest,
  RequestCustomerFuncAlias,
  UpdateCustomerRqst,
} from '@xpo-ltl-2.0/sdk-customer';

import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import {
  ActionCd,
  CustomerDetailCd,
  CustomerFunctionCd,
  CustomerIdTypeCd,
  CustomerLineStatusCd,
  CustomerRequestStatusCd,
} from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { ChangeRequestService } from 'src/app/change-request-page/services/change-request.service';
import { LocationDetailService } from 'src/app/location-details-page/services/location-details.service';
import { AppRoutes } from 'src/app/shared/enums/app-routes.enum';
import { Country } from 'src/app/shared/models/country';
import { CountryState } from 'src/app/shared/models/country-state';
import { ConstantsService } from 'src/app/shared/services/constants/constants.service';
import { ErrorStateManagerService } from 'src/app/shared/services/error-state-manager.service';
import { ValidatorHelper } from 'src/app/shared/validators';
import { AppState } from 'src/app/store';
import {
  getChangeRequest,
  getChangeRequestId,
  getRequestedChangeCurrentLine,
  locationHasWarnings,
  loggedInUserIsOwner,
} from 'src/app/store/change-request/change-request.selectors';
import { getLocationData } from 'src/app/store/location/location.selectors';
import { DialogComponent, DialogConfig } from '../../../dialog';

@Component({
  selector: 'detail-business-name',
  templateUrl: './detail-business-name.component.html',
  styleUrls: ['./detail-business-name.component.scss'],
  host: {
    class: 'ncis-BusinessName',
  },
})
export class DetailBusinessNameComponent implements ICellRendererAngularComp, OnDestroy {
  params: any;
  isChangeRequest = false;
  bnaForm: UntypedFormGroup;
  originalData: any;
  gridActions: any;
  countries: Country[];
  filteredStates: Observable<any[]>;
  states: CountryState[];
  countryChangeSubscription: Subscription;
  actionType: 'ADD' | 'UPDATE' | 'DELETE' | null = null;
  actionText = '';
  changeRequestId = null;
  remarks = '';
  locationSeqNbr = 0;
  customerRequest: CustomerRequest;
  requestChangesCurrentLocation: CustomerRequest;
  loggedInUserIsOwner: boolean = false;
  statusIsApproveOrRejected: boolean;
  statusIsApproved: boolean;
  statusIsHeld: boolean;
  isCompleted: boolean;
  isRequesterCanceled: boolean = false;
  isAbleToProcess: boolean;
  checkedMassAlias: boolean = false;
  isCorpLocation: boolean = false;
  isMassAliasUpdate: boolean = false;
  hasWarnings: boolean = false;
  hasMassAlias: boolean = false;

  private destroy$ = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private router: Router,
    private changeRequestService: ChangeRequestService,
    private snackbar: XpoSnackBar,
    private dialog: MatDialog,
    private locationDetailsService: LocationDetailService,
    private errorStateManager: ErrorStateManagerService
  ) {}

  agInit(params: any): void {
    this.isChangeRequest = this.isChangeRequestFn();
    this.params = params;
    const massIndValue = params?.node?.data?.massInd;
    if (massIndValue) {
      this.checkedMassAlias = massIndValue;
    }
    this.hasMassAlias = params.node.data?.auditInfo?.createByPgmId === 'MASS_OP';
    if (this.isChangeRequest) {
      this.statusIsApproved = this.params.data.statusCd === CustomerLineStatusCd.APPROVED;
      this.statusIsApproveOrRejected =
        this.statusIsApproved || this.params.data.statusCd === CustomerLineStatusCd.REJECTED;
      this.setIsAbleToProcess();
      this.statusIsHeld = this.params.data.statusCd === CustomerLineStatusCd.HELD;
      this.store.pipe(takeUntil(this.destroy$), select(getRequestedChangeCurrentLine)).subscribe((res) => {
        const currLoc = res.requestCustomerLocationFunction[0];
        this.requestChangesCurrentLocation = res;
        this.locationSeqNbr = currLoc ? currLoc.customerLocationFuncId : 0;
      });
      this.store
        .pipe(takeUntil(this.destroy$), select(getChangeRequestId))
        .subscribe((changeRequestId) => (this.changeRequestId = changeRequestId));
      this.store.pipe(takeUntil(this.destroy$), select(getChangeRequest)).subscribe((res) => {
        this.customerRequest = res.changeRequest;
        this.isCompleted = this.customerRequest?.statusCd === CustomerRequestStatusCd.COMPLETED;
        this.isRequesterCanceled = this.customerRequest?.statusCd === CustomerRequestStatusCd.CANCEL;
      });
      this.store.pipe(take(1), select(loggedInUserIsOwner)).subscribe((isOwner) => {
        this.loggedInUserIsOwner = isOwner;
      });

      this.store
        .pipe(
          select(locationHasWarnings, this.requestChangesCurrentLocation?.requestCustomerLocationFunction[0]?.madCd),
          take(1)
        )
        .subscribe((warnings) => {
          if (!warnings.isContact) {
            this.hasWarnings = warnings.disabled;
          }
        });
    } else {
      this.setIsAbleToProcess();
      this.store
        .select(getLocationData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((loc) => {
          this.isCorpLocation =
            loc.customerLocation.customerLocationFunction[0].functionCd === CustomerFunctionCd.CORPORATE;
        });
    }

    this.gridActions = params.customActions || null;
    this.isMassAliasUpdate = params.data.actionCd === 'CASCADE';
    if (this.isMassAliasUpdate) {
      this.checkedMassAlias = true;
    }
    this.actionType = params.data ? (this.isMassAliasUpdate ? 'UPDATE' : params.data.actionCd) : null;
    this.actionText = this.setActionText(params.data.actionCd);
    this.remarks = params.data.requestNote
      ? (params.data.requestNote.find((n) => n.noteTypeCd === 'General') || {}).note
      : '';
    this.countries = ConstantsService.countries;
    this.createFormGroup();

    this.errorStateManager
      .errorChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.setIsAbleToProcess();
      });
  }

  refresh(): boolean {
    return false;
  }

  createFormGroup(): void {
    this.originalData = this.params.data;
    const processNote = this.changeRequestService.getProcessNote(this.params.data.requestNote);
    this.bnaForm = this.fb.group({
      comments: [
        this.isChangeRequest && this.statusIsApproveOrRejected
          ? processNote?.note
          : this.isMassAliasUpdate
          ? 'Cascade Mass Alias'
          : this.remarks,
        this.isChangeRequest
          ? [ValidatorHelper.noWhiteSpace(), Validators.maxLength(800)]
          : [
              ValidatorHelper.maxLength(50, 'Remarks can not have more than 50 characters'),
              ValidatorHelper.required('Remarks is mandatory.'),
            ],
      ],
    });
  }

  private setIsAbleToProcess(): void {
    this.isAbleToProcess = this.isChangeRequest
      ? !this.statusIsApproved && !this.errorStateManager.hasError(this.params.node.parent.rowIndex.toString())
      : !this.errorStateManager.hasError(this.params.node.parent.rowIndex.toString());
  }

  disableValidators(): void {
    this.bnaForm.controls['comments'].setValidators([
      Validators.required,
      ValidatorHelper.noWhiteSpace(),
      Validators.maxLength(800),
    ]);
    this.bnaForm.controls['comments'].updateValueAndValidity();
  }

  enableValidators(): void {
    this.bnaForm.controls['comments'].setValidators([
      Validators.required,
      ValidatorHelper.noWhiteSpace(),
      Validators.maxLength(800),
    ]);
    this.bnaForm.controls['comments'].updateValueAndValidity();
  }

  updateCustomer(): void {
    this.store
      .select(getLocationData)
      .pipe(take(1))
      .subscribe((loc) => {
        const aliasForRequest = new CustomerLocationFuncAlias();
        const requestPayload = new UpdateCustomerRqst();
        requestPayload.customerDetailCd = CustomerDetailCd.ALIAS;
        const data = this.params.node.parent.data;

        aliasForRequest.customerLocationFuncId = loc.customerLocationFuncId;
        aliasForRequest.name1 = data.name1;
        aliasForRequest.name2 = data.name2;
        aliasForRequest.address = data.address;
        aliasForRequest.cityName = data.cityName;
        aliasForRequest.stateCd = data.stateCd;
        aliasForRequest.zipCd = data.zipCd;
        aliasForRequest.zip4Cd = data.zip4Cd;
        aliasForRequest.countryCd = data.countryCd;
        aliasForRequest.sequenceNbr = data.sequenceNbr;
        aliasForRequest.legacySequenceNbr = data.legacySequenceNbr || 0;
        aliasForRequest.deleteInd = data.deleteInd;
        requestPayload.massAliasInd = this.checkedMassAlias;
        requestPayload.actionCd = ActionCd[this.actionType];
        requestPayload.customerLocationFunctionAlias = aliasForRequest;
        requestPayload.ignoreAddressValidation = true;
        aliasForRequest.lastUpdateRemarks = this.bnaForm.value.comments;

        this.locationDetailsService.updateCustomer(
          requestPayload,
          loc.customerLocationFuncId,
          () => {
            this.params.node.parent.setExpanded(false);
            this.params.customActions.update();
          },
          false,
          false,
          requestPayload.actionCd === ActionCd.DELETE,
          true,
          true
        );
      });
  }

  approveRequest(): void {
    const requestPayload = Object.assign({}, this.customerRequest);
    const currentLine = this.getAliasLine(requestPayload);
    const madCd = requestPayload?.requestCustomerLocationFunction[0]?.customerLocationFuncId;
    Object.keys(currentLine).forEach((key) => {
      const nodeValue = this.params.node.parent.data[key];
      currentLine[key] = nodeValue === undefined && key !== 'zip4Cd' ? currentLine[key] : nodeValue;
    });
    if (this.params.node.data.name2 && !currentLine['name2']) {
      currentLine['name2'] = this.params.node.data.name2;
    }
    if (currentLine['zip4Cd'] === undefined) {
      currentLine['zip4Cd'] = null;
    }
    if (currentLine.actionCd === 'DELETE' ? currentLine.existngAliasSequenceNbr : true) {
      this.confirmApprove().subscribe((resp) => {
        if (resp) {
          this.changeRequestService
            .approveCustomerRequest(
              requestPayload,
              this.params.data.statusCd,
              this.bnaForm.get('comments'),
              CustomerDetailCd.ALIAS,
              currentLine,
              currentLine.actionCd === 'DELETE',
              true
            )
            .subscribe((response) => {
              if (response) {
                this.locationDetailsService
                  .getLocationData(madCd.toString(), CustomerIdTypeCd.CUSTOMER_LOCATION_FUNCTION_ID, true)
                  .subscribe((_) => {
                    this.params.customActions.update();
                    this.snackbar.open({
                      message: 'A requested change has been processed.',
                      status: 'success',
                      matConfig: {
                        duration: ConstantsService.snackbarDuration,
                      },
                    });
                    this.params.node.parent.setExpanded(false);
                  });
              }
            });
        }
      });
    } else {
      this.snackbar.open({
        message: 'Alias does not exist. You must reject the change',
        status: 'error',
        matConfig: {
          duration: ConstantsService.snackbarDuration,
        },
      });
    }
  }

  private getAliasLine(requestPayload): RequestCustomerFuncAlias {
    const currentLocation = Object.assign({}, this.requestChangesCurrentLocation.requestCustomerLocationFunction[0]);

    const aliasLine = Object.assign(
      {},
      currentLocation.requestCustomerFunctionAlias?.find(
        (alias) => alias.requestAliasSequenceNbr === this.params.node.data.requestAliasSequenceNbr
      )
    );

    requestPayload.requestCustomerLocationFunction = [currentLocation];

    currentLocation.requestCustomerFunctionAlias = [aliasLine];

    return aliasLine;
  }

  private processRequest(action: string, status: string): void {
    const requestPayload = Object.assign({}, this.customerRequest);
    this.changeRequestService
      .rejectHoldCustomerRequest(
        requestPayload,
        this.params.data.statusCd,
        this.bnaForm.get('comments'),
        action,
        CustomerDetailCd.ALIAS.toUpperCase(),
        status,
        this.getAliasLine(requestPayload)
      )
      .subscribe((response) => {
        if (response) {
          this.snackbar.open({
            message: 'A requested change has been processed.',
            status: 'success',
            matConfig: {
              duration: ConstantsService.snackbarDuration,
            },
          });
          this.params.node.parent.setExpanded(false);
        }
      });
  }

  rejectRequest(): void {
    this.enableValidators();
    if (this.bnaForm.get('comments').value) {
      this.processRequest('reject', CustomerLineStatusCd.REJECTED);
    }
  }

  holdRequest(): void {
    this.enableValidators();
    if (this.bnaForm.get('comments').value) {
      this.processRequest('hold', CustomerLineStatusCd.HELD);
    }
  }

  cancelForm(): void {
    this.params.node.parent.setExpanded(false);
    this.errorStateManager.removeKeyErrors(this.params.node.rowIndex.toString());
    if (this.isMassAliasUpdate) {
      this.params.node.parent.data.actionCd = null;
    }
    if (this.actionType === 'ADD' && !this.isChangeRequest) {
      this.gridActions.rollback(this.params.node);
    }
  }

  isChangeRequestFn(): boolean {
    const urlParts = this.router.routerState.snapshot.url.split('/') || [];
    return urlParts.findIndex((item) => item === AppRoutes.CHANGE_REQUEST_PAGE) !== -1;
  }

  getRemainingCharacters(): number {
    const charCount = this.bnaForm.get('comments').value ? this.bnaForm.get('comments').value.length : 0;
    return ConstantsService.textAreaMaxLength - charCount;
  }

  setActionText(actionType): string {
    if (!actionType) {
      return 'ADD';
    }
    return actionType;
  }

  isAllDataFilled(): boolean {
    const { address, name1, cityName, stateCd, zipCd, countryCd } = this.params.node.parent.data;

    return address && name1 && cityName && stateCd && zipCd && countryCd;
  }

  changeValueMassAlias({ checked }): void {
    this.checkedMassAlias = checked;

    // update mass alias column only when adding a new alias
    if (this.actionType === 'ADD') {
      const newData = { ...this.params.node.data };
      newData['massInd'] = checked;

      const res = this.params?.node?.parent?.gridApi?.applyTransaction({ update: [newData] });
    }
  }

  private confirmApprove(): Observable<boolean> {
    const dialog: DialogConfig = {
      title: 'Update request line',
      content: `This request line was previously <b>Rejected</b>. Would you like to <b>Approve</b> it?.
      <br\><br\>
      NOTE: You can't undo this change.
      `,
      icon: 'help',
      actions: [
        {
          label: 'CONFIRM',
          type: 'primary',
          resultType: 'CLOSE',
          resultAction: true,
          position: 'right',
        },
        {
          label: 'CANCEL',
          type: 'secondary',
          resultType: 'CLOSE',
          resultAction: false,
          position: 'left',
        },
      ],
    };

    if (this.statusIsApproveOrRejected) {
      return this.dialog
        .open(DialogComponent, { maxWidth: '600px', data: dialog })
        .afterClosed()
        .pipe(map((resp) => resp.resultAction));
    }

    return of(true);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
