import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Observable } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { UserRole } from 'src/app/shared/enums/user-role/user-role.enum';
import { AppState } from 'src/app/store';
import { isProcessableChangeRequest } from 'src/app/store/change-request/change-request.selectors';
import { getLoggedInUserRole } from 'src/app/store/user/user.selectors';
import { AppRoutes } from '../../enums/app-routes.enum';

@Component({
  selector: 'app-edit-icon',
  templateUrl: './edit-icon.component.html',
  styleUrls: ['./edit-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ncis-GridActions-wrapper ncis-EditIconComponent',
  },
})
export class EditIconComponent implements ICellRendererAngularComp {
  params: any;
  gridActions: any;
  isChangeRequest: boolean;
  action: 'ADD' | 'UPDATE' | 'DELETE' | null = null;
  status = '';
  isProcessable = false;
  userRole$: Observable<UserRole>;
  massAliasUpdate = 'PROPAGATE';
  propagateMassAlias = 'Cascade Mass Alias';

  constructor(private router: Router, private store: Store<AppState>) {}

  agInit(params: any): void {
    this.isChangeRequest = this.isChangeRequestFn();
    this.store.select(isProcessableChangeRequest).subscribe((res) => {
      // this.isProcessable = res; REVIEW CONDITION
      this.isProcessable = true;
    });
    this.params = params;
    this.gridActions = params.customActions || null;
    this.action = params.data && params.data.actionCd ? params.data.actionCd : null;
    this.status = params.data && params.data.statusCd ? params.data.statusCd : null;
    this.userRole$ = this.store.pipe(
      select(getLoggedInUserRole),
      skipWhile((role) => !role)
    );
  }

  refresh(): boolean {
    return false;
  }

  isChangeRequestFn(): boolean {
    const urlParts = this.router.routerState.snapshot.url.split('/') || [];
    return urlParts.findIndex((item) => item === AppRoutes.CHANGE_REQUEST_PAGE) !== -1;
  }

  getIcon(action: string): string {
    switch (action) {
      case 'UPDATE':
        return 'create';
      case 'DELETE':
        return 'delete';
      case 'CASCADE':
        return 'redo';
    }
  }

  setActionType(action: string): void {
    this.params.node.setExpanded(false);
    this.params.node.setData({ ...this.params.node.data, actionCd: action });
    this.params.node.setExpanded(true);
  }

  moreDetailsClick(): void {
    this.params.moreDetailsAction(this.params.node);
  }
}
