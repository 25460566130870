<form class="ncis-new-contact-form" [formGroup]="newContactForm">
  <div class="ncis-new-contact-form-title">{{ title }}</div>
  <div class="ncis-new-contact-form-contact-type">
    <div class="ncis-new-contact-form-field-wrapper">
      <span>CONTACT TYPE</span>
      <mat-form-field [matTooltip]="getErrorMessage('contactRoleCd')" matTooltipPosition="above">
        <mat-select disableOptionCentering="true" formControlName="contactRoleCd"
          [value]="newContactForm.controls['contactRoleCd'].value">
          <mat-option *ngFor="let option of contactTypes" [value]="option.value">{{ option.label }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ncis-new-contact-form-field-wrapper">
      <span>JOB TITLE</span>
      <mat-form-field [matTooltip]="getErrorMessage('title')" matTooltipPosition="above">
        <input formControlName="title" matInput type="text" />
      </mat-form-field>
    </div>
  </div>
  <div class="ncis-new-contact-form-full-name">
    <div class="ncis-new-contact-form-field-wrapper">
      <span>FIRST NAME</span>
      <mat-form-field [matTooltip]="getErrorMessage('firstName')" matTooltipPosition="above">
        <input formControlName="firstName" matInput type="text" maxlength="10" />
      </mat-form-field>
    </div>
    <div class="ncis-new-contact-form-field-wrapper">
      <span>LAST NAME</span>
      <mat-form-field [matTooltip]="getErrorMessage('lastName')" matTooltipPosition="above">
        <input formControlName="lastName" matInput type="text" maxlength="20" />
      </mat-form-field>
    </div>
  </div>
  <div class="ncis-new-contact-form-address">
    <div class="ncis-new-contact-form-field-wrapper">
      <span>ADDRESS</span>
      <mat-form-field [matTooltip]="getErrorMessage('address')" matTooltipPosition="above">
        <input formControlName="address" matInput type="text" />
      </mat-form-field>
    </div>
    <div class="ncis-new-contact-form-field-wrapper">
      <span>CITY</span>
      <mat-form-field [matTooltip]="getErrorMessage('cityName')" matTooltipPosition="above">
        <input formControlName="cityName" matInput type="text" maxlength="20" />
      </mat-form-field>
    </div>
    <div class="ncis-new-contact-form-field-wrapper">
      <span>STATE</span>
      <mat-form-field [matTooltip]="getErrorMessage('stateCd')" matTooltipPosition="above">
        <mat-select disableOptionCentering="true" formControlName="stateCd"
          [value]="newContactForm.controls['stateCd'].value">
          <mat-option *ngFor="let state of states$ | async" [value]="state.abbreviation">{{ state.abbreviation }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ncis-new-contact-form-field-wrapper">
      <span>POSTAL CODE</span>
      <mat-form-field [matTooltip]="getErrorMessage('zipCd')" matTooltipPosition="above">
        <input formControlName="zipCd" matInput type="text" />
      </mat-form-field>
    </div>
    <div class="ncis-new-contact-form-field-wrapper">
      <span>COUNTRY</span>
      <mat-form-field [matTooltip]="getErrorMessage('countryCd')" matTooltipPosition="above">
        <mat-select (selectionChange)="countryChange($event)" disableOptionCentering="true" formControlName="countryCd"
          [value]="newContactForm.controls['countryCd'].value">
          <mat-option *ngFor="let country of countryList" [value]="country">{{ country }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="ncis-new-contact-form-phone">
    <div class="ncis-new-contact-form-field-wrapper">
      <div class="phone-number">
        <span>PHONE NUMBER</span>
        <mat-form-field [matTooltip]="getErrorMessage('phoneNbr')" matTooltipPosition="above">
          <app-phone-number-input [showCountryCd]="true" formControlName="phoneNbr"> </app-phone-number-input>
        </mat-form-field>
      </div>
      <div class="extension">
        <span>EXT</span>
        <mat-form-field [matTooltip]="getErrorMessage('businessPhoneExt')" matTooltipPosition="above">
          <input formControlName="businessPhoneExt" matInput type="text" />
        </mat-form-field>
      </div>
    </div>
    <div class="ncis-new-contact-form-field-wrapper">
      <span>FAX NUMBER</span>
      <mat-form-field [matTooltip]="getErrorMessage('faxNbr')" matTooltipPosition="above">
        <app-phone-number-input formControlName="faxNbr"> </app-phone-number-input>
      </mat-form-field>
    </div>
    <div class="ncis-new-contact-form-field-wrapper">
      <span>EMAIL ADDRESS</span>
      <mat-form-field [matTooltip]="getErrorMessage('emailId')" matTooltipPosition="above">
        <input formControlName="emailId" matInput type="text" />
      </mat-form-field>
    </div>
  </div>
  <div class="ncis-new-contact-form-notes">
    <div class="ncis-new-contact-form-field-wrapper">
      <span>CONTACT NOTES</span>
      <mat-form-field [matTooltip]="getErrorMessage('contactNote')" matTooltipPosition="above">
        <input formControlName="contactNote" matInput type="text" />
      </mat-form-field>
    </div>
  </div>
  <div class="ncis-new-contact-form-notes">
    <div class="ncis-new-contact-form-field-wrapper">
      <span>LAST UPDATE REMARKS</span>
      <mat-form-field matTooltipPosition="above">
        <input formControlName="lastUpdateRemarks" matInput type="text" />
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="!isChangeRequest" class="ncis-new-contact-form-remarks">
    <div class="ncis-new-contact-form-field-wrapper">
      <p>Remarks</p>
      <mat-form-field [matTooltip]="getErrorMessage('remarks')" matTooltipPosition="above">
        <input formControlName="remarks" matInput type="text" maxlength="50" />
      </mat-form-field>
    </div>
  </div>
  <div class="ncis-new-contact-form-actions">
    <button id="contact-modal-save" *ngIf="!isChangeRequest" mat-flat-button [disabled]="newContactForm.invalid"
      (debounceClick)="onSave()" type="submit">
      {{ successButtonTitle }}
    </button>
    <button id="contact-modal-cancel" mat-stroked-button (click)="onCancel()" type="button">
      {{ closeButtonTitle }}
    </button>
  </div>
</form>
