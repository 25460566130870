import { Pipe, PipeTransform } from '@angular/core';
import { CountryState } from '../models/country-state';

@Pipe({
  name: 'stateSort',
})
export class StateSortPipe implements PipeTransform {
  transform(states: CountryState[]): CountryState[] {
    return states?.sort((a, b) => a.abbreviation.localeCompare(b.abbreviation));
  }
}
