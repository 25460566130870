import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CustomerRequest, GetCustomerResp } from '@xpo-ltl-2.0/sdk-customer';
import { XpoAgGridColumns } from '@xpo-ltl/ngx-ag-grid';
import { XpoAgGridBoardApi, XpoAgGridBoardReadyEvent, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-board/ag-grid';
import {
  XpoBoardDataFetchState,
  XpoBoardOptions,
  XpoBoardState,
  XpoBoardViewConfig,
  XpoLocalStorageBoardViewDataStore,
} from '@xpo-ltl/ngx-board/core';
import { CustomerFunctionCd, CustomerLineStatusCd, CustomerNoteForCd } from '@xpo-ltl/sdk-common';
import { CellPosition, ColumnApi, GridApi, GridOptions, RowNode } from 'ag-grid-community';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { skipWhile, switchMap, take, takeUntil } from 'rxjs/operators';
import { YesNo } from 'src/app/change-request-page/models/enums/yes-no';
import { CustomerBusinessNameService } from 'src/app/location-details-page/company-information/services/customer-business-name.service';
import { LineStatusCellRendererComponent } from 'src/app/shared/components/line-status-cell-renderer/line-status-cell-renderer.component';
import { AppRoutes } from 'src/app/shared/enums/app-routes.enum';
import { UserRole } from 'src/app/shared/enums/user-role/user-role.enum';
import { ErrorStateManagerService } from 'src/app/shared/services/error-state-manager.service';
import { StatesService } from 'src/app/shared/services/states/states.service';
import { ValidatorHelper } from 'src/app/shared/validators';
import { AppState } from 'src/app/store';
import {
  getChangeRequest,
  getRequestedChangeCurrentLine,
  loggedInUserIsOwner,
} from 'src/app/store/change-request/change-request.selectors';
import { SetCurrentCountry } from 'src/app/store/details/details.actions';
import { getCurrentCountrySelected } from 'src/app/store/details/details.selectors';
import { SetShowChangesOnly } from 'src/app/store/location/location.action';
import { getLocationData, getShowChangesOnly } from 'src/app/store/location/location.selectors';
import { getLoggedInUserRole } from 'src/app/store/user/user.selectors';
import { EditCellRendererComponent } from '../cell-renderers/edit-cell-renderer/edit-cell-renderer.component';
import { SelectCellRendererComponent } from '../cell-renderers/select-cell-renderer/select-cell-renderer.component';
import { EditIconComponent } from '../edit-icon/edit-icon.component';
import { AliasDialogComponent } from './alias-dialog/alias-dialog.component';
import { DetailBusinessNameComponent } from './cell-renderers/detail-business-name/detail-business-name.component';

@Component({
  selector: 'app-customer-business-name',
  templateUrl: './customer-business-name.component.html',
  styleUrls: ['./customer-business-name.component.scss', '../../styles/shared-styles.scss'],
  providers: [CustomerBusinessNameService],
})
export class CustomerBusinessNameComponent implements OnDestroy, OnInit {
  gridApi: GridApi;
  colApi: ColumnApi;
  gridBoardApi: XpoAgGridBoardApi;
  readonly viewDataStore: XpoLocalStorageBoardViewDataStore;
  readonly viewTemplates: XpoAgGridBoardViewTemplate[];
  stateChange$ = new ReplaySubject<XpoBoardState>(1);
  subscribeRoutes$: any;
  noData: boolean = false;
  hasChanges = false;
  customerRequest: CustomerRequest;
  notes: string = '';
  loggedInUserIsOwner: boolean = false;
  locationData: GetCustomerResp;
  userRole$: Observable<UserRole>;
  isNewLocation: boolean = false;
  functionCd: CustomerFunctionCd;
  isCorporateLocation: boolean = false;
  isChangeRequest = false;
  massAliasUpdate = 'CASCADE';

  boardOptions: XpoBoardOptions;
  customGridOptions: GridOptions;

  private destroy$ = new Subject<void>();

  constructor(
    public dataSource: CustomerBusinessNameService,
    private router: Router,
    private store: Store<AppState>,
    public dialog: MatDialog,
    private errorStateManager: ErrorStateManagerService,
    private statesService: StatesService
  ) {
    this.isChangeRequest = this.isChangeRequestFn();
    this.boardOptions = {
      addNewViewButtonLabel: '',
      suppressViewSwitcher: true,
      enableFilterReset: false,
      enableQueryParamStatePersistance: false,
      persistFiltersBetweenViews: false,
      preloadViewData: false,
      suppressGridDensity: true,
      suppressGridSettingsPopover: false,
      suppressRecordCounts: false,
      suppressLastUpdateTime: true,
    };
    this.customGridOptions = {
      suppressRowClickSelection: true,
      localeText: { noRowsToShow: 'There are no business name aliases for this location.' },
      masterDetail: true,
      detailCellRenderer: 'detailCellRenderer',
      frameworkComponents: {
        detailCellRenderer: DetailBusinessNameComponent,
        editCellRenderer: EditIconComponent,
        editCellRendererComponent: EditCellRendererComponent,
        selectCellRendererComponent: SelectCellRendererComponent,
      },
      animateRows: true,
      detailCellRendererParams: {
        getDetailRowData: (params) => {
          const arr = [];
          arr.push(params.data);
          params.successCallback(arr);
        },
        customActions: {
          toggle: this.toggleRow.bind(this),
          update: this.updateTable.bind(this),
          rollback: this.removeBusinessName.bind(this),
        },
      },
      multiSortKey: 'ctrl',
      pagination: true,
      suppressPaginationPanel: true,
      getRowClass: (params) => {
        return this.isChangeRequest
          ? params.node.data.actionCd
            ? 'ncis-GridRow--highlighted'
            : ''
          : params.node.data.actionCd && params.node.data.actionCd !== 'DELETE'
          ? 'ncis-GridRow--highlighted'
          : '';
      },
      onRowClicked: (params) => {
        this.toggleRow(params.node);
      },
      onRowDataChanged: (event) => {
        this.noData = event.api.getDisplayedRowCount() === 0;
      },
      domLayout: 'autoHeight',
      paginationPageSize: 10,
      getRowHeight: (params) => {
        return params.node.detail ? (this.isChangeRequest ? (!this.loggedInUserIsOwner ? 236 : 316) : 192) : 30;
      },
      getRowNodeId(params): string {
        return params;
      },
      defaultColDef: {
        resizable: true,
        cellClassRules: {
          'cell-edition-error': (params) => {
            return this.errorStateManager.hasError(params.node.rowIndex + '', params.colDef.field);
          },
        },
      },
      tabToNextCell(params): CellPosition {
        // workaround to get tabbing working inside grid
        let selector = `div[row-index="${params?.nextCellPosition?.rowIndex}"]`;
        selector += ` [col-id="${params?.nextCellPosition?.column?.getColId()}"] .container`;
        (<HTMLElement>document.querySelector(selector))?.focus();
        return params?.nextCellPosition;
      },
    };

    this.viewTemplates = this.getBoardViewTemplates();
    this.viewDataStore = new XpoLocalStorageBoardViewDataStore('client-side-customer-business', this.getBoardViews());
  }

  private getBoardViews(): XpoBoardViewConfig[] {
    return [
      {
        templateId: 'template1',
        name: 'All - SD',
        closeable: false,
      },
    ];
  }

  private getRequestActions(params, massIndValue): string[] {
    const actions = ['DELETE'];
    if (!params.data.sequenceNbr) {
      return [];
    }
    if (!massIndValue) {
      actions.push('UPDATE');
    }
    if (this.isCorporateLocation) {
      actions.push(this.massAliasUpdate);
    }
    return actions;
  }

  private getBoardViewTemplates(): XpoAgGridBoardViewTemplate[] {
    const indexCol = {
      ...XpoAgGridColumns.RowIndex,
      cellClassRules: {
        'cell-edition-error': (params) => this.errorStateManager.hasError(params.node.rowIndex + ''),
      },
    };
    indexCol.suppressSizeToFit = true;
    indexCol.width = 60;
    return [
      new XpoAgGridBoardViewTemplate({
        id: 'template1',
        name: 'Template 1',
        allowAdditional: true,
        availableColumns: [
          indexCol,
          {
            cellRendererFramework: EditIconComponent,
            cellRendererParams: (params) => {
              const isMassUpdate = false;
              const massIndValue = this.getMassIndValue(params, isMassUpdate);
              return {
                customActions: {
                  toggle: this.toggleRow.bind(this),
                  update: this.updateTable.bind(this),
                },
                noChangeRequestActions: this.getRequestActions(params, massIndValue),
                moreDetailsAction:
                  this.isChangeRequest && params.data.actionCd ? null : this.openAliasDetails.bind(this),
              };
            },
            sortable: false,
            suppressMenu: true,
            suppressSizeToFit: true,
            width: 110,
            headerName: 'Actions',
            editable: false,
            cellClassRules: {},
            colId: 'action',
            field: 'actionCd',
          },
          {
            cellRendererFramework: LineStatusCellRendererComponent,
            sortable: false,
            suppressMenu: true,
            suppressSizeToFit: true,
            editable: false,
            minWidth: 90,
            cellClassRules: {},
            headerName: 'Status',
            colId: 'status',
            hide: !this.isChangeRequest,
          },
          {
            headerName: 'Name',
            field: 'name1',
            colId: 'name1',
            minWidth: 200,
            suppressMenu: true,
            cellRenderer: 'editCellRendererComponent',
            cellRendererParams: (params) => {
              return {
                value: params.node.data.name1,
                validators: [
                  ValidatorHelper.required('Name is mandatory'),
                  ValidatorHelper.maxLength(30, 'Must contain from 2 to 30 characters'),
                  this.nameFormatValidator,
                ],
                isEditable: this.isCellEditable(params.node),
                node: params.node,
                key: 'name1',
              };
            },
            cellStyle: { padding: '0' },
          },
          {
            headerName: 'Name 2',
            field: 'name2',
            colId: 'name2',
            minWidth: 200,
            suppressMenu: true,
            cellRenderer: 'editCellRendererComponent',
            cellRendererParams: (params) => {
              return {
                value: params.node.data.name2,
                validators: [
                  ValidatorHelper.maxLength(30, 'Must contain from 2 to 30 characters'),
                  this.nameFormatValidator,
                ],
                isEditable: this.isCellEditable(params.node),
                node: params.node,
                key: 'name2',
              };
            },
            cellStyle: { padding: '0' },
          },
          {
            headerName: 'Address',
            field: 'address',
            colId: 'address',
            minWidth: 200,
            suppressMenu: true,
            cellRenderer: 'editCellRendererComponent',
            cellRendererParams: (params) => {
              return {
                value: params.node.data.address,
                validators: [
                  ValidatorHelper.required('Address field is mandatory'),
                  ValidatorHelper.maxLength(30, 'Must contain less than 30 characters'),
                  this.addressFormatValidator,
                ],
                isEditable: this.isCellEditable(params.node),
                node: params.node,
                key: 'address',
              };
            },
            cellStyle: { padding: 0 },
          },
          {
            headerName: 'City',
            field: 'cityName',
            colId: 'cityName',
            minWidth: 200,
            suppressMenu: true,
            cellRenderer: 'editCellRendererComponent',
            cellRendererParams: (params) => {
              return {
                value: params.node.data.cityName,
                validators: [
                  ValidatorHelper.required('City name is mandatory'),
                  ValidatorHelper.maxLength(20, 'Must contain less than 20 characters'),
                  this.addressFormatValidator,
                ],
                isEditable: this.isCellEditable(params.node),
                node: params.node,
                key: 'cityName',
              };
            },
            cellStyle: { padding: 0 },
          },
          {
            headerName: 'State',
            field: 'stateCd',
            colId: 'stateCd',
            minWidth: 80,
            suppressMenu: true,
            cellRenderer: 'selectCellRendererComponent',
            cellRendererParams: (params) => {
              return {
                value: params.node.data.stateCd,
                isEditable: this.isCellEditable(params.node),
                key: 'stateCd',
                type: 'state',
                options: this.store.pipe(
                  select(getCurrentCountrySelected),
                  switchMap((countryCd) => {
                    return this.statesService.getStates(countryCd).pipe(
                      switchMap((states) => {
                        return of(states.map((s) => ({ label: s.abbreviation, value: s.abbreviation })));
                      })
                    );
                  }),
                  takeUntil(this.destroy$)
                ),
                keepFocus: true,
              };
            },
          },
          {
            headerName: 'Postal Code',
            field: 'zipCd',
            colId: 'zipCd',
            minWidth: 120,
            suppressMenu: true,
            cellRenderer: 'editCellRendererComponent',
            cellRendererParams: (params) => {
              const value = params.node.data.zip4Cd
                ? `${params.node.data.zipCd}-${params.node.data.zip4Cd}`
                : params.node.data.zipCd;
              return {
                value: value,
                validators: [ValidatorHelper.required('Zip Code is mandatory'), this.postalCodeValidator(params)],
                isEditable: this.isCellEditable(params.node),
                node: params.node,
                key: 'zipCd',
              };
            },
            cellStyle: { padding: '0' },
          },
          {
            headerName: 'Country',
            field: 'countryCd',
            colId: 'countryCd',
            minWidth: 80,
            suppressMenu: true,
            cellRenderer: 'selectCellRendererComponent',
            cellRendererParams: (params) => {
              return {
                value: params.node.data.countryCd,
                isEditable: this.isCellEditable(params.node),
                key: 'countryCd',
                type: 'country',
                options: of([
                  { label: 'US', value: 'US' },
                  { label: 'MX', value: 'MX' },
                  { label: 'CA', value: 'CA' },
                ]),
                keepFocus: true,
              };
            },
          },
          {
            headerName: 'Mass Alias',
            field: 'massInd',
            colId: 'massInd',
            minWidth: 200,
            suppressMenu: true,
            cellRenderer: 'selectCellRendererComponent',
            hide: false,
            cellRendererParams: (params) => {
              const isMassUpdate = params?.node?.data?.actionCd === this.massAliasUpdate || params?.node?.data?.massInd;
              const massIndValue = this.getMassIndValue(params, isMassUpdate);
              const parsedValue = YesNo[massIndValue?.toString()?.toUpperCase()];
              return {
                parsedValue: massIndValue !== undefined ? parsedValue : '',
                value: massIndValue,
                isEditable: false,
                key: 'massInd',
                type: 'state',
                options: of([
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]),
              };
            },
          },
        ],
        keyField: 'index',
      }),
    ];
  }

  private getMassIndValue(params, isMassUpdate): boolean {
    return isMassUpdate
      ? true
      : this.isChangeRequest
      ? params.node.data?.massInd
      : params.node.data?.auditInfo?.createByPgmId === 'MASS_OP';
  }

  private isCellEditable(node): boolean {
    return (
      node.data.actionCd &&
      node.data.actionCd !== 'DELETE' &&
      node.expanded &&
      (this.isChangeRequest
        ? node.data.statusCd === CustomerLineStatusCd.NOT_IN_PROCESS || node.data.statusCd === CustomerLineStatusCd.HELD
        : node.data.actionCd !== this.massAliasUpdate)
    );
  }

  updateTable(): void {
    this.refreshGrid();
  }

  refreshGrid(): void {
    this.dataSource.refresh();
  }

  /* This action could be called from parent or child, should be differenciate those use cases */
  toggleRow(params): void {
    if (!this.isNewLocation) {
      let canExpand = false;

      if (params && params.data) {
        if (!params.data.actionCd) {
          return;
        }
        canExpand = true;
      }
      if (canExpand && !params.detail) {
        params.setExpanded(!params.expanded);
        this.store.dispatch(new SetCurrentCountry({ currentCountrySelected: params.data.countryCd }));
        if (params && params.actionParams && params.actionParams.needRefresh) {
          this.refreshGrid();
        }
      }
    }
  }

  gridBoardReady(gridEvent: XpoAgGridBoardReadyEvent): void {
    this.gridApi = gridEvent.agGridApi;
    this.colApi = gridEvent.agGridColumnApi;
    this.gridBoardApi = gridEvent.agGridBoardApi;

    this.store
      .select(getRequestedChangeCurrentLine)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const currLoc = res?.requestCustomerLocationFunction[0];
        this.isNewLocation = !currLoc?.madCd;
        this.colApi.setColumnsVisible(
          ['status'],
          this.isChangeRequest ? !!currLoc?.requestCustomerFunctionAlias : false
        );
        this.hasChanges = !!currLoc?.requestCustomerFunctionAlias;
        this.notes = currLoc?.requestCustomerFunctionAlias
          ? currLoc?.requestCustomerFunctionAlias[0]?.requestNote
            ? (
                currLoc.requestCustomerFunctionAlias[0]?.requestNote.find((n) => n.noteTypeCd === 'General') ||
                ({} as any)
              ).note
            : ''
          : '';
      });
  }

  isChangeRequestFn(): boolean {
    const urlParts = this.router.routerState.snapshot.url.split('/') || [];
    return urlParts.findIndex((item) => item === AppRoutes.CHANGE_REQUEST_PAGE) !== -1;
  }

  ngOnInit(): void {
    this.store.pipe(takeUntil(this.destroy$), select(getShowChangesOnly)).subscribe((_) => {
      this.dataSource.refresh();
    });

    this.store.pipe(takeUntil(this.destroy$), select(getChangeRequest)).subscribe((res) => {
      this.customerRequest = res.changeRequest;
      this.dataSource.refresh();
    });

    this.store.pipe(takeUntil(this.destroy$), select(loggedInUserIsOwner)).subscribe((isOwner) => {
      this.loggedInUserIsOwner = isOwner;
    });

    this.store.pipe(takeUntil(this.destroy$), select(getLocationData)).subscribe((state) => {
      this.locationData = state;
      this.functionCd = this.locationData?.customerLocation?.customerLocationFunction[0]?.functionCd;
      this.isCorporateLocation = this.functionCd === CustomerFunctionCd.CORPORATE;
    });

    this.userRole$ = this.store.pipe(
      takeUntil(this.destroy$),
      select(getLoggedInUserRole),
      skipWhile((res) => !res)
    );

    this.store.dispatch(new SetShowChangesOnly({ showChangesOnly: true }));
  }

  ngOnDestroy(): void {
    if (this.subscribeRoutes$) {
      this.subscribeRoutes$.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
    this.errorStateManager.clearAll();
  }

  nameFormatValidator(control): { [key: string]: any } | null {
    const value = control ? control.value : '';
    const theRegex = /^the /i;
    const minLetters = /[^A-Za-z]/g;

    switch (true) {
      case theRegex.test(value):
        return { beginWithThe: 'Must not begin with "The"' };
      case value && (value.replace(minLetters, '') || '').length < 2:
        return { minLetters: 'Must contain at least 2 letters' };
      default:
        return null;
    }
  }

  addressFormatValidator(control): { [key: string]: any } | null {
    const value = control ? control.value : '';
    const regex = /[\w\&\-.#% ]/g;

    return (value.replace(regex, '') || '').length > 0
      ? { invalidFormat: 'Must contain letters, numbers, #, &, -, % or space.' }
      : null;
  }

  cityFormatValidator(control): { [key: string]: any } | null {
    const value = control ? control.value : '';
    const regex = /[\w\&\- ]/g;

    return (value.replace(regex, '') || '').length > 0
      ? { invalidFormat: 'Must contain letters, numbers, &, - or space.' }
      : null;
  }

  postalCodeValidator(params): ValidatorFn {
    return (control) => {
      let result = null;
      const { value = '' } = control;
      switch (params.node.data.countryCd) {
        case 'US':
          const [zip6, zip4] = value.replace(' ', '').split('-');
          if (zip6) {
            result =
              zip6.length === 5 && zip6.replace(/\d/g, '').length === 0
                ? null
                : { invalidZip: 'Invalid zip code format.' };
          }
          if (zip4) {
            const validZip4 = zip4.length === 4 && zip4.replace(/\d/g, '').length === 0 ? true : false;
            if (!validZip4) {
              if (result) {
                result['invalidZip4'] = 'Invalid zip code format.';
              } else {
                result = { invalidZip4: 'Invalid zip code format.' };
              }
            }
          }
          break;
        case 'CA':
          const zip = value.replace(' ', '');
          result =
            zip.length === 6
              ? /[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d/.test(value)
                ? null
                : { invalidZip: 'Invalid zip code format.' }
              : { invalidZip: 'Invalid zip code format.' };
          break;
        case 'MX':
          result =
            value.replace(/\d/g, '').length === 0 && value.length === 5
              ? null
              : { invalidZip: 'Invalid zip code format.' };
          break;
      }
      return result;
    };
  }

  addBusinessName(): void {
    const nodeData = {
      name1: '',
      name2:
        this.locationData.customerLocation.customerLocationFunction[0].functionCd === CustomerFunctionCd.CORPORATE
          ? this.locationData.customerLocation.nameExt || this.locationData.customerLocation.party2?.partyName
          : '',
      address: this.locationData.customerLocation.customerAddress.address,
      cityName: this.locationData.customerLocation.customerAddress.cityName,
      stateCd: this.locationData.customerLocation.customerAddress.stateCd,
      countryCd: this.locationData.customerLocation.customerAddress.countryCd,
      zipCd: this.locationData.customerLocation.customerAddress.zipCd,
      zip4Cd: this.locationData.customerLocation.customerAddress.zip4Cd,
      deleteInd: false,
      actionCd: 'ADD',
    };
    setTimeout(() => {
      this.gridApi.paginationGoToLastPage();
    });
    const transaction = this.gridApi.applyTransaction({ addIndex: 0, add: [nodeData] });
    transaction.add[0].setExpanded(true);
    this.dataSource.setState({
      ...this.dataSource,
      source: 'ADD-NEW-ROW',
      dataFetchState: XpoBoardDataFetchState.ResultsReturned,
    });
  }

  removeBusinessName(node: RowNode): void {
    // this.gridApi.updateRowData({ remove: [node.data] });
    this.dataSource.refresh();
  }

  openAliasDetails(params): void {
    this.dialog.open(AliasDialogComponent, { minWidth: 600, data: params.data });
  }
}
