import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CustomerApiService, GetRequestGrabAndLockResp, RequestQueueFullData } from '@xpo-ltl-2.0/sdk-customer';
import { CustomerRequestQueueNameCd } from '@xpo-ltl/sdk-common';
import { ChangeRequestService } from 'src/app/change-request-page/services/change-request.service';
import { AppRoutes } from 'src/app/shared/enums/app-routes.enum';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { AppState } from 'src/app/store';
import { getLoggedInUserRole } from 'src/app/store/user/user.selectors';
import { UserRole } from '../../enums/user-role/user-role.enum';
import { DialogComponent, DialogConfig } from '../dialog';

@Component({
  selector: 'dashboard-queue-tile',
  templateUrl: 'dashboard-queue-tile.component.html',
  styleUrls: ['dashboard-queue-tile.component.scss'],
})
export class DashboardQueueTileComponent implements OnInit {
  @Input() queueData: RequestQueueFullData;
  columnOptions: any;
  isHover: boolean = false;
  isOutside: boolean = false;
  isWithin: boolean = false;
  disableProcess: boolean = false;
  total: number = 0;
  cardColor: string;
  break: boolean = false;
  queueName: string;
  isTotalTile: boolean = false;
  queueNames = CustomerRequestQueueNameCd;

  constructor(
    private customerApi: CustomerApiService,
    private utilsService: UtilsService,
    private router: Router,
    private store: Store<AppState>,
    public dialog: MatDialog,
    private changeRequestService: ChangeRequestService
  ) {
    this.store.pipe(select(getLoggedInUserRole)).subscribe((role) => {
      this.disableProcess = role === UserRole.noUpdateAuthorization;
    });
  }

  ngOnInit(): void {
    this.total = this.queueData.unprocessedRequest;
    this.isTotalTile = !Object.values(this.queueNames).includes(this.queueData.queueName);
  }

  getChangeRequest(queueName): void {
    if (!this.isTotalTile) {
      this.customerApi.getRequestGrabAndLock({ rqstQueueName: queueName }).subscribe(
        (res: GetRequestGrabAndLockResp) => {
          if (res.requestId) {
            // Reset Change Request Store
            this.changeRequestService.resetStore();
            this.router.navigate([AppRoutes.CHANGE_REQUEST_PAGE, res.requestId]);
          } else {
            const dialog: DialogConfig = {
              title: 'There are no more requests in the queue.',
              icon: 'report_problem',
              actions: [
                {
                  label: 'Close',
                  type: 'primary',
                  resultType: 'CLOSE',
                  resultAction: false,
                  position: 'right',
                },
              ],
            };
            this.dialog.open(DialogComponent, { maxWidth: '600px', data: dialog }).afterClosed();
          }
        },
        (err) => {
          this.utilsService.showServiceErrMessage(err);
        }
      );
    }
  }
}
