<div class="ncis-GridActions-row">
  <ng-container *ngIf="isChangeRequest; else editActions">
    <ng-container>
      <button
        id="expand-btn"
        mat-icon-button
        matTooltip="Add"
        [disabled]="userRole$ | userAccess: ['Credit Analyst', 'noUpdateAuthorization'] | async"
        matTooltipPosition="above"
        *ngIf="action === 'ADD'"
      >
        <mat-icon>add_circle</mat-icon>
      </button>
      <button
        id="expand-btn"
        mat-icon-button
        matTooltip="Update"
        [disabled]="userRole$ | userAccess: ['Credit Analyst', 'noUpdateAuthorization'] | async"
        matTooltipPosition="above"
        *ngIf="action === 'UPDATE'"
        class="ncis-GridActionsIcon--update"
      >
        <mat-icon>create</mat-icon>
      </button>
      <button
        id="expand-btn"
        mat-icon-button
        matTooltip="Delete"
        [disabled]="userRole$ | userAccess: ['Credit Analyst', 'noUpdateAuthorization'] | async"
        matTooltipPosition="above"
        *ngIf="action === 'DELETE'"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <span>{{ action }}</span>
    </ng-container>
    <button
      mat-icon-button
      id="view-details-btn"
      matTooltip="View More Details"
      matTooltipPosition="above"
      *ngIf="params.moreDetailsAction"
      (click)="moreDetailsClick()"
      style="width: 26px;"
    >
      <mat-icon>info</mat-icon>
    </button>
  </ng-container>
  <ng-template #editActions>
    <button
      id="expand-btn"
      mat-icon-button
      style="width: 26px;"
      [disabled]="userRole$ | userAccess: ['Credit Analyst', 'noUpdateAuthorization'] | async"
      (click)="setActionType(action)"
      matTooltip="{{ action === 'CASCADE' ? propagateMassAlias : '' }}"
      matTooltipPosition="above"
      *ngFor="let action of params.noChangeRequestActions"
    >
      <mat-icon>{{ getIcon(action) }}</mat-icon>
    </button>
    <button
      id="expand-btn"
      mat-icon-button
      matTooltipPosition="above"
      matTooltip="View More Details"
      *ngIf="params.moreDetailsAction"
      (click)="moreDetailsClick()"
      style="width: 26px;"
    >
      <mat-icon>info</mat-icon>
    </button>
  </ng-template>
</div>
