import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { debounce } from 'lodash';

@Directive({
  selector: 'button, div',
})
export class DebounceClickDirective {
  @Output() debounceClick = new EventEmitter();

  @HostListener('click', ['$event'])
  debouncedClick = debounce(
    (event: Event) => {
      this.debounceClick.emit(event);
    },
    500,
    { leading: true, trailing: false }
  );
}
