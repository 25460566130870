<div class="confirm-address-dialog-container">
  <div class="confirm-address-dialog-result cdk-cursor" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    Address Validation: &nbsp;
    <span [ngClass]="{ success: result === 'Valid', failed: result !== 'Valid' }">
      {{ result === 'Valid' ? 'Passed' : 'Failed' }}
    </span>
  </div>
  <div class="confirm-address-dialog-list">
    <div *ngIf="showEnteredAddress" class="confirm-address-dialog-entered" style="margin-right: 16px;">
      <p><strong>As-Entered Party</strong></p>
      <div>
        <div>{{ partyName }}</div>
      </div>
      <div>
        <div>{{ asEnteredAddress.address }}</div>
        <div>
          <span>{{ asEnteredAddress.cityName }},</span>&nbsp;
          <span>
            {{ asEnteredAddress.stateCd }}&nbsp;
            {{
              asEnteredAddress.zip4Cd ? asEnteredAddress.zipCd + '-' + asEnteredAddress.zip4Cd : asEnteredAddress.zipCd
            }},&nbsp;
          </span>
          <span>{{ asEnteredAddress.countryCd }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="validatedAddress" class="confirm-address-dialog-suggested">
      <p><strong>Validated Party</strong></p>
      <div>
        <div>{{ partyName }}</div>
      </div>
      <div>
        <div>{{ validatedAddress?.addressLine1 }}</div>
        <div>
          <span>{{ validatedAddress?.cityName }},</span>&nbsp;
          <span>
            {{ validatedAddress?.stateCd }}&nbsp;
            {{
              validatedAddress?.usZip4
                ? validatedAddress?.postalCd + '-' + validatedAddress?.usZip4
                : validatedAddress?.postalCd
            }},&nbsp;
          </span>
          <span>{{ validatedAddress?.countryCd }}</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="validatedAddress" style="margin-bottom: 16px;">Which party do you want to use?</div>
  <div class="confirm-address-dialog-actions">
    <div class="success-wrapper">
      <button
        id="address-modal-validated-btn"
        (click)="useValidatedClick()"
        [disabled]="validatedDisabled"
        mat-flat-button
      >
        VALIDATED
      </button>
      <button
        id="address-modal-as-entered-btn"
        (click)="useAsEnteredClick()"
        [disabled]="!showEnteredAddress"
        mat-stroked-button
      >
        AS-ENTERED
      </button>
    </div>
    <div class="cancel-wrapper">
      <button id="address-modal-cancel-btn" (click)="cancelClick()" mat-stroked-button>CANCEL</button>
    </div>
  </div>
</div>
