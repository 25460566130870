import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerApiService } from '@xpo-ltl-2.0/sdk-customer';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { CustomerDetailCd, CustomerIdTypeCd } from '@xpo-ltl/sdk-common';
import { Agreement } from '@xpo-ltl/sdk-pricingworkbench';
import moment from 'moment';
import { YesNo } from 'src/app/change-request-page/models/enums/yes-no';
import { EmployeesService } from 'src/app/change-request-page/services/employees.service';
import { LocationDetailService } from 'src/app/location-details-page/services/location-details.service';
import { ConstantsService } from 'src/app/shared/services/constants/constants.service';

@Component({
  selector: 'app-pricing-dialog',
  templateUrl: './pricing-dialog.component.html',
  styleUrls: ['./pricing-dialog.component.scss'],
})
export class PricingDialogComponent implements OnInit {
  pricingDetailsForm: UntypedFormGroup;
  salesOwner: string;
  primaryAccount: string;
  pricingStatus: string;
  pricingData: any;
  expiryDate: any;
  lastExpiryAgreement: Agreement;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialogRef<PricingDialogComponent>,
    private employeeService: EmployeesService,
    private locationDetailService: LocationDetailService,
    private customerApi: CustomerApiService,
    private snackbar: XpoSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.locationDetailService.hasActivePricing(this.data.legacyCisCustomerNbr, false).subscribe(
      (res) => {
        this.pricingData = res.agreementData;
        if (!this.pricingData) {
          this.snackbar.open({
            message: 'The pricing Agreement service is not available.',
            status: 'error',
            matConfig: {
              duration: ConstantsService.snackbarDuration,
            },
          });
        }
        this.expiryDate = res.expiryDate;
        const salesOwnerId = this.pricingData?.accountOwner?.salesOwnerEmployeeId;
        if (!this.pricingData?.agreements || this.pricingData?.agreements?.length === 0) {
          this.snackbar.open({
            message: 'No pricing Agreement found.',
            status: 'warn',
            matConfig: {
              duration: ConstantsService.snackbarDuration,
            },
          });
          this.close();
        }
        this.lastExpiryAgreement = this.getLatestAgreementWithLatestVersionNbr(this.pricingData?.agreements);
        const primaryAcctId = this.lastExpiryAgreement?.leadAcctId;

        this.createForm();

        if (salesOwnerId) {
          this.employeeService.getEmployee(salesOwnerId).subscribe((name) => {
            this.salesOwner = name;
            this.pricingDetailsForm.controls['salesOwner'].setValue(name);
          });
        }

        if (primaryAcctId) {
          this.pricingDetailsForm.controls['primaryAccount'].setValue('Loading...');
          this.customerApi
            .getCustomer(
              { id: typeof primaryAcctId !== 'string' ? primaryAcctId.toString() : primaryAcctId },
              {
                customerIdTypeCd: CustomerIdTypeCd.CUSTOMER_LOCATION_FUNCTION_ID,
                customerDetailCd: [CustomerDetailCd.ACCOUNT],
                inheritContacts: true,
              },
              { loadingOverlayEnabled: false }
            )
            .subscribe((data) => {
              this.primaryAccount = data.customerLocation?.customerLocationFunction.find(
                (loc) => loc.legacyCisCustomerNbr === primaryAcctId
              )?.madCd;
              this.pricingDetailsForm.controls['primaryAccount'].setValue(this.primaryAccount);
            });
        }
      },
      (_) => {
        this.pricingStatus = 'The pricing Agreement service is not available.';
        this.createForm();
      }
    );
  }

  private createForm(): void {
    const notAvailable = this.pricingStatus === 'The pricing Agreement service is not available.';
    const account = this.pricingData?.account;
    const status = this.lastExpiryAgreement?.expiryDate
      ? moment(this.lastExpiryAgreement?.expiryDate).isAfter(moment())
        ? 'Active'
        : 'Expired'
      : '';

    this.pricingDetailsForm = this.fb.group({
      agreemmentId: [
        notAvailable || !this.lastExpiryAgreement?.agreementSequenceNbr
          ? ''
          : `${this.lastExpiryAgreement.leadAcctId}-${this.lastExpiryAgreement?.agreementSequenceNbr}-${this.lastExpiryAgreement?.agreementCustomerVersion}`,
      ],
      obi: [
        notAvailable
          ? ''
          : this.lastExpiryAgreement && YesNo[this.lastExpiryAgreement?.obiInd?.toString()?.toUpperCase()],
      ],
      salesOwner: [''],
      primaryAccount: [this.lastExpiryAgreement?.primaryAcctId],
      status: [status],
      effectiveDate: [
        notAvailable || !this.lastExpiryAgreement?.effectiveDate
          ? ''
          : this.lastExpiryAgreement && moment(this.lastExpiryAgreement?.effectiveDate).format('MM/DD/YYYY'),
      ],
      expirationDate: [
        notAvailable || !this.lastExpiryAgreement?.effectiveDate
          ? ''
          : this.lastExpiryAgreement && moment(this.lastExpiryAgreement?.expiryDate).format('MM/DD/YYYY'),
      ],
    });

    this.pricingDetailsForm.disable();
  }

  private getLatestDate(agreements: Agreement[]): Date {
    return agreements.reduce((maxDate, agreement) => {
      const currentDate = new Date(agreement?.expiryDate);
      return currentDate > maxDate ? currentDate : maxDate;
    }, new Date('1970-01-01'));
  }

  private getMaxAgreementSequenceAndVersion(collection: Agreement[]): Agreement {
    const highestSequenceAgreement = collection.reduce((prev, current) =>
      prev.agreementSequenceNbr > current.agreementSequenceNbr ? prev : current
    );

    return collection
      .filter((obj) => obj?.agreementSequenceNbr === highestSequenceAgreement?.agreementSequenceNbr)
      .reduce((prev, current) => (prev.agreementCustomerVersion > current?.agreementCustomerVersion ? prev : current));
  }

  getLatestAgreementWithLatestVersionNbr(agreements: Agreement[]): Agreement | undefined {
    if (agreements) {
      const latestDate = this.getLatestDate(agreements);

      const collection = agreements.filter(
        (agreement) => new Date(agreement?.expiryDate).getTime() === latestDate.getTime()
      );

      if (collection?.length > 1) {
        return this.getMaxAgreementSequenceAndVersion(collection);
      } else {
        return collection[0];
      }
    }
    return;
  }

  close(): void {
    this.dialog.close();
  }
}
