import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { XpoAgGridColumns } from '@xpo-ltl/ngx-ag-grid';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-board/ag-grid';
import {
  XpoBoardOptions,
  XpoBoardState,
  XpoBoardViewConfig,
  XpoLocalStorageBoardViewDataStore,
} from '@xpo-ltl/ngx-board/core';
import { GridApi } from 'ag-grid-community';
import { ReplaySubject } from 'rxjs';
import { ExportDialogService } from './services/export-dialog.service';

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExportDialogComponent implements OnInit {
  boardOptions: XpoBoardOptions;
  customGridOptions;
  gridApi: GridApi;
  readonly viewDataStore: XpoLocalStorageBoardViewDataStore;
  readonly viewTemplates: XpoAgGridBoardViewTemplate[];
  stateChange$ = new ReplaySubject<XpoBoardState>(1);

  constructor(public dataSource: ExportDialogService, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.viewTemplates = this.getBoardViewTemplates();
    this.viewDataStore = new XpoLocalStorageBoardViewDataStore('client-side-dialog-export', this.getBoardViews());
  }

  ngOnInit(): void {
    this.boardOptions = {
      suppressViewSwitcher: true,
      enableFilterReset: false,
      enableQueryParamStatePersistance: false,
      persistFiltersBetweenViews: false,
      preloadViewData: false,
      suppressGridDensity: true,
      suppressGridSettingsPopover: true,
      suppressRecordCounts: false,
    };

    this.customGridOptions = {
      suppressRowClickSelection: true,
      animateRows: true,
      multiSortKey: 'ctrl',
      pagination: true,
      defaultColDef: {
        suppressMenu: true,
        sortable: false,
        resizable: true,
      },
      domLayout: 'normal',
      suppressPaginationPanel: false,
      getContextMenuItems(): string[] {
        return ['copy'];
      },
      context: { filtersOptionsVisibleDefaultValue: true },
    };
    this.dataSource.setResults(this.data.rows);
  }

  private getBoardViewTemplates(): XpoAgGridBoardViewTemplate[] {
    const indexCol = {
      ...XpoAgGridColumns.RowIndex,
    };
    indexCol.suppressSizeToFit = true;
    return [
      new XpoAgGridBoardViewTemplate({
        id: 'template1',
        name: 'Template 1',
        allowAdditional: false,
        availableColumns: this.data.columns,
        keyField: 'index',
      }),
    ];
  }

  private getBoardViews(): XpoBoardViewConfig[] {
    return [
      {
        templateId: 'template1',
        name: 'All - SD',
        closeable: false,
      },
    ];
  }

  gridBoardReady(params): void {
    this.gridApi = params.agGridApi;
  }

  exportToCsv(): void {
    this.gridApi.exportDataAsCsv({ fileName: this.data.fileName });
  }

  exportToXlsx(): void {
    this.gridApi.exportDataAsExcel({ fileName: this.data.fileName });
  }
}
