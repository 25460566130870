import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { XpoAccountPopoverModule } from '@xpo-ltl/ngx-ltl-core/account-popover';
import { XpoAppSwitcherPopoverModule } from '@xpo-ltl/ngx-ltl-core/app-switcher-popover';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoPageNotFoundRoutingModule } from '@xpo-ltl/ngx-ltl-core/error-page';
import { XpoFeedbackModule } from '@xpo-ltl/ngx-ltl-core/feedback';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire';

/* XPO */
import { CustomerApiService } from '@xpo-ltl-2.0/sdk-customer';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { SupportedAppEnum, XpoMaintAppConfig, XpoMaintenanceModule } from '@xpo-ltl/maint-ui';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';

import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { CustomerRequestApiService } from '@xpo-ltl/sdk-customerrequest';
import { SdkInfrastructureModule } from '@xpo-ltl/sdk-infrastructure';
import { SdkLocationModule } from '@xpo-ltl/sdk-location';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { XpoAuthConfig, XpoAuthModule, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';

/* Components */
import { NotificationComponent } from './app-notification.component';

/* Modules */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DialogModule } from './dialogs/dialog.module';
import { SharedModule } from './shared/shared.module';
import { NcisStoreModule } from './store/store.module';

/* Services */
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { AccountsReceivableApiService } from '@xpo-ltl-2.0/sdk-accountsreceivable';
import { HumanResourceApiService } from '@xpo-ltl-2.0/sdk-humanresource';
import { PricingApiService } from '@xpo-ltl/sdk-pricing';
import { PricingWorkbenchApiService } from '@xpo-ltl/sdk-pricingworkbench';
import { ShipmentOdsApiService } from '@xpo-ltl/sdk-shipmentods';
import { InterceptorService } from './shared/services/interceptor.service';
import { UtilsService } from './shared/services/utils/utils.service';
import { MatDialogModule } from '@angular/material/dialog';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [AppComponent, NotificationComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    ConfigManagerModule,
    DialogModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    XpoAccountPopoverModule,
    XpoAppSwitcherPopoverModule,
    XpoButtonModule,
    XpoCardModule,
    MatDialogModule,
    XpoDialogModule,
    XpoIconModule,
    XpoShellModule,
    XpoFeedbackModule,
    XpoSnackBarModule,
    XpoFeedbackModule,
    SdkAppMetadataModule,
    SdkLocationModule,
    SdkUserPreferenceModule,
    SdkInfrastructureModule,
    XpoMaintenanceModule.initialize(<XpoMaintAppConfig>{ appName: SupportedAppEnum.NCIS }),
    NcisStoreModule,
    XpoAuthModule,
    // FOR SSO
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    AppRoutingModule,
    XpoPageNotFoundRoutingModule,
    XpoLtlAuthUiModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    DataApiService,
    NotificationService,
    CustomerRequestApiService,
    CustomerApiService,
    UtilsService,
    HumanResourceApiService,
    PricingApiService,
    PricingWorkbenchApiService,
    ShipmentOdsApiService,
    AccountsReceivableApiService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
