import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerApiService } from '@xpo-ltl-2.0/sdk-customer';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { CustomerDetailCd, CustomerIdTypeCd } from '@xpo-ltl/sdk-common';
import moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import { CustomerStatus } from 'src/app/change-request-page/models/enums/customer-status';
import { CustomerRequestStatusCdLabel } from 'src/app/shared/enums/customer-request-status-cd-label.enum';
import { ConstantsService } from 'src/app/shared/services/constants/constants.service';
import { ValidatorHelper } from 'src/app/shared/validators';

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss'],
})
export class QuickSearchComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef;
  searchValueControl = new UntypedFormControl('', [
    ValidatorHelper.pattern('[A-Za-z0-9 ]*', 'No special characters allowed.'),
  ]);
  results: Array<any>;
  searchingForCustomer = false;
  searchingForRequest = false;
  searchingForLegacyCustomer = false;
  loading = false;
  noResults = false;
  madCdChanged = false;
  newMadCd = '';
  customerStatusEnum = CustomerStatus;

  constructor(private customerApi: CustomerApiService, private snackbar: XpoSnackBar, private router: Router) {}

  ngOnInit(): void {
    this.searchValueControl.valueChanges.pipe(debounceTime(100)).subscribe((value) => {
      this.madCdChanged = false;
      this.newMadCd = '';
      value = value?.trim();
      this.searchingForCustomer = value && value.length > 2 && /^[A-Za-z ]{2,}/g.test(value);
      this.searchingForRequest = value && value.length >= 2 && /^[A-Za-z]{1}[0-9]{1,}/.test(value);
      this.searchingForLegacyCustomer = value && value.length > 2 && /^[0-9]*$/.test(value);

      if (this.searchValueControl.valid) {
        switch (true) {
          case (this.searchingForCustomer && value.length === 11) || this.searchingForLegacyCustomer:
            this.loading = true;
            this.noResults = false;
            this.customerApi
              .getCustomer(
                { id: value.toUpperCase() },
                {
                  customerIdTypeCd: this.searchingForCustomer
                    ? CustomerIdTypeCd.MAD_CODE
                    : CustomerIdTypeCd.CUSTOMER_LOCATION_FUNCTION_ID,
                  customerDetailCd: [CustomerDetailCd.ACCOUNT],
                  inheritContacts: true,
                },
                { loadingOverlayEnabled: false }
              )
              .subscribe(
                (res) => {
                  const filterLocation = res.customerLocation.customerLocationFunction.filter(
                    (location) =>
                      location.legacyCisCustomerNbr.toString() === value.toUpperCase() ||
                      location.madCd === value.toUpperCase() ||
                      location.customerLocationFuncId === +value.toUpperCase()
                  );
                  res.customerLocation.customerLocationFunction =
                    filterLocation.length > 0 ? filterLocation : res.customerLocation.customerLocationFunction;
                  if (filterLocation.length === 0) {
                    this.madCdChanged = true;
                    this.newMadCd = res.customerLocation.customerLocationFunction[0].madCd;
                  }
                  this.results = [res];
                },
                (error) => {
                  if (error.code === '500') {
                    this.snackbar.open({
                      message: 'System error. Unable to display request. Please contact support.',
                      status: 'error',
                      matConfig: {
                        duration: ConstantsService.snackbarDuration,
                      },
                    });
                  }
                  this.loading = false;
                  this.noResults = true;
                },
                () => {
                  this.loading = false;
                }
              );
            break;
          case this.searchingForRequest && value.length === 7:
            this.loading = true;
            this.noResults = false;
            this.customerApi
              .getRequest(
                { id: value.toUpperCase() },
                {
                  changes: false,
                  requestIdType: 'requestNumber',
                  customerLocationFuncId: null,
                  requestFuncSequenceNbr: null,
                },
                { loadingOverlayEnabled: false }
              )
              .subscribe(
                (res) => {
                  this.results = [
                    {
                      text: ` - ${CustomerRequestStatusCdLabel[res.request.statusCd]} - ${moment(
                        res.request.auditInfo.createdTimestamp
                      ).format('L')}`,
                      requestId: res.request.cstRequestId,
                    },
                  ];
                },
                (error) => {
                  if (error.code === '500') {
                    this.snackbar.open({
                      message: 'System error. Unable to display request. Please contact support.',
                      status: 'error',
                      matConfig: {
                        duration: ConstantsService.snackbarDuration,
                      },
                    });
                  }
                  this.loading = false;
                  this.noResults = true;
                },
                () => {
                  this.loading = false;
                }
              );
            break;
          default:
            this.noResults = true;
            if (this.loading) {
              this.loading = false;
              this.results = [];
            }
            break;
        }
      }
    });
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): void {
    if (e.key === 'Enter' && !this.loading && !this.noResults && this.results?.length > 0) {
      const navigateUrl = this.searchingForCustomer
        ? `/location/${this.results[0].customerLocationFuncId}`
        : `/change-request/${this.results[0].requestId}`;
      this.results = [];
      this.noResults = false;
      this.searchInput.nativeElement.value = '';
      this.searchInput.nativeElement.blur();
      this.router.navigate([navigateUrl]);
    }
  }
}
